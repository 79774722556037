import React from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

import { Button } from "../../common/buttons";
import { PlusIcon, TrashIcon } from "../../common/icons";

import "./styles.less";

const GroupRoleActions = ({
    onAddClick,
    onRemoveClick, 
    isAddDisabled, 
    isDeleteDisabled
}) => {
    return (
        <Box className="group-role-actions">
            <Button
                onClick={onAddClick}
                variant="outlined"
                className="group-role-action-button"
                disabled={isAddDisabled}
            >
                <PlusIcon />
                Добавить
            </Button>
            <Button
                onClick={onRemoveClick}
                variant="outlined"
                className="group-role-action-button"
                disabled={isDeleteDisabled}
            >
                <TrashIcon />
                Удалить
            </Button>
        </Box>
    );
};

GroupRoleActions.propTypes = {
    onAddClick: PropTypes.func,
    onRemoveClick: PropTypes.func, 
    isAddDisabled: PropTypes.bool,
    isDeleteDisabled: PropTypes.bool
};

export default GroupRoleActions;