import SodService from "./sod-service.js";

class AdminService extends SodService {
    async generateRules() {
        const currentInstance = this.getCurrentInstance(true);
        return currentInstance.get("/sod/reports/shm");
    }

    async clearMatrices() {
        return this.delete("/sod/clear-matrices");
    }

    async getSodAppVersion() {
        return this.get("/sod/version", null, true, false)
    }

    async getCoreAppVersion() {
        return this.get("/core/version")
    }

    async getCoreSettings() {
        const data = await this.get("/core/settings")
        const response = data.map(item => this.parseSettingToFrontend(item))

        return response
    }

    async getSodSettings() {
        const data = await this.get("/sod/settings")
        const response = data.map(item => this.parseSettingToFrontend(item))

        return response
    }

    async saveCoreSettings(settings) {
        const payload = settings.map(item => this.parseSettingToServer(item))
        const data = await this.post("/core/settings", payload)

        return data
    }

    async saveSodSettings(settings) {
        const payload = settings.map(item => this.parseSettingToServer(item))
        const data = await this.post("/sod/settings", payload)

        return data
    }

    parseSettingToServer(item) {
        let newValue;

        switch (item.type) {
            case "bool":
                newValue = item.value ? "True" : "False";
                break;
            case "int":
                newValue = item.value.toString();
                break;
            default:
                newValue = item.value
                break;
        }

        return {
            key: item.key,
            value: newValue,
            type: item.type,
            description: item.description
        }
    }

    parseSettingToFrontend(item) {
        let newValue;

        switch (item.type) {
            case "bool":
                newValue = item.value == "True" ? true : false;
                break;
            case "int":
                newValue = parseInt(item.value);
                break;
            default:
                newValue = item.value
                break;
        }

        return {
            key: item.key,
            value: newValue,
            type: item.type,
            description: item.description
        }
    }
}

const adminService = new AdminService();

export { adminService };
export default AdminService;
