import React, { useCallback, useEffect, useState, useId } from "react";
import PropTypes from "prop-types";

import { SearchHelpIcon } from "../icons";

import Input from "./input.jsx";
import InputWrapper from "./input-wrapper.jsx";
import { Box, Typography } from "@mui/material";

const SearchHelpInput = ({
    className = "",
    fullWidth = true,
    shrink = true,
    label,
    value,
    delay = 900,
    error = false,
    errorMessage,
    warning = false,
    warningMessage,
    disabled,
    showFormHelpers = true,
    showInnerHelpers = false,
    IconComponent = SearchHelpIcon,
    onChange,
    onOpenSearchHelp
}) => {
    const id = useId();

    const [valueInner, setValueInner] = useState("");
    const [changeEvent, setChangeEvent] = useState(null)

    useEffect(() => {
        setValueInner(value)
    }, [value])

    useEffect(() => {
        if (!changeEvent) return;

        const timeoutId = setTimeout(() => {
            onChange(changeEvent)
            setChangeEvent(null);
        }, delay)

        return () => {
            clearTimeout(timeoutId)
        }
    }, [changeEvent, delay, onChange]);

    const onChangeInner = useCallback((e) => {
        const value = e.target.value;

        setValueInner(value)
        setChangeEvent(e)
    }, [])

    const onCLickSearchHelpIcon = useCallback(() => {
        if (disabled) return;

        onOpenSearchHelp()
    }, [disabled, onOpenSearchHelp]);

    return (
        <InputWrapper
            id={id}
            className={className}
            fullWidth={fullWidth}
            shrink={shrink}
            label={label}
            error={error}
            errorMessage={errorMessage}
            warning={warning}
            warningMessage={warningMessage}
            showFormHelpers={showFormHelpers}
            InputComponent={
                <Input
                    id={id}
                    value={valueInner}
                    onChange={onChangeInner}
                    error={error}
                    errorMessage={errorMessage}
                    warning={warning}
                    warningMessage={warningMessage}
                    disabled={disabled}
                    showInnerHelpers={showInnerHelpers}
                    endAdornment={
                        <IconComponent
                            onClick={onCLickSearchHelpIcon}
                            sx={{
                                cursor: "pointer",
                                color: "#B7B7B7"
                            }}
                        />
                    }
                />
            }
        />
    );
};



const SearchHelpDescriptionInput = ({
    className = "",
    fullWidth = true,
    shrink = true,
    label,
    value,
    description,
    delay = 900,
    error = false,
    errorMessage,
    warning = false,
    warningMessage,
    disabled,
    showFormHelpers = true,
    showInnerHelpers = false,
    IconComponent = SearchHelpIcon,
    onChange,
    onOpenSearchHelp
}) => {
    const id = useId();

    const [valueInner, setValueInner] = useState("");
    const [changeEvent, setChangeEvent] = useState(null)

    useEffect(() => {
        setValueInner(value)
    }, [value])

    useEffect(() => {
        if (!changeEvent) return;

        const timeoutId = setTimeout(() => {
            onChange(changeEvent)
            setChangeEvent(null);
        }, delay)

        return () => {
            clearTimeout(timeoutId)
        }
    }, [changeEvent, delay, onChange]);

    const onChangeInner = useCallback((e) => {
        const value = e.target.value;

        setValueInner(value)
        setChangeEvent(e)
    }, [])

    const onCLickSearchHelpIcon = useCallback(() => {
        if (disabled) return;

        onOpenSearchHelp()
    }, [disabled, onOpenSearchHelp]);

    return (
        <InputWrapper
            id={id}
            className={className}
            fullWidth={fullWidth}
            shrink={shrink}
            label={label}
            error={error}
            errorMessage={errorMessage}
            warning={warning}
            warningMessage={warningMessage}
            showFormHelpers={showFormHelpers}
            InputComponent={
                <Box sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center"
                }}>
                    <Input
                        id={id}
                        sx={{
                            width: "50%"
                        }}
                        value={valueInner}
                        onChange={onChangeInner}
                        error={error}
                        errorMessage={errorMessage}
                        warning={warning}
                        warningMessage={warningMessage}
                        disabled={disabled}
                        showInnerHelpers={showInnerHelpers}
                        endAdornment={
                            <IconComponent
                                onClick={onCLickSearchHelpIcon}
                                sx={{
                                    cursor: "pointer",
                                    color: "#B7B7B7"
                                }}
                            />
                        }
                    />

                    <Typography className="search-help-input-description">
                        {description}
                    </Typography>
                </Box>
            }
        />
    );
};

SearchHelpInput.propTypes = {
    className: PropTypes.string,
    fullWidth: PropTypes.bool,
    shrink: PropTypes.bool,
    label: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    delay: PropTypes.number,
    onOpenSearchHelp: PropTypes.func,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    warning: PropTypes.bool,
    warningMessage: PropTypes.string,
    showFormHelpers: PropTypes.bool,
    showInnerHelpers: PropTypes.bool,
    IconComponent: PropTypes.elementType
};

SearchHelpDescriptionInput.propTypes = {
    className: PropTypes.string,
    fullWidth: PropTypes.bool,
    shrink: PropTypes.bool,
    label: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    delay: PropTypes.number,
    onOpenSearchHelp: PropTypes.func,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    warning: PropTypes.bool,
    warningMessage: PropTypes.string,
    description: PropTypes.string,
    showFormHelpers: PropTypes.bool,
    showInnerHelpers: PropTypes.bool,
    IconComponent: PropTypes.elementType
};

export default SearchHelpInput;
export {
    SearchHelpDescriptionInput
}
