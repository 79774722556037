import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

import delay from "../../../utils/delay.js";
import { USER_SCREEN_BUTTONS } from "../../../utils/auth.js";

import {
    editField,
    runIntegrationUploadSAP,
    runIntegrationUpload1C,
    uploadMatrix as uploadMatrixSlice,
    setUploadErrors,
    setUploadCompleted,
    resetIntegrationStatus
} from "../../../reducers/admin/integrationSlice.js";

import { selectUserScreenButton } from "../../../reducers/authSlice.js";

import SodSocketService from "../../../services/sod-socket-service.js";
import { integrationService } from "../../../services/integration-service.js";

import IntegrationSystemBox from "./integration-system-box.jsx";
import ScheduleMaintenanceDialog from "./dialogs/schedule-maintenance-dialog.jsx";
import IntegrationHistoryDialog from "./dialogs/integration-history-dialog.jsx";
import downloadFile from "../../../utils/downloadFile.js";
import UploadMatrixDialog from "./dialogs/upload-matrix-dialog.jsx";
import { SYSTEM_TYPES, integrationEntityMap } from "../../../utils/integration-utils.js";

import "./styles.less";

const parseIntegrStatusUpdateMessage = (messageEntity) => {
    return {
        systemId: messageEntity.system,
        systemType: messageEntity.system_type ? messageEntity.system_type : SYSTEM_TYPES.SAP,
        entity: integrationEntityMap[messageEntity.entity],
        status: parseNullableValue(messageEntity.status),
        lastSyncDate: parseNullableValue(messageEntity.last_update)
    };
}

const parseNullableValue = (value) => {
    return value === "null" ? null : value;
}

const IntegrationList = ({ integrationItems, setError }) => {
    const socketService = SodSocketService.getInstance();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [search, setSearch] = useSearchParams()

    const [itemAction, setItemAction] = useState();

    const uploadCompleted = useSelector(state => state.admin.integration.uploadCompleted);

    const canResetIntegrationStatus = useSelector(state => selectUserScreenButton(state, USER_SCREEN_BUTTONS.RESET_INTEGR_STATUS));

    const navigateOptions = { 
        state: {
            previousDialogType: itemAction?.payload?.scheduleType
        }
    };

    useEffect(() => {
        if (!uploadCompleted) return;

        onCloseUploadMatrixDialog()
        
        dispatch(setUploadCompleted(false))
    }, [uploadCompleted, dispatch, onCloseUploadMatrixDialog])

    const handleSocketMessage = useCallback(async (dataRaw) => {
        const data = parseIntegrStatusUpdateMessage(dataRaw)

        await delay()

        dispatch(editField({ systemId: data.systemId, systemType: data.systemType, entity: data.entity, field: "status", value: data.status }));
        dispatch(editField({ systemId: data.systemId, systemType: data.systemType, entity: data.entity, field: "lastSyncDate", value: data.lastSyncDate }));
    }, []) 

    useEffect(() => {
        socketService.subscribe("get_notification", handleSocketMessage)

        return () => {
            socketService.unsubscribe("get_notification", handleSocketMessage)
        }

    }, [socketService, handleSocketMessage])

    const editFieldSystem = (systemId, systemType) => (entity, field, value) => {
        dispatch(editField({ systemId: systemId, systemType: systemType, entity: entity, field: field, value: value }));
    };

    const runIntegrationSAP = (systemId) => {
        const systemItegrationCurren = integrationItems.find(
            (item) => item.id === systemId
        );

        if (!systemItegrationCurren) return;

        const settingsForStart = {
            systemId: systemId,
            permissionDetail: systemItegrationCurren.permissionDetail.checked,
            profile: systemItegrationCurren.profile.checked,
            role: systemItegrationCurren.role.checked,
            employee: systemItegrationCurren.employee.checked,
        };

        dispatch(runIntegrationUploadSAP(settingsForStart))
    }

    const runIntegration1C = (systemId) => {
        const systemItegrationCurren = integrationItems.find(
            (item) => item.id === systemId
        );

        if (!systemItegrationCurren) return;

        const settingsForStart = {
            systemId: systemId,
            authorityDetail: systemItegrationCurren.authorityDetail.checked,
            metadataObject: systemItegrationCurren.metadataObject.checked,
            employee1C: systemItegrationCurren.employee1C.checked,
            role1C: systemItegrationCurren.role1C.checked,
        };

        dispatch(runIntegrationUpload1C(settingsForStart))
    }

    const handleStartClick = (systemId, systemType) => () => {
        systemType === SYSTEM_TYPES.SAP ? runIntegrationSAP(systemId) : runIntegration1C(systemId)
    };

    const onPlanClick = useCallback(async (system, type, systemType) => {
        let scheduleItems = []

        try {
            scheduleItems = await integrationService.getScheduleItems(system, type, "");
        } catch (error) {
            setError(error)
            return
        }

        setItemAction({
            type: "planning",
            payload: {
                data: scheduleItems,
                system: system,
                scheduleType: type,
                systemType: systemType
            }
        })
    }, [setError]);

    const onHistoryClick = useCallback(async (system, historyType, systemType) => {
        let historyItems = []

        try {
            historyItems = await integrationService.getIntegrationHistoryItems(system, historyType, systemType);
        } catch (error) {
            setError(error)
            return
        }

        setItemAction({
            type: "history",
            payload: {
                data: historyItems,
                system: system,
                type: historyType,
                systemType: systemType
            }
        })
    }, [setError]);

    const system = search.get("system")
    const scheduleType = search.get("schedule_type")
    const systemType = search.get("system_type")
    const historyType = search.get("history_type")
    const uploadMatrix = search.get("upload_matrix") === "true"

    useEffect(() => {
        if (!system || !scheduleType || !systemType) return

        onPlanClick(system, scheduleType, systemType)
    }, [system, scheduleType, systemType, onPlanClick])

    useEffect(() => {
        if (!system || !historyType || !systemType) return

        onHistoryClick(system, historyType, systemType)
    }, [system, historyType, systemType, onHistoryClick])

    useEffect(() => {
        if (!system || !uploadMatrix) return

        setItemAction({
            type: "uploadMatrix",
            payload: {
                system: system,
            }
        })
    }, [system, uploadMatrix])

    const onAddSchedule = () => {
        if (!itemAction) {
            return
        }

        const itemScheduleType = itemAction.payload.scheduleType;
        const navigationScheduleType = (!itemScheduleType || itemScheduleType === "all")
            ? "common"
            : itemAction.payload.scheduleType;

        navigate(
            `/integration/${itemAction.payload.system}/${itemAction.payload.systemType}/${navigationScheduleType}/schedule/`,
            navigateOptions
        );
    }

    const onSearchSchedule = async (type, value) => {
        let scheduleItems = []

        try {
            scheduleItems = await integrationService.getScheduleItems(system, type, value);
        } catch (error) {
            setError(error)
            return
        }

        setItemAction({
            type: "planning",
            payload: {
                data: scheduleItems,
                system: system,
                scheduleType: type
            }
        })
    }

    const onEditSchedule = (task) => {
        if (!itemAction) {
            return
        }

        navigate(
            `/integration/${itemAction.payload.system}/${itemAction.payload.systemType}/${task.type}/schedule/${task.id}`,
            navigateOptions
        );
    }

    const onDeleteSchedule = async (task) => {
        try {
            await integrationService.deleteScheduleItem(task.id)
        } catch (error) {
            setError(error)
            return
        }

        setItemAction(itemAction => {
            const rows = itemAction.payload.data

            const newRows = rows.filter(row => row.id !== task.id)

            return {
                ...itemAction,
                payload: {
                    ...itemAction.payload,
                    data: newRows
                }
            }
        })
    }

    const onDownloadItem = async (row) => {
        try {
            const { data, filename } = await integrationService.downloadIntegrationHistoryItem(row.id)

            downloadFile(data, filename)
        } catch (error) {
            setError(error)
        }
    }


    const onScheduleAction = (type, task) => {
        switch (type) {
            case "edit":
                return onEditSchedule(task)
            case "delete":
                return onDeleteSchedule(task, itemAction.payload.scheduleType)
            case "add":
                return onAddSchedule()
            case "search":
                return onSearchSchedule(itemAction.payload.scheduleType, task)
        }
    }

    const onHistoryAction = (type, row) => {
        switch (type) {
            case "download":
                return onDownloadItem(row)
        }
    }

    const onUploadMatrix = async (formData) => {
        const system = itemAction.payload.system;

        dispatch(uploadMatrixSlice({ system, formData }))
    }

    const onCloseUploadMatrixDialog = useCallback(() => {
        setItemAction(null)
        setSearch({ system })
        dispatch(setUploadErrors(null))
    }, [system, dispatch, setSearch]);

    const handlePlanAllClick = (item) => {
        navigate(
            `/integration/${item.id}/${item.systemType}/common/schedule/`,
            navigateOptions
        );
    };

    const handleResetStatusClick = (integrationItem) => {
        if (!canResetIntegrationStatus) {
            return;
        }

        dispatch(resetIntegrationStatus(integrationItem.id));
    };

    return (
        <>
            <Box className="integration-list">
                {integrationItems.map((item) => (
                    <IntegrationSystemBox
                        key={item.id}
                        integrationItem={item}
                        editField={editFieldSystem(item.id, item.systemType)}
                        showResetStatus={
                            canResetIntegrationStatus && (item.common.status === "pending" || item.manualIntegration.status === "pending") 
                        }
                        onResetStatus={() => handleResetStatusClick(item)}
                        onStartClick={handleStartClick(item.id, item.systemType)}
                        onPlanClick={type => {
                            setSearch({ system: item.id, schedule_type: type || "all", system_type: item.systemType})
                        }}
                        onPlanAllClick={() => handlePlanAllClick(item)}
                        onHistoryClick={(type) => {
                            setSearch({ system: item.id, history_type: type, system_type: item.systemType})
                        }}
                        onUploadMatrixClick={() => {
                            setSearch({ system: item.id, upload_matrix: "true" })
                        }}
                        open={search.get("system") === item.id}
                        onOpen={() => {
                            const isOpen = search.get("system") === item.id

                            isOpen ? setSearch({}) : setSearch({ system: item.id })
                        }}
                    />
                ))}
            </Box>

            <ScheduleMaintenanceDialog
                open={itemAction && itemAction.type === "planning"}
                rows={(itemAction && itemAction.payload.data)}
                system={itemAction && itemAction.payload.system}
                scheduleType={itemAction && itemAction.payload.scheduleType}
                onClose={() => {
                    setItemAction(null)
                    setSearch({ system })
                }}
                onScheduleAction={onScheduleAction} />

            <IntegrationHistoryDialog
                open={itemAction && itemAction.type === "history"}
                rows={itemAction && itemAction.payload.data}
                system={itemAction && itemAction.payload.system}
                showDownload={itemAction && itemAction.payload.type !== "manual_integration"}
                onClose={() => {
                    setItemAction(null)
                    setSearch({ system })
                }}
                onRowAction={onHistoryAction} />

            {itemAction && itemAction.type === "uploadMatrix" && <UploadMatrixDialog
                open={itemAction && itemAction.type === "uploadMatrix"}
                system={itemAction && itemAction.payload.system}
                onClose={onCloseUploadMatrixDialog}
                onApply={onUploadMatrix} />
            }
        </>
    );
};

IntegrationList.propTypes = {
    integrationItems: PropTypes.arrayOf(PropTypes.object),
    setError: PropTypes.func,
}

export default IntegrationList;
