
// TODO: при необходимости добавить дельту
export function addGroupRoleChildren(newRoleChild, newChildRoleId,  children) {
    const newChildren = [...children];
    const alreadyInChildren = newChildren?.some(child => (
        child.role === newRoleChild.role &&
        child.systemId === newRoleChild.systemId
    ));

    if (newChildren && !alreadyInChildren) {
        newChildren.push({
            ...newRoleChild,
            id: newChildRoleId,
            isChild: true,
            changed: newRoleChild.changed || false, 
        });
    }
    return newChildren
}

// TODO: при необходимости добавить дельту
export function removeGroupRoleChildren(roleIdToRemove, children = []) {
    return children?.filter(child => roleIdToRemove != child.id);
}