import { useState } from "react";

import { usersService } from "../../../services/users-service";
import { systemsService } from "../../../services/systems-service";
import { systemGroupsService } from "../../../services/system-groups-service";

import { risksService } from "../../../services/risks-service";
import { functionsService } from "../../../services/functions-service";
import { matrixService } from "../../../services/matrix-service";
import { criticalRuleService } from "../../../services/critical-rule-service";
import { customRolesService } from "../../../services/custom-roles-service";
import { busynessProcessesService } from "../../../services/busyness-processes-service";

const useLogs = (entity, key) => {

    const [logs, setLogs] = useState([]);
    const [openLogs, setOpenLogs] = useState(false)

    const getLogs = async () => {
        setOpenLogs(true)

        const logs = await getChangeHistory()

        setLogs(logs);
    };

    const clearLogs = () => {
        setLogs([])
    }

    const getChangeHistory = async () => {
        switch (entity) {
            case "system":
                return await systemsService.getSystemChangeHistory(key)
            case "system_group":
                return await systemGroupsService.getGroupChangeHistory(key)
            case "user":
                return await usersService.getUserChangeHistory(key)
            case "risk":
                return await risksService.getRiskChangeHistory(key)
            case "matrix":
                return await matrixService.getMatrixHeaderChangeHistory(key)
            case "function":
                return await functionsService.getFunctionChangeHistory(key)
            case "critical_role":
                return await criticalRuleService.getCriticalRoleChangeHistory(key)
            case "critical_profile":
                return await criticalRuleService.getCriticalProfileChangeHistory(key)
            case "custom_role":
                return await customRolesService.getCustomRoleChangeHistory(key)
            case "busynessProcess":
                return await busynessProcessesService.getBusynessProcessChangeHistory(key)
        }
    }

    return {
        logs,
        setLogs,
        getLogs,
        clearLogs,
        openLogs,
        setOpenLogs
    }
}

export default useLogs