import { createSlice, createAsyncThunk, createSelector } from "@reduxjs/toolkit";
import { adminService } from "../../services/admin-service";
import { BUSY_TYPES } from "../../utils/busy";

export const SERVICE_ID = {
    CORE: "core",
    SOD: "sod"
}


const initialState = {
    busy: false,
    busyType: null,
    error: null,
    dialogType: null,
    validationErrors: null,
    sodSettings: [],
    coreSettings: [],
    changedCoreSettings: {},
    changedSodSettings: {},
};

export const fetchCoreSettings = createAsyncThunk(
    "admin/fetchCoreSettings",
    async () => {
        const settings = await adminService.getCoreSettings()

        return settings
    }
);

export const fetchSodSettings = createAsyncThunk(
    "admin/fetchSodSettings",
    async () => {
        const settings = await adminService.getSodSettings()

        return settings
    }
);


export const saveCoreSettings = createAsyncThunk(
    "admin/saveCoreSettings",
    async (_, { getState, rejectWithValue }) => {
        const state = getState();

        const changedCoreSettings = selectChangedCoreSettings(state)

        if (changedCoreSettings.length === 0) {
            return rejectWithValue()
        }

        try {
            await adminService.saveCoreSettings(changedCoreSettings);
        } catch (error) {
            return rejectWithValue(error)
        }
    }
);

export const saveSodSettings = createAsyncThunk(
    "admin/saveSodSettings",
    async (_, { getState, rejectWithValue }) => {
        const state = getState();

        const changedSodSettings = selectChangedSodSettings(state)

        if (changedSodSettings.length === 0) {
            return rejectWithValue()
        }

        try {
            await adminService.saveSodSettings(changedSodSettings);
        } catch (error) {
            return rejectWithValue(error)
        }
    }
);

const adminSlice = createSlice({
    name: "admin",
    initialState: initialState,
    reducers: {
        setError(state, action){
            state.error = action.payload;
        },

        setBusy(state, action){
            state.busy = action.payload;
        },

        setDialogType(state, action){
            state.dialogType = action.payload;
        },

        setCoreSetting(state, action){
            const {settingId, value} = action.payload

            const item = state.coreSettings.find(item => item.key === settingId)

            if (!item) return

            item.value = value
            state.changedCoreSettings[settingId] = null
        },

        setSodSetting(state, action){
            const {settingId, value} = action.payload

            const item = state.sodSettings.find(item => item.key === settingId)

            if (!item) return

            item.value = value
            state.changedSodSettings[settingId] = null
        }
    },
    extraReducers: {
        [fetchCoreSettings.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        },

        [fetchCoreSettings.fulfilled]: (state, action) => {
            state.busy = false;
            state.coreSettings = action.payload;
        },

        [fetchCoreSettings.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.error;
        },


        [fetchSodSettings.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        },

        [fetchSodSettings.fulfilled]: (state, action) => {
            state.busy = false;
            state.sodSettings = action.payload;
        },

        [fetchSodSettings.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.error;
        },



        [saveSodSettings.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        },

        [saveSodSettings.fulfilled]: (state) => {
            state.busy = false;
            state.changedSodSettings = {}
            state.dialogType = "success"
        },

        [saveSodSettings.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.error;
        },


        [saveCoreSettings.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        },

        [saveCoreSettings.fulfilled]: (state) => {
            state.busy = false;
            state.changedCoreSettings = {}
            state.dialogType = "success"
        },

        [saveCoreSettings.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.error;
        },
    }
});

export const selectCoreSettings = state => state.admin.admin.coreSettings;
export const selectSodSettings = state => state.admin.admin.sodSettings;

export const selectChangedCoreSettings = state => {
    const changedSettingKeys = new Set(Object.keys(state.admin.admin.changedCoreSettings))

    return state.admin.admin.coreSettings.filter(item => changedSettingKeys.has(item.key))
};

export const selectChangedSodSettings = state => {
    const changedSettingKeys = new Set(Object.keys(state.admin.admin.changedSodSettings))

    return state.admin.admin.sodSettings.filter(item => changedSettingKeys.has(item.key))
};


export const selectSodSetting = createSelector(
    selectSodSettings,
    (_, settingId = "") => settingId,
    (state, settingId) => {
        return state.find(item => item.key === settingId)
    }
);

export const selectCoreSetting = createSelector(
    selectCoreSettings,
    (_, settingId = "") => settingId,
    (state, settingId) => {
        return state.find(item => item.key === settingId)
    }
);

export const { setCoreSetting, setSodSetting, setError, setBusy, setDialogType } = adminSlice.actions;
export default adminSlice.reducer;
