import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import CommonTable from "../../common/table";
import { createRowCellContentInner, getCellClassNameInner } from "../../common/table";
import { SelectIcon } from "../../common/icons";

import "./styles.less";
import { IconButton } from "@mui/material";

const columns = [

    {
        id: "expand",
        type: "expand"
    },

    {
        id: "role",
        title: "Название роли",
        type: "text"
    },
    {
        id: "systemId",
        title: "Система",
        type: "text"
    },
    {
        id: "roleType",
        title: "Тип роли",
        type: "text"
    },
    {
        id: "description",
        title: "Описание",
        type: "text"
    },
];

const getCellValue = (row, column) => {
    switch (column.id) {
        case "roleType":
            return row.isComplex ? "Групповая роль" : "Одиночная роль";

        default:
            return row[column.id];
    }
};



const GroupRoleTable = ({
    rows,
    selectedRole,
    onExpandClick,
    handleRowClick,
}) => {
    const [activeRowId, setActiveRowId] = useState('');

    useEffect(() => {
        if (!selectedRole) {
            setActiveRowId('')
        }
    }, [selectedRole])


    const getCellClassName = (row, column, selected) => {
        let className = getCellClassNameInner(row, column, selected);


        if (row.changed) {
            className += ` group-role-table-cell-changed`;
        }

        if (row.status) {
            className += ` group-role-table-cell-${row.status}`;
        }

        if (row.isChild) {
            className += ` group-role-table-child-cell`
        }
        if (row.id === activeRowId) {
            className += ` group-role-table-active-cell`
        }
        return className;
    }

    const onRowClick = (row) => {
        handleRowClick(row, setActiveRowId)
    }

    const createRowCellContent = (row, column, selected, onSelect, getCellValue) => {

        switch (column.type) {

            case "expand": {
                return (
                    <>
                        {row.isComplex && row.open && (
                            <IconButton
                                onClick={() => { onExpandClick(row) }}
                            >
                                <SelectIcon />
                            </IconButton>
                        )}

                        {row.isComplex && !row.open && (
                            <IconButton
                                onClick={() => { onExpandClick(row) }}
                                sx={{ transform: "rotate(270deg)" }}
                            >
                                <SelectIcon />
                            </IconButton>
                        )}
                    </>
                )
            }

            default:

                return createRowCellContentInner(row, column, selected, onSelect, getCellValue);

        }
    };



    return (

        <CommonTable
            className="group-role-table"
            rows={rows}
            columns={columns}
            showPagination={false}
            showFiltersActions={false}
            getCellValue={getCellValue}
            createRowCellContent={createRowCellContent}
            getCellClassName={getCellClassName}
            onRowClick={onRowClick}
        />

    );
};

GroupRoleTable.propTypes = {
    rows: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        role: PropTypes.string,
        systemId: PropTypes.string,
        isComplex: PropTypes.bool,
        description: PropTypes.string
    })),
    selectedRole: PropTypes.object,
    selectedIds: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ),
    setSelectedIds: PropTypes.func,
    onExpandClick: PropTypes.func,
    handleRowClick: PropTypes.func
};

export default GroupRoleTable;