import { nanoid } from "@reduxjs/toolkit";
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import { Record } from "@bloomberg/record-tuple-polyfill";

import { TrashIcon } from "../components/common/icons";

import { TreeWalker, createTreeNode, findItemInTree, modifyItemAndRecalcParentsFields, modifyItemInTreeRecursive } from "./tree-table";
import { reportsModelingService } from "../services/reports-modeling-service";
import { TRAFFIC_LIGHT_STATUSES } from "./traffic-light";

export const REPORT_MODELING_VIEWS = {
    PFCG: "pfcg",
    PERMISSIONS: "permissions"
};

export const REPORT_MODELING_SUBVIEWS = {
    MENU: "menu",
    PERMISSIONS: "permissions"
};

export const REPORT_MODELING_VALUE_TYPES = {
    SELECT: "select",
    RANGE: "range"
};

export const REPORT_MODELING_DIALOG_TYPES = {
    EDIT: "edit",
    SELECT_PERMISSIONS: "select_permissions",
    MANUAL_SELECT_PERMISSIONS: "manual_select_permissions",
    ORG_LEVELS: "org_levels"
};

export const REPORT_MODELING_STATUSES = {
    CHANGED: "M",
    SET: "G",
    STANDARD: "S",
    MANUAL: "U"
};

export const REPORT_MODELING_DELTA_STATUSES = {
    DELETED: "deleted",
    ADDED: "added",
    CHANGED: "changed"
};

export const REPORT_MODELING_CONFLICT_LEVELS = {
    ROLE: "role",
    GROUP_ROLE: "group_role",
    USERNAME: "username"
};

export const REPORT_MODELING_CONFLICT_LEVELS_USER = {
    USER: "user",
    USER_ALL: "all_users",
};

export const REPORT_MODELING_DELTA_OPERATIONS = {
    DELETE: "d",
    ADD: "a",
    CHANGE: "c"
};

export function calcRoleTreeItemStatus(item) {
    if (!item.children?.length) {
        return item.status;
    }

    if (item.children?.some(child => child.status === REPORT_MODELING_STATUSES.MANUAL)) {
        return REPORT_MODELING_STATUSES.MANUAL;
    }

    if (item.children?.some(child => child.status === REPORT_MODELING_STATUSES.CHANGED)) {
        return REPORT_MODELING_STATUSES.CHANGED;
    }

    if (item.children?.some(child => child.status === REPORT_MODELING_STATUSES.SET)) {
        return REPORT_MODELING_STATUSES.SET;
    }

    return REPORT_MODELING_STATUSES.STANDARD;
}

function isOrgLevelNotInit(values, orgLevel) {
    return values.some(value => value.from.toLowerCase().replace(/^\$/, '') != orgLevel.toLowerCase() || value.to)
}

function calcRoleTreeFieldTrafficLightStatus(fieldItem, orgLevels) {
    if ((fieldItem.status == REPORT_MODELING_STATUSES.STANDARD || fieldItem.status == REPORT_MODELING_STATUSES.MANUAL) && orgLevels && Object.keys(orgLevels).length != 0 && fieldItem.orgLevel) {
        if (isOrgLevelNotInit(orgLevels[fieldItem.orgLevel].values, fieldItem.id)) {
            return TRAFFIC_LIGHT_STATUSES.GREEN;
        } else {
            return TRAFFIC_LIGHT_STATUSES.RED;
        }
    }
    if (fieldItem.orgLevel) {
        if (fieldItem.values && isOrgLevelNotInit(fieldItem.values, fieldItem.id)) {
            return TRAFFIC_LIGHT_STATUSES.GREEN;
        } else {
            return TRAFFIC_LIGHT_STATUSES.RED
        }
    }
    if (fieldItem.orgLevel && !fieldItem.values ||
        fieldItem.values && fieldItem.values.some(value => value.from || value.to)) {
        return TRAFFIC_LIGHT_STATUSES.GREEN;
    }

    return TRAFFIC_LIGHT_STATUSES.YELLOW;
}

export function calcRoleTreeItemTrafficLightStatus(item, orgLevels) {
    if (item.type === "field") {
        return calcRoleTreeFieldTrafficLightStatus(item, orgLevels);
    }

    if (item.children?.some(child => child.trafficLightStatus === TRAFFIC_LIGHT_STATUSES.RED)) {
        return TRAFFIC_LIGHT_STATUSES.RED;
    }

    if (item.children?.some(child => child.trafficLightStatus === TRAFFIC_LIGHT_STATUSES.YELLOW)) {
        return TRAFFIC_LIGHT_STATUSES.YELLOW;
    }

    return TRAFFIC_LIGHT_STATUSES.GREEN;
}

export function recalcRoleTreeTrafficLightStatus(tree, orgLevels) {
    const treeWalker = new TreeWalker(tree);
    treeWalker.walk(
        node => {
            node.trafficLightStatus = calcRoleTreeFieldTrafficLightStatus(node, orgLevels)
        },
        () => {
            return {
                keyField: "",
                keyValue: "",
                childField: "children"
            }
        },
        (node) => {
            node.trafficLightStatus = calcRoleTreeItemTrafficLightStatus(node, orgLevels)
        }
    )
}

function getNewPermission(profile, description, children, lastExistingPermission, isStandart) {
    let permissionIdEnd;

    if (lastExistingPermission) {
        const lastPermissionNumber = Number(lastExistingPermission.id.slice(-2));
        permissionIdEnd = String(lastPermissionNumber + 1).padStart(2, "0");
    } else {
        permissionIdEnd = "00";
    }

    // children && children.forEach(child => {
    //     if (!!child.orgLevel){
    //         child.status = REPORT_MODELING_STATUSES.STANDARD 
    //     }
    // })

    return {
        id: `${profile}${permissionIdEnd}`,
        rowId: nanoid(),
        type: "permission",
        description,
        children,
        status: isStandart ? REPORT_MODELING_STATUSES.STANDARD : REPORT_MODELING_STATUSES.MANUAL
    };
}

export function addOrgLevel(orgLevels, id, description, values, orgLevelsDelta) {
    const newOrgLevel = {
        id,
        description,
        values: !values?.length
            ? [{
                rowId: nanoid(),
                from: "",
                to: ""
            }]
            : values
    };

    orgLevels[id] = newOrgLevel;
    addRoleTreeOrgLevelDelta(newOrgLevel, orgLevelsDelta);
}

export function sortTreeChildren(children) {
    return children?.sort((a, b) => a.id.localeCompare(b.id));
}

function parseNewRoleTreeItem(item, profile, orgLevels, defaultValues, orgLevelsDelta, deltaKeys = {}, isStandart = false) {
    const filteredDefaultValues = getDefaultValuesForRoleTreeItem(defaultValues, item, deltaKeys);

    if (item.type === "field" &&
        item.orgLevel &&
        !(item.orgLevel in orgLevels)) {
        addOrgLevel(orgLevels, item.orgLevel, item.description, filteredDefaultValues, orgLevelsDelta);
    }

    const isOrgLevel = item.orgLevel in orgLevels;

    const parsedItem = {
        ...item,
        active: true,
        status: REPORT_MODELING_STATUSES.MANUAL
    };

    deltaKeys[parsedItem.type] = parsedItem.id;

    switch (parsedItem.type) {
        case "field":
            if (!isOrgLevel) {
                parsedItem.values = filteredDefaultValues ?? [];
            } else {
                parsedItem.values = orgLevels[`${parsedItem.orgLevel}`].values;
            }

            if (isStandart || parsedItem.orgLevel) {
                parsedItem.status = REPORT_MODELING_STATUSES.STANDARD
            }

            //addRoleTreeDelta(parsedItem, delta, deltaKeys);

            break;

        case "permission_object": {

            const permission = getNewPermission(profile, parsedItem.description, parsedItem.children, null, isStandart);
            parsedItem.children = [permission];

            parsedItem.status = calcRoleTreeItemStatus(parsedItem)
            break;
        }

        case "permission": {
            if (isStandart) {
                parsedItem.status = REPORT_MODELING_STATUSES.STANDARD
            }
            break;
        }

        case "class": {
            parsedItem.children = sortTreeChildren(parsedItem.children);

            parsedItem.status = calcRoleTreeItemStatus(parsedItem)
            break;
        }
    }

    return parsedItem;
}

function getDefaultValuesForRoleTreeItem(defaultValues, item, deltaKeys = {}) {
    if (item.type === "permission_object") {
        return defaultValues?.filter(defaultValue => defaultValue.permission === item.id);
    }

    if (item.type === "field") {
        const permission = deltaKeys["permission_object"]

        return defaultValues && defaultValues
            .filter(defaultValue => defaultValue.field === item.id && defaultValue.permission === permission)
            .map(defaultValue => ({
                rowId: nanoid(),
                from: defaultValue.valueFrom,
                to: defaultValue.valueTo
            }));
    }

    return defaultValues;
}

function parseNewRoleTreeNode(node, orgLevels) {
    return {
        ...node,
        trafficLightStatus: calcRoleTreeItemTrafficLightStatus(node, orgLevels)
    };
}

function mergeStandardPermission(node, tree, delta, deltaKeys) {

    if (node.status !== REPORT_MODELING_STATUSES.STANDARD) {
        return node
    }

    const statusesForAddStandeardPermission = [REPORT_MODELING_STATUSES.STANDARD, REPORT_MODELING_STATUSES.SET]

    const currentStandartPermission = tree.find(item => statusesForAddStandeardPermission.includes(item.status))

    if (!currentStandartPermission) {
        return node
    }

    const nodeFieldValuesMap = {}
    const currentFieldValuesMap = {}

    deltaKeys.permission = currentStandartPermission.id

    node.children.forEach(field => {

        if (!field.values) {
            return
        }

        nodeFieldValuesMap[field.id] = new Set(field.values.map(value => {
            return Record({
                from: value.from,
                to: value.to
            })
        }))
    })

    currentStandartPermission.children.forEach(field => {
        if (!field.values) {
            return
        }

        currentFieldValuesMap[field.id] = new Set(field.values.map(value => {
            return Record({
                from: value.from,
                to: value.to
            })
        }))

        if (nodeFieldValuesMap[field.id]) {
            Array.from(nodeFieldValuesMap[field.id]).forEach(value => {
                currentFieldValuesMap[field.id].add(value)
            })
        }

        field.values = Array.from(currentFieldValuesMap[field.id]).map(val => ({
            rowId: nanoid(),
            from: val.from,
            to: val.to
        }))

        updateRoleTreeDelta(field, delta, deltaKeys, "values", field.values)

    })

    return null
}

function addNewItemToRoleTree(profile, orgLevels, tree, item, parentPath, level, defaultValues, delta, orgLevelsDelta, deltaKeys, isStandart) {
    const filteredDefaultValues = getDefaultValuesForRoleTreeItem(defaultValues, item, deltaKeys);

    let node = createTreeNode(
        item,
        level,
        parentPath,
        parseNewRoleTreeNode,
        (item) => parseNewRoleTreeItem(item, profile, orgLevels, filteredDefaultValues, orgLevelsDelta, deltaKeys, isStandart),
        orgLevels
    );
    if (node && orgLevels) {
        recalcRoleTreeTrafficLightStatus([node], orgLevels)
    }

    const nextTreeItemIndex = item.type === "class" || item.type === "permission_object"
        ? tree.findIndex(treeItem => treeItem.id.localeCompare(item.id) === 1)
        : -1;

    if (item.type === "class") {
        node.status = calcRoleTreeItemStatus(node)
    }

    if (item.type === "permission") {
        // if (checkPermissionShouldNotBeAdded(node, tree)) {
        //     return
        // }

        node = mergeStandardPermission(node, tree, delta, deltaKeys)

        if (!node) {
            return
        }
    }

    addRoleTreeDeltaFromNode(node, delta, deltaKeys)

    if (nextTreeItemIndex >= 0) {
        tree.splice(nextTreeItemIndex, 0, node);
    } else {
        tree.push(node);
    }
}

function addExistingItemToRoleTree(existingItem, profile, orgLevels, newItem, level, defaultValues, delta, orgLevelsDelta, deltaKeys, isStandart) {
    const filteredDefaultValues = getDefaultValuesForRoleTreeItem(defaultValues, existingItem, deltaKeys);

    deltaKeys[existingItem.type] = existingItem.id;

    if (existingItem.type === "permission_object") {
        const lastPermission = existingItem.children[existingItem.children.length - 1];
        const permission = getNewPermission(profile, newItem.description, newItem.children, lastPermission, isStandart);
        addNewItemToRoleTree(profile, orgLevels, existingItem.children, permission, existingItem.path, level + 1, filteredDefaultValues, delta, orgLevelsDelta, deltaKeys, isStandart);
    } else if (newItem.children) {
        for (const child of newItem.children) {
            addItemToRoleTree(profile, orgLevels, existingItem.children, child, existingItem.path, level + 1, filteredDefaultValues, delta, orgLevelsDelta, deltaKeys, isStandart);
        }
    }

    existingItem.status = calcRoleTreeItemStatus(existingItem);
    existingItem.trafficLightStatus = calcRoleTreeItemTrafficLightStatus(existingItem, orgLevels);
}

export function addItemToRoleTree(profile, orgLevels, tree, item, parentPath, level, defaultValues, delta, orgLevelsDelta, deltaKeys, isStandart) {
    const currentTreeItem = tree.find(treeItem => treeItem.id === item.id);

    if (!currentTreeItem) {
        addNewItemToRoleTree(profile, orgLevels, tree, item, parentPath, level, defaultValues, delta, orgLevelsDelta, deltaKeys, isStandart);
    } else {
        addExistingItemToRoleTree(currentTreeItem, profile, orgLevels, item, level, defaultValues, delta, orgLevelsDelta, deltaKeys, isStandart);
    }
}

export function getPermissionFieldKey(permission, field) {
    return `${permission}-${field}`
}

export function isOrgLevelValue(val) {
    if (val.startsWith("$")) {
        return true
    }
    return false
}

export function getDeletedDefaultValuesForTcodes(deletedTcodes, remainedTcodes, defaultValueMap) {
    const remainedDefaultValue = {}
    const defaultValueMapForDelete = {}

    const remainedOrgLevels = new Set()
    const orgLevelsForDelete = new Set()

    remainedTcodes.forEach(tcode => {
        const defaultValues = defaultValueMap[tcode];

        if (!defaultValues) {
            return
        }

        defaultValues.forEach(value => {
            if (isOrgLevelValue(value.valueFrom)) {
                remainedOrgLevels.add(value.valueFrom)
            }

            const valueKey = getPermissionFieldKey(value.permission, value.field)

            if (!(valueKey in remainedDefaultValue)) {
                remainedDefaultValue[valueKey] = new Set()
            }

            remainedDefaultValue[valueKey].add(new Record({
                from: value.valueFrom,
                to: value.valueTo
            }))
        })
    })

    deletedTcodes.forEach(tcode => {
        const defaultValues = defaultValueMap[tcode];

        if (!defaultValues) {
            return
        }

        defaultValues.forEach(value => {

            if (isOrgLevelValue(value.valueFrom) && !remainedOrgLevels.has(value.valueFrom)) {
                orgLevelsForDelete.add(value.valueFrom)
            }

            const valueKey = getPermissionFieldKey(value.permission, value.field)

            if (!(valueKey in defaultValueMapForDelete)) {
                defaultValueMapForDelete[value] = new Set()
            }

            const valueRecord = new Record({
                from: value.valueFrom,
                to: value.valueTo
            })

            const remainedValuesSet = remainedDefaultValue[valueKey]

            if (!remainedValuesSet) {
                if (!(valueKey in defaultValueMapForDelete)) {
                    defaultValueMapForDelete[valueKey] = new Set()
                }

                defaultValueMapForDelete[valueKey].add(valueRecord)
                return
            }

            if (!remainedValuesSet.has(valueRecord)) {
                if (!(valueKey in defaultValueMapForDelete)) {
                    defaultValueMapForDelete[valueKey] = new Set()
                }

                defaultValueMapForDelete[valueKey].add(valueRecord)
            }
        })
    })

    return { defaultValuesForDelete: defaultValueMapForDelete, orgLevelsForDelete }
}

export function substractPfcgTreeFromRole(roleTree, defaultValuesForDelete, delta, orgLevels, orgLevelsDelta, orgLevelsForDelete) {

    const treeWalker = new TreeWalker(roleTree)

    const idsSetForDelete = new Set()
    const remainedOrgLevelSet = new Set()

    //console.log(JSON.parse(JSON.stringify(delta)))

    const statusesForSubstract = [REPORT_MODELING_STATUSES.STANDARD, REPORT_MODELING_STATUSES.SET]

    try {
        treeWalker.walk(
            (nodePermission, parentKeyPermissionMap) => {
                if (nodePermission.type !== "permission") {
                    return
                }

                parentKeyPermissionMap[nodePermission.type] = nodePermission.id

                let anotherValueRemained = false

                const idsForDeleteMapTmp = {}

                nodePermission.children.forEach(node => {
                    if (!statusesForSubstract.includes(node.status)) {

                        if (node.orgLevel) {
                            orgLevelsForDelete.delete(node.orgLevel)
                        }

                        return
                    }

                    const parentKeyMap = {
                        ...parentKeyPermissionMap,
                        [node.type]: node.id
                    }

                    const permissionFieldKey = getPermissionFieldKey(parentKeyMap.permission_object, node.id)

                    if (permissionFieldKey in defaultValuesForDelete) {
                        const newNodeValues = []

                        if (node.orgLevel && orgLevelsForDelete.has(node.orgLevel)) {
                            idsForDeleteMapTmp[node.rowId] = node
                        }

                        if (!node.values) {
                            const defaultValuesForDeleteList = defaultValuesForDelete[permissionFieldKey]

                            if (!defaultValuesForDeleteList) {
                                return
                            }

                            //case when remove org level
                            defaultValuesForDeleteList.forEach(val => {
                                if (isOrgLevelValue(val.from) && val.from === node.orgLevel) {
                                    idsForDeleteMapTmp[node.rowId] = node

                                    remainedOrgLevelSet.add(val.from)
                                }
                            })

                            return
                        }

                        node.values.forEach(value => {
                            const valueRecord = new Record({
                                from: value.from,
                                to: value.to
                            })

                            const mutableValueRecord = {
                                ...valueRecord
                            };

                            if (node.orgLevel && node.status == REPORT_MODELING_STATUSES.STANDARD) {
                                mutableValueRecord.from = node.orgLevel;
                            }
                            
                            const existsInSet = Array.from(defaultValuesForDelete[permissionFieldKey])
                                .some(record => record.from === mutableValueRecord.from && record.to === mutableValueRecord.to);

                            if (!existsInSet) {
                                newNodeValues.push(value);
                                anotherValueRemained = true;
                            }

                            // if (!defaultValuesForDelete[permissionFieldKey].has(valueRecord)) {
                            //     newNodeValues.push(value)

                            //     anotherValueRemained = true
                            // }
                        })

                        node.values = newNodeValues

                        updateRoleTreeDelta(node, delta, parentKeyMap, "values", node.values)

                        if (newNodeValues.length === 0) {
                            idsForDeleteMapTmp[node.rowId] = node
                        }

                        return
                    }

                    if (node.values && node.values.length === 0) {
                        idsForDeleteMapTmp[node.rowId] = node
                    }
                })

                if (!anotherValueRemained) {
                    Object.entries(idsForDeleteMapTmp).forEach(([rowId, node]) => {
                        idsSetForDelete.add(rowId)

                        const parentKeyMap = {
                            ...parentKeyPermissionMap,
                            [node.type]: node.id
                        }

                        removeRoleTreeDelta(node, delta, parentKeyMap)
                    })
                } else {
                    remainedOrgLevelSet.forEach(tcode => {
                        orgLevelsForDelete.delete(tcode)
                    })
                    Object.entries(idsForDeleteMapTmp).forEach(([rowId]) => {
                        idsSetForDelete.add(rowId)
                    })
                }

                nodePermission.children = nodePermission.children.filter(childNode => !idsSetForDelete.has(childNode.rowId))

                if (nodePermission.children.length === 0) {
                    idsSetForDelete.add(nodePermission.rowId)
                }
            },
            node => {
                if (node.type === "permission") {
                    return null
                }

                return {
                    keyField: node.type,
                    keyValue: node.id,
                    childField: "children"
                }
            },
            (node) => {
                const newChildren = node.children.filter(childNode => !idsSetForDelete.has(childNode.rowId))

                node.children = newChildren

                if (newChildren.length === 0) {
                    idsSetForDelete.add(node.rowId)
                }
            }
        )

        removeOrgLevelDelta(orgLevelsForDelete, orgLevels, orgLevelsDelta)
        recalcRoleTreeTrafficLightStatus(roleTree, orgLevels)

    } catch (error) {
        console.log(error)
    }

}

export function modifyItemFieldInRoleTree(orgLevels, tree, itemPath, field, value, delta, currentPathIndex = 0, deltaKeys = {}) {
    const currentId = itemPath[currentPathIndex];
    const item = tree?.find(child => child.rowId === currentId);

    if (!item) {
        return;
    }

    deltaKeys[item.type] = item.id;

    if (currentPathIndex < itemPath.length - 1) {
        modifyItemFieldInRoleTree(
            orgLevels, item.children, itemPath, field, value, delta, currentPathIndex + 1, deltaKeys
        );

        recalcParentRoleTreeItem(item, field, orgLevels)
    } else {
        modifyRoleTreeItem(item, field, value, orgLevels, delta, deltaKeys);
    }
}

function updateRoleTreeDelta(item, delta, deltaKeys, field, value) {
    deltaKeys[item.type] = item.id;

    if (item.type !== "field") {
        item.children?.forEach(child =>
            updateRoleTreeDelta(child, delta, deltaKeys, field, value)
        );
        return;
    }

    const currentDeltaIndex = delta.findIndex(deltaItem => (
        Object.entries(deltaKeys).every(([key, value]) => deltaItem[key] === value)
    ));

    if (currentDeltaIndex === -1) {
        const newDeltaItem = {
            ...deltaKeys,
            active: item.active,
            status: item.status,
            values: item.values,
            operation: REPORT_MODELING_DELTA_OPERATIONS.CHANGE
        };

        delta.push(newDeltaItem)
    } else {
        delta[currentDeltaIndex][field] = value;
        delta[currentDeltaIndex].status = item.status;
    }
}

function addRoleTreeDeltaFromNode(item, delta, deltaKeys) {

    deltaKeys[item.type] = item.id;

    if (item.type !== "field") {
        item.children?.forEach(child =>
            addRoleTreeDeltaFromNode(child, delta, deltaKeys)
        );
        return;
    }

    const currentDeltaIndex = delta.findIndex(deltaItem => (
        Object.entries(deltaKeys).every(([key, value]) => deltaItem[key] === value)
    ));

    if (currentDeltaIndex === -1) {
        const newDeltaItem = {
            ...deltaKeys,
            active: item.active,
            status: item.status,
            values: item.values,
            operation: REPORT_MODELING_DELTA_OPERATIONS.ADD
        };

        delta.push(newDeltaItem)
    } else {
        delta[currentDeltaIndex].active = item.active;
        delta[currentDeltaIndex].status = item.status;
        delta[currentDeltaIndex].values = item.values;

        if (delta[currentDeltaIndex].operation === REPORT_MODELING_DELTA_OPERATIONS.DELETE) {
            delta[currentDeltaIndex].operation = REPORT_MODELING_DELTA_OPERATIONS.CHANGE;
        }
        //delta[currentDeltaIndex].operation = REPORT_MODELING_DELTA_OPERATIONS.ADD;
    }
}

function addRoleTreeOrgLevelDelta(orgLevel, orgLevelsDelta) {
    const currentOrgLevelDelta = orgLevelsDelta.find(delta => delta.id === orgLevel.id);

    if (currentOrgLevelDelta) {
        if (currentOrgLevelDelta.operation === REPORT_MODELING_DELTA_OPERATIONS.DELETE) {
            currentOrgLevelDelta.operation = REPORT_MODELING_DELTA_OPERATIONS.ADD
            currentOrgLevelDelta.values = orgLevel.values
        }
    } else {
        orgLevelsDelta.push({
            id: orgLevel.id,
            operation: REPORT_MODELING_DELTA_OPERATIONS.ADD,
            values: orgLevel.values
        });
    }
}

function updateRoleTreeOrgLevelDelta(orgLevelId, values, orgLevelsDelta) {
    const currentOrgLevelDelta = orgLevelsDelta.find(delta => delta.id === orgLevelId);

    if (currentOrgLevelDelta) {
        currentOrgLevelDelta.values = values;
    } else {
        orgLevelsDelta.push({
            id: orgLevelId,
            operation: REPORT_MODELING_DELTA_OPERATIONS.CHANGE,
            values: values
        });
    }
}

function convertRoleTreeValuesToString(values) {
    return values && values
        .map(({ from, to }) => `${from}${to}`)
        .join("");
}

function checkRoleTreeValuesEqual(values1, values2) {
    const valuesString1 = convertRoleTreeValuesToString(values1);
    const valuesString2 = convertRoleTreeValuesToString(values2);
    return valuesString1 === valuesString2;
}

export function updateRoleTreeOrgLevels(orgLevels, changedValues, orgLevelsDelta, tree) {
    for (const orgLevelId in orgLevels) {
        const currentOrgLevel = orgLevels[orgLevelId];
        const changedOrgLevelValue = changedValues[orgLevelId];

        if (checkRoleTreeValuesEqual(currentOrgLevel.values, changedOrgLevelValue)) {
            continue;
        }

        currentOrgLevel.values = changedOrgLevelValue;
        updateRoleTreeOrgLevelDelta(orgLevelId, changedOrgLevelValue, orgLevelsDelta);

    }
    const treeWalker = new TreeWalker(tree);
    treeWalker.walk(
        node => {
            node.trafficLightStatus = calcRoleTreeFieldTrafficLightStatus(node, orgLevels)
            const isOrgLevel = node.orgLevel in orgLevels;
            if (isOrgLevel) {
                node.values = orgLevels[`${node.orgLevel}`].values;
            }
        },
        () => {
            return {
                keyField: "",
                keyValue: "",
                childField: "children"
            }
        },
        (node) => {
            node.trafficLightStatus = calcRoleTreeItemTrafficLightStatus(node, orgLevels)
        }
    )

    //recalcRoleTreeTrafficLightStatus(tree, orgLevels)
}

function modifyRoleTreeItem(item, field, value, orgLevels, delta, deltaKeys) {
    const isActiveField = field === "active";
    const isOpenField = field === "open";
    const isValuesField = field === "values";
    const shouldRecalcStatus = !isActiveField && !isOpenField;
    const shouldUpdateDelta = !isOpenField;

    if (isActiveField) {
        modifyItemInTreeRecursive(item, field, value);
    } else {
        item[field] = value;
    }

    if (shouldRecalcStatus) {
        let newStatus = field === "values" && item.orgLevel in orgLevels && !value
            ? REPORT_MODELING_STATUSES.STANDARD
            : REPORT_MODELING_STATUSES.CHANGED;

        if (field === "values" && item.status === REPORT_MODELING_STATUSES.CHANGED && item.values) {
            newStatus = REPORT_MODELING_STATUSES.MANUAL
        }

        if (field === "values" && item.status === REPORT_MODELING_STATUSES.STANDARD && item.values) {
            newStatus = REPORT_MODELING_STATUSES.SET
        }

        if (field === "values" && item.status === REPORT_MODELING_STATUSES.SET && item.values) {
            newStatus = REPORT_MODELING_STATUSES.SET
        }
        if (!item.values) {
            newStatus = REPORT_MODELING_STATUSES.STANDARD
        }

        modifyItemInTreeRecursive(item, "status", newStatus);
    }

    if (isValuesField) {
        item.trafficLightStatus = calcRoleTreeItemTrafficLightStatus(item, orgLevels);
    }

    if (shouldUpdateDelta) {
        updateRoleTreeDelta(item, delta, deltaKeys, field, value)
    }
}

function recalcParentRoleTreeItem(item, field, orgLevels) {
    const isActiveField = field === "active";
    const isOpenField = field === "open";
    const isValuesField = field === "values";
    const shouldRecalcStatus = !isActiveField && !isOpenField;
    const shouldCalcTrafficLight = isValuesField || !field;

    if (isActiveField || field === undefined) {
        item.active = item.children?.some(child => child.active);
    }

    if (shouldRecalcStatus) {
        item.status = calcRoleTreeItemStatus(item);
    }

    if (shouldCalcTrafficLight) {
        item.trafficLightStatus = calcRoleTreeItemTrafficLightStatus(item, orgLevels);
    }
}

export function filterNewPermissionsInRoleTree(roleTree, permissions) {
    const permissionsSet = new Set(permissions);

    for (const classNode of roleTree) {
        if (!classNode.children?.length) {
            continue;
        }

        for (const permissionObject of classNode.children) {
            permissionsSet.delete(permissionObject.id);
        }
    }

    return [...permissionsSet];
}

export function setClassCheckedIndeterminate(item) {
    const hasChildren = Boolean(item.children);
    item.checked = hasChildren && item.children.every(child => child.checked);
    item.indeterminate = !item.checked && hasChildren && item.children.some(child => child.checked || child.indeterminate);
}

export function filterCheckedTreeItems(tree) {
    return tree
        .filter(child => child.checked || child.indeterminate)
        .map(child => {
            if (!child.children) {
                return child;
            }

            return {
                ...child,
                children: filterCheckedTreeItems(child.children)
            }
        });
}

function handlePermissionChecked(checkedPermissions, permissionId, checked) {
    if (checked) {
        checkedPermissions.add(permissionId);
    } else {
        checkedPermissions.delete(permissionId);
    }
}

function modifyCheckedInPermissionsTree(tree, itemPath, field, value, checkedPermissions) {
    modifyItemAndRecalcParentsFields(
        tree,
        itemPath,
        (item) => {
            switch (item.type) {
                case "permission_object":
                    handlePermissionChecked(checkedPermissions, item.id, value);
                    break;

                case "class":
                    for (const permission of item.children) {
                        handlePermissionChecked(checkedPermissions, permission.id, value);
                    }
                    break;
                default:
                    return;
            }

            item.indeterminate = false;
            modifyItemInTreeRecursive(item, field, value);
        },
        setClassCheckedIndeterminate
    );
}

export function modifySelectPermissionsTree(tree, itemPath, field, value, checkedPermissions) {
    if (field === "checked") {
        modifyCheckedInPermissionsTree(tree, itemPath, field, value, checkedPermissions);
        return;
    }

    const item = findItemInTree(itemPath, tree, "rowId");

    if (item) {
        item[field] = value;
    }
}

function parseValuesToCalc(values) {
    return values?.map(({ from, to }) => ({
        value_from: from,
        value_to: to
    }));
}

function addTreeNodeToCalc(permissionsArray, treeNode, orgLevels, fieldKeys = {}) {
    if (treeNode.type === "field") {
        const valuesToParse = treeNode.orgLevel && !treeNode.values
            ? orgLevels[treeNode.orgLevel].values
            : treeNode.values;

        const permission = {
            ...fieldKeys,
            field: treeNode.id,
            status: treeNode.status,
            values: parseValuesToCalc(valuesToParse)
        }

        permissionsArray.push(permission);
    } else if (treeNode.active) {
        switch (treeNode.type) {
            case "permission_object":
                fieldKeys.permission_object = treeNode.id;
                break;

            case "permission":
                fieldKeys.permission_group = treeNode.id;
                break;
        }

        for (const childNode of treeNode.children) {
            addTreeNodeToCalc(permissionsArray, childNode, orgLevels, fieldKeys);
        }
    }
}

function parseTreeToCalc(tree, orgLevels) {
    const permissionsArray = [];

    for (const treeNode of tree) {
        addTreeNodeToCalc(permissionsArray, treeNode, orgLevels);
    }

    return permissionsArray;
}

// async function getMappedGroupRoleChildrens(role, variantName, reportLevel){
//     const data = await reportsModelingService.getRoleChildrensVariant(role.role, role.systemId, reportLevel, variantName);
//     return [role.id, data];
// }

export async function getRolePermissionsMap(rolesToFetch, variantName = "", reportLevel, username) {
    if (!rolesToFetch?.length) {
        return {};
    }

    const batchStruct = rolesToFetch.map(role => ({
        url: `/api/core/pfcg/role/${role.role}?system=${role.systemId}&variant_name=${variantName}&level=${reportLevel}&user=${username}`,
        method: "get"
    }))

    const reponse = await reportsModelingService.post("/core/batch/", batchStruct)

    const result = {}

    reponse.forEach((item, i) => {
        if (item.status_code !== 200) {
            return
        }

        const roleData = rolesToFetch[i]

        const tree = reportsModelingService.parseRolePermissionsTree(item.body.tree);
        const orgLevels = reportsModelingService.parseRoleOrgLevels(item.body.orgLevels);
        const menu = reportsModelingService.parseRoleMenu(item.body.menu);
        const childrens = reportsModelingService.parseRoleChildrenList(item.body.children, roleData.systemId)

        result[roleData.id] = { tree, orgLevels, menu, childrens }
    })

    return result
}

// async function getGroupRoleChildrensMap(rolesToFetch, variantName="", reportLevel){
//     if (!rolesToFetch?.length) {
//         return {};
//     }

//     if (rolesToFetch.length === 0){
//         return {}
//     }

//     const promises = rolesToFetch.map(role => getMappedGroupRoleChildrens(role, variantName, reportLevel));
//     const permissionsEntries = await Promise.all(promises);
//     return Object.fromEntries(permissionsEntries);
// }

export async function getManualRolesForSave(roles) {
    //const rolesToFetch = roles.filter(role => !role.isComplex && role.status && !role.tree);
    //const permissionsMap = await getRolePermissionsMap(rolesToFetch, variantName, reportLevel);

    const manualRoles = roles.filter(role => role.manual)
    const rolePfcg = []
    const roleMenu = []
    const groupRoles = []
    const orgLevelsForCalc = []

    manualRoles.forEach(({ role, systemId, tree, orgLevels, menu, isComplex, children }) => {
        if (isComplex && children) {
            groupRoles.push({
                role,
                system_id: systemId,
                childrens: children.map(role => role.role),
            })

            return
        }

        if (orgLevels) {

            const orgLevelItem = {
                role: role,
                system_id: systemId,
                org_levels: Object.values(orgLevels).map(item => ({
                    org_level: item.id,
                    values: item.values.map(val => ({ value_from: val.from, value_to: val.to }))
                }))
            }

            if (orgLevelItem.org_levels.length > 0) {
                orgLevelsForCalc.push(orgLevelItem)
            }
        }

        const roleItem = {
            role,
            system_id: systemId,
            permissions: [],
        }

        const [treeInner, orgLevelsInner] = [tree, orgLevels]

        if (tree) {
            roleItem["permissions"] = parseTreeToCalc(treeInner, orgLevelsInner)
        }

        if (roleItem["permissions"].length > 0) {
            rolePfcg.push(roleItem)
        }


        const roleMenuItem = {
            role,
            system_id: systemId,
            menu: []
        }

        roleMenuItem["menu"] = (menu?.[0]?.children || []).map(child => reportsModelingService.parseRoleMenuChildVariant(child))

        if (menu && roleMenuItem["menu"].length > 0) {
            roleMenu.push(roleMenuItem)
        }

        return roleItem
    });

    return { rolePfcg, roleMenu, groupRoles, orgLevels: orgLevelsForCalc }
}

export async function getModelingRolesForCalc(roles, prefetchedRoles, changedRoleSet) {



    // const updatedRoles = roles.map(role => ({
    //     ...role,
    //     is_explicit: explicitRoles.some(r => r.id === role.id) 
    // }));

    return roles
        .filter(role => changedRoleSet.has(role.id) && !role.isComplex)
        .map(({ id, role, systemId, tree, orgLevels, status, initialized, is_explicit }) => {
            const permissions = prefetchedRoles[id];

            if (!tree && !permissions) {
                return {
                    role,
                    system_id: systemId,
                    is_new: status === REPORT_MODELING_DELTA_STATUSES.ADDED,
                    permissions: []
                }
            }

            const [treeInner, orgLevelsInner] = initialized
                ? [tree, orgLevels] : [permissions.tree, permissions.orgLevels];

            return {
                role,
                system_id: systemId,
                is_new: status === REPORT_MODELING_DELTA_STATUSES.ADDED,
                permissions: parseTreeToCalc(treeInner, orgLevelsInner),
                is_explicit
            }
        });
}

export async function initializeNewRoles(calcRoles, addedRoleIdSet) {
    const {
        rolePfcg: rolesPfcgForInit,
        roleMenu: roleMenuForInit,
        groupRoles: groupRolesForInit,
        orgLevels: changedOrgLevelsForInit }
        = await getManualRolesForSave(calcRoles.filter(role => addedRoleIdSet.has(role.id)))

    if (rolesPfcgForInit.length > 0 || roleMenuForInit.length > 0 || groupRolesForInit.length > 0 || changedOrgLevelsForInit.length > 0) {
        try {
            await reportsModelingService.initializeNewRoles(rolesPfcgForInit, roleMenuForInit, groupRolesForInit, changedOrgLevelsForInit);
        } catch (error) {
            console.log(error)
        }
    }
}


export function getModelingGroupRolesForCalc(roles) {
    return roles
        .filter(role => role.isComplex)
        .map(({  role, systemId, status, children, }) => {
            return {
                role,
                system_id: systemId,
                is_new: status === REPORT_MODELING_DELTA_STATUSES.ADDED,
                childrens: children.map(item => item.role)
            }
        });
}

export function parseModelingRolesToCalcForm(roles) {
    return roles
        .filter(role => role.status)
        .map(({ role, systemId, tree, orgLevels }) => ({
            role,
            system_id: systemId,
            is_new: role.status === REPORT_MODELING_DELTA_STATUSES.ADDED,
            permissions: parseTreeToCalc(tree, orgLevels),
        }));
}

export function parseModelingUsersToCalcForm(users) {
    return users.map(({ employee, systemId, roles }) => ({
        employee,
        system_id: systemId,
        roles: roles
            .filter(role => role.status !== REPORT_MODELING_DELTA_STATUSES.DELETED)
            .map(role => role.role)
    }));
}

export function getEntityDeleteIcon(entityStatus) {
    return entityStatus === REPORT_MODELING_DELTA_STATUSES.DELETED
        ? RestoreFromTrashIcon
        : TrashIcon;
}

function removeOrgLevelDelta(orgLevelsForDelete, orgLevels, orgLevelsDelta) {
    orgLevelsForDelete.forEach(orgLevel => {
        delete orgLevels[orgLevel]

        const orgLevelDeltaIndex = orgLevelsDelta.findIndex(item => item.id === orgLevel)

        if (orgLevelDeltaIndex === -1) {
            orgLevelsDelta.push({
                id: orgLevel,
                operation: REPORT_MODELING_DELTA_OPERATIONS.DELETE,
                values: []
            })

            return
        }

        const orgLevelDelta = orgLevelsDelta[orgLevelDeltaIndex]

        if (orgLevelDelta.operation === REPORT_MODELING_DELTA_OPERATIONS.ADD) {
            orgLevelsDelta.splice(orgLevelDeltaIndex, 1)
            return
        }

        orgLevelDelta.operation = REPORT_MODELING_DELTA_OPERATIONS.DELETE
    })
}

function removeRoleTreeDelta(item, delta, deltaKeys) {
    deltaKeys[item.type] = item.id;

    if (item.type !== "field") {
        item.children?.forEach(child =>
            removeRoleTreeDelta(child, delta, deltaKeys)
        );
        return;
    }

    const currentDeltaIndex = delta.findIndex(deltaItem => (
        Object.entries(deltaKeys).every(([key, value]) => deltaItem[key] === value)
    ));

    if (currentDeltaIndex === -1) {
        const newDeltaItem = {
            ...deltaKeys,
            active: item.active,
            status: item.status,
            values: item.values,
            operation: REPORT_MODELING_DELTA_OPERATIONS.DELETE
        };

        delta.push(newDeltaItem)
        return;
    }

    const deltaItem = delta[currentDeltaIndex];

    if (deltaItem.operation === REPORT_MODELING_DELTA_OPERATIONS.ADD) {
        delta.splice(currentDeltaIndex, 1);
    } else {
        deltaItem.operation = REPORT_MODELING_DELTA_OPERATIONS.DELETE;
    }
}

export function removeItemFromRoleTree(tree, itemPath, delta, orgLevels, currentPathIndex = 0, deltaKeys = {}) {
    const currentId = itemPath[currentPathIndex];
    const itemIndex = tree?.findIndex(child => child.rowId === currentId) ?? -1;

    if (itemIndex === -1) {
        return;
    }

    const item = tree[itemIndex];
    deltaKeys[item.type] = item.id;

    if (currentPathIndex === itemPath.length - 1) {
        tree.splice(itemIndex, 1);
        removeRoleTreeDelta(item, delta, deltaKeys);
        return;
    }

    removeItemFromRoleTree(
        item.children, itemPath, delta, orgLevels, currentPathIndex + 1, deltaKeys
    );

    if (!item.children?.length) {
        tree.splice(itemIndex, 1);
    } else {
        recalcParentRoleTreeItem(item, orgLevels);
    }
    recalcRoleTreeTrafficLightStatus(tree, orgLevels)
}