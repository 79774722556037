import React from "react";
import { Typography, Box, styled } from "@mui/material";
import PropTypes from 'prop-types';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import { CloseIcon } from "../icons";

import "./styles.less"


const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#EAEAEA',
        maxWidth: 350,
        fontSize: theme.typography.pxToRem(12),
        borderRadius: "8px",
        padding: "8px 16px",
        border: "1px solid #B7B7B7",
        color: "var(--font-4)"
    },
}));

const Tag = ({ tag, onDelete, onClick, size = "medium", selected }) => {
    const classes = ["tag-container", `tag-container-${size}`];

    if (selected) {
        classes.push("selected")
    }


    return (

        <Box className={classes.join(" ")}>

            <HtmlTooltip
                title={
                    <Typography color="inherit">{tag || ""}</Typography>
                }
                placement="top"
                style={{
                    cursor: "pointer",
                    display: tag ? "initial" : "none"
                }}
            >

                <Typography className="tag-text" onClick={onClick}>
                    {tag}
                </Typography>
            </HtmlTooltip>

            <CloseIcon
                className="tag-close-icon"
                onClick={onDelete}
            />
        </Box>

    )
}

Tag.propTypes = {
    tag: PropTypes.string,
    onDelete: PropTypes.func,
    onClick: PropTypes.func,
    size: PropTypes.string,
    selected: PropTypes.bool
}

export default Tag;
