import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { busynessProcessesService } from "../../services/busyness-processes-service";
import LockManager from "../../utils/lockManager";
import { BUSY_TYPES } from "../../utils/busy";

const lockManager = new LockManager("busynessProcess");

const initialState = {
    busynessProcesses: [],
    columns: [],
    total: 0,
    selectedIds: [],
    searchString: "",
    pageNumber: 1,
    limitNumber: 10,
    sortData: [],
    filterData: [],
    busy: false,
    busyType: null,
    error: null,
    submit: false,
    currentBusynessProcess: null,
    currentBusynessProcessEditable: false,
    currentBusynessProcessDeleted: true,
    validationErrors: null,
};

export const fetchBusynessProcesses = createAsyncThunk(
    "admin/fetchBusynessProcesses",
    async ({ params }) => {
        const response = await busynessProcessesService.getBusynessProcesses(params);
        return response;
    }
);

export const fetchBusynessProcessColumns = createAsyncThunk(
    "admin/fetchBusynessProcessColumns",
    async () => {
        const response = await busynessProcessesService.getBusynessProcessColumns();
        return response;
    }
);

export const fetchBusynessProcessDetailed = createAsyncThunk(
    "admin/fetchBusynessProcessDetailed",
    async (busynessProcessId) => {
        const busynessProcess = await busynessProcessesService.getBusynessProcessDetailed(busynessProcessId);
        const lock = await lockManager.lockObject(busynessProcessId);

        return { busynessProcess, lock };
    }
);

export const createBusynessProcess = createAsyncThunk(
    "admin/saveBusynessProcess",
    async (busynessProcess, { rejectWithValue }) => {
        let response

        try {
            response = await busynessProcessesService.createBusynessProcess(busynessProcess);
        } catch (messages){
            return rejectWithValue(messages)
        }

        return response;
    }
);

export const editBusynessProcess = createAsyncThunk(
    "admin/editBusynessProcess",
    async (busynessProcess, {rejectWithValue}) => {
        let response

        try {
            response = await busynessProcessesService.editBusynessProcess(busynessProcess);
        } catch (messages){
            return rejectWithValue(messages)
        }

        return response;
    }
);


export const deleteBusynessProcess = createAsyncThunk(
    "admin/deleteBusynessProcess",
    async (busynessProcessId, {rejectWithValue}) => {
        try {
            await busynessProcessesService.deleteBusynessProcess(busynessProcessId);
        } catch (messages){
            return rejectWithValue(messages)
        }

        return busynessProcessId;
    }
);

export const clearCurrentBusynessProcess = createAsyncThunk(
    "admin/clearCurrentBusynessProcess",
    async (_, thunkAPI) => {
        const state = thunkAPI.getState();
        const currentBusynessProcess = state.admin.busynessProcesses.currentBusynessProcess;
        const currentBusynessProcessEditable = state.admin.busynessProcesses.currentBusynessProcessEditable;
        const currentBusynessProcessDeleted = state.admin.busynessProcesses.currentBusynessProcessDeleted;

        if (!currentBusynessProcess) return thunkAPI.rejectWithValue(["Current busynessProcess is not initialized"]);

        if (currentBusynessProcessDeleted || !currentBusynessProcessEditable || !currentBusynessProcess.id) return;

        const lockResponse = await lockManager.unlockObject(currentBusynessProcess.id);

        if (!lockResponse.result) {
            return thunkAPI.rejectWithValue(lockResponse.messages);
        }
    }
);


const busynessProcessesSlice = createSlice({
    name: "busynessProcesses",
    initialState: initialState,
    reducers: {
        setSubmit(state, action){
            state.submit = action.payload;
        },

        setBusy(state, action){
            state.busy = action.payload;
        },

        setInitialCurrentBusynessProcess(state) {
            state.currentBusynessProcess = {
                id: "",
                description: "",
            };

            state.error = null;
            state.validationErrors = null;
            state.submit = false;
            state.currentBusynessProcessEditable = true;
            state.currentBusynessProcessDeleted = false;
        },

        setError(state, action) {
            state.error = action.payload;
        },

        setValidationErrors(state, action) {
            state.validationErrors = action.payload;
        },

        setSelectedIds(state, action) {
            state.selectedIds = action.payload;
        },

        setSearchString(state, action) {
            if (state.searchString !== action.payload) {
                state.pageNumber = 1;
                state.searchString = action.payload;
            }
        },

        setPageNumber(state, action) {
            state.pageNumber = action.payload;
        },

        setLimitNumber(state, action) {
            state.pageNumber = 1;
            state.limitNumber = action.payload;
        },

        setSortData(state, action) {
            state.pageNumber = 1;
            state.sortData = action.payload;
        },

        setFilterData(state, action) {
            state.pageNumber = 1;
            state.filterData = action.payload;
        },

    },
    extraReducers: {
        //get all busynessProcesses
        [fetchBusynessProcesses.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        },

        [fetchBusynessProcesses.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.error;
        },

        [fetchBusynessProcesses.fulfilled]: (state, action) => {
            state.busy = false;
            state.busynessProcesses = action.payload.busynessProcesses;
            state.total = action.payload.total;
            state.error = null;
            state.validationErrors = null;
        },

        // fetch busynessProcess detailed
        [fetchBusynessProcessDetailed.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
            state.currentBusynessProcessDeleted = false;
            state.submit = false;
        },

        [fetchBusynessProcessDetailed.fulfilled]: (state, action) => {
            const { busynessProcess, lock } = action.payload;

            state.currentBusynessProcess = busynessProcess;
            state.busy = false;
            state.currentBusynessProcessEditable = lock.result;
            state.error = lock.result ? null : lock.messages;
        },

        [fetchBusynessProcessDetailed.rejected]: (state, action) => {
            state.currentBusynessProcess = null;
            state.error = action.error;
            state.busy = false;
            state.currentBusynessProcessEditable = false;
        },

        // clear current busynessProcess
        [clearCurrentBusynessProcess.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        },

        [clearCurrentBusynessProcess.fulfilled]: (state) => {
            state.busy = false;
            state.currentBusynessProcess = null;
            state.currentBusynessProcessEditable = false;
            state.currentBusynessProcessDeleted = false;
            state.error = null;
            state.validationErrors = null;
            state.submit = false;
        },

        [clearCurrentBusynessProcess.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.payload;
        },

        //create busynessProcesses
        [createBusynessProcess.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.SAVE;
            state.submit = false;
        },

        [createBusynessProcess.fulfilled]: (state, action) => {
            state.currentBusynessProcess = action.payload;
            state.busy = false;
            state.submit = true;
            state.error = null;   
            state.validationErrors = null;  
        },

        [createBusynessProcess.rejected]: (state, action) => {
            state.busy = false;
            const error = action.payload;

            if (!error) return;

            const validationCodes = new Set(["validation"])

            if (error.code && validationCodes.has(error.code)){
                state.validationErrors = error.errors;
            }

            state.error = error
        },

        //edit busynessProcess
        [editBusynessProcess.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.SAVE;
            state.submit = false;
        },

        [editBusynessProcess.fulfilled]: (state, action) => {
            state.busy = false;
            state.submit = true;
            state.error = null;
            state.validationErrors = null;
            state.currentBusynessProcess = action.payload;
        },

        [editBusynessProcess.rejected]: (state, action) => {
            state.busy = false;
            const error = action.payload;

            if (!error) return;

            const validationCodes = new Set(["validation"])

            if (error.code && validationCodes.has(error.code)){
                state.validationErrors = error.errors;
            }

            state.error = error
        },


        //delete busynessProcess

        [deleteBusynessProcess.fulfilled]: (state, action) => {
            state.busy = false;
            state.error = null;
            state.validationErrors = null;
            state.busynessProcesses = state.busynessProcesses.filter(busynessProcess => busynessProcess.id !== action.payload);

            if (state.currentBusynessProcess?.id === action.payload) {
                state.submit = true;
                state.currentBusynessProcessDeleted = true;
            }
        },

        [deleteBusynessProcess.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.payload;
        },

        [deleteBusynessProcess.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.SAVE;
        },

        [fetchBusynessProcessColumns.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        },

        [fetchBusynessProcessColumns.fulfilled]: (state, action) => {
            state.busy = false;
            state.columns = action.payload;
        },

        [fetchBusynessProcessColumns.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.error;
        },

    }
});


export const {
    setSubmit, setBusy, setInitialCurrentBusynessProcess, setError, setValidationErrors,
    setSelectedIds, setSearchString, setPageNumber, setLimitNumber,
    setSortData, setFilterData
} = busynessProcessesSlice.actions;

export default busynessProcessesSlice.reducer;
