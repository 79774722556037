import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, nanoid } from "@reduxjs/toolkit";

import { reportsModelingService } from "../../services/reports-modeling-service.js";
import { PERMISSIONS_TREE_VARIANT_LEVEL, REPORT_LEVELS, REPORT_TYPES, REPORT_VARIANT_TYPES } from "../../utils/sodReports.js";
import { BUSY_TYPES } from "../../utils/busy.js";
import { REPORT_MODELING_CONFLICT_LEVELS, getModelingRolesForCalc, getModelingGroupRolesForCalc, getRolePermissionsMap, getDeletedDefaultValuesForTcodes, recalcRoleTreeTrafficLightStatus } from "../../utils/reports-modeling-utils.js";
import { getSelectedRoleReducers } from "./selectedRoleReducers.js";
import { getDefaultPermissionsValuesForTransactions, getTransactionFromRoleMenu, getPfcgTreeForTcodes, getDeletedTcodesFromMenu, getTcodesMapCountFromMenu, getTransactionsByIds } from "../../utils/role-menu.js";
import { findItemInTree, TreeWalker } from "../../utils/tree-table.js";
import { removeGroupRoleChildren, addGroupRoleChildren } from "../../utils/group-role-utils.js";

const modelingRoles = createEntityAdapter();

const initialResults = {
    canFetchResults: false,
    resultsOpen: false,
    reportLevel: REPORT_LEVELS.OPERATIONS,
    reportType: REPORT_TYPES.FINAL,
    conflictLevel: REPORT_MODELING_CONFLICT_LEVELS.ROLE,
    rows: [],
    columns: [],
    total: 0,
    selectedIds: [],
    searchString: "",
    pageNumber: 1,
    limitNumber: 10,
    sortData: [],
    filterData: [],
};

const initialState = {
    roles: [],
    systems: [],
    extendedSystems: [],
    matrixHeaders: [],
    risks: [],
    riskLevels: [],
    groups: [],
    parametersOpen: true,

    ...initialResults,

    busy: false,
    busyType: null,
    error: null,
    validationErrors: null,

    variantCreated: false,
    variants: [],
    currentVariant: null,
    standardParameters: {
        roles: [],
        systems: [],
        matrixHeaders: [],
        risks: [],
        riskLevels: [],
        extendedSystems: [],
    },

    modeling: {
        open: false,
        roles: modelingRoles.getInitialState(),
        selectedRoleId: "",
        rolesForView: [],
        selectedViewRole: null,
    }
};

const createRoleForModeling = (role) => ({
    id: nanoid(),
    role: role.role,
    description: role.description,
    isVariantAvailable: role.isVariantAvailable,
    isComplex: role.isComplex,
    systemId: role.systemId,
    initialized: false,
    menu: reportsModelingService.parseRoleMenu([]),
    tree: [],
    orgLevels: [],
    //children: role.children ?? [],
    children: [],
    delta: [],
    orgLevelsDelta: [],
    manual: role.manual || false,
    profile: role.profile || role.role,
    changed: role.changed || false,
})


export const removeMenuItemFromTree = createAsyncThunk(
    "reportsModeling/roleLevel/removeMenuItem",
    async ({ menuItemPath, id }, { getState, dispatch }) => {

        const state = getState();

        const selectedRole = selectModelingRoleById(state, id);

        if (!selectedRole) {
            return
        }

        const removedMenuItem = findItemInTree(menuItemPath, selectedRole.menu)

        const tcodesMapItem = getTcodesMapCountFromMenu([removedMenuItem])

        dispatch(removeRoleMenuNode({
            id: selectedRole.id,
            activeNodePath: menuItemPath
        }))

        const tcodesMapMenu = getTcodesMapCountFromMenu(selectedRole.menu)

        const { deletedTcodes, remainedTcodes } = getDeletedTcodesFromMenu(tcodesMapItem, tcodesMapMenu)

        if (deletedTcodes.size === 0) {
            return
        }

        try {
            const { defaultValuesForDelete, orgLevelsForDelete } = getDeletedDefaultValuesForTcodes(deletedTcodes, remainedTcodes, selectedRole.tcodeToValue)

            dispatch(substractPfcgTreeFromRole({
                id,
                defaultValuesForDelete,
                orgLevelsForDelete
            }))


        } catch (error) {
            console.log(error)
        }
    }
);

export const fetchModelingRoles = createAsyncThunk(
    "reportsModeling/roleLevel/fetchModelingRoles",
    async (_, { getState, rejectWithValue }) => {
        const state = getState();
        const currentVariantName = selectCurrentVariantName(state);

        if (state.reportsModeling.roleLevel.roles.length === 0) {
            return rejectWithValue()
        }

        return reportsModelingService.getModelingRoles(
            state.reportsModeling.roleLevel.roles,
            state.reportsModeling.roleLevel.extendedSystems.map(item => item.key),
            _,
            currentVariantName,
            PERMISSIONS_TREE_VARIANT_LEVEL.ROLES
        );
    }
);

export const fetchReportColumns = createAsyncThunk(
    "reportsModeling/roleLevel/fetchReportColumns",
    async (_, { getState }) => {
        const state = getState();
        const conflictLevel = state.reportsModeling.roleLevel.conflictLevel;

        const columns = conflictLevel === REPORT_MODELING_CONFLICT_LEVELS.USERNAME
            ? await reportsModelingService.getUserLevelModelingColumns(
                state.reportsModeling.roleLevel.reportLevel,
                state.reportsModeling.roleLevel.reportType
            )
            : await reportsModelingService.getRoleLevelModelingColumns(
                state.reportsModeling.roleLevel.reportLevel,
                state.reportsModeling.roleLevel.reportType
            );
        return columns;
    }
);


export const fetchReportResults = createAsyncThunk(
    "reportsModeling/roleLevel/fetchReportResults",
    async ({ params }, { getState }) => {
        const state = getState();

        if (!state.reportsModeling.roleLevel.canFetchResults) {
            return;
        }

        const conflictLevel = state.reportsModeling.roleLevel.conflictLevel;

        const newParams = {
            ...params,
            "report_level": state.reportsModeling.roleLevel.reportLevel,
            "report_type": state.reportsModeling.roleLevel.reportType,
            "conflict_level": conflictLevel,
        };

        const response = conflictLevel === REPORT_MODELING_CONFLICT_LEVELS.USERNAME
            ? await reportsModelingService.getUserLevelReportResults(newParams)
            : await reportsModelingService.getRoleLevelReportResults(newParams);

        return response;
    }
);

export const calcReport = createAsyncThunk(
    "reportsModeling/roleLevel//calcReport",
    async (_, { getState, rejectWithValue }) => {
        const state = getState();
        //const rolesForView = state.reportsModeling.roleLevel.modeling.rolesForView;
        const currentVariantName = selectCurrentVariantName(state);
        const groupRoles = state.reportsModeling.roleLevel.modeling.rolesForView.filter(role => role.isComplex)
        const calcRoles = [...Object.values(state.reportsModeling.roleLevel.modeling.roles.entities), ...groupRoles]

        // const changedRolesMap = calcRoles.filter(role => role.changed).reduce((accumulator, role) => {
        //     accumulator[role.id] = true

        //     return accumulator
        // }, {})

        const changedRoleSet = new Set();

        calcRoles.forEach(role => {
            if(role.changed){
                changedRoleSet.add(role.id )   
            }
        })


        const systemsForCalc = state.reportsModeling.roleLevel.extendedSystems.filter(item => !item.isGroup).map(item => item.key)

        const rolesToFetch = calcRoles.filter(role => role.changed && !role.initialized);

        const prefetchedRoleMap = await getRolePermissionsMap(
            rolesToFetch, currentVariantName, PERMISSIONS_TREE_VARIANT_LEVEL.ROLES, ""
        );

        const changedRolesForCalc = await getModelingRolesForCalc(calcRoles, prefetchedRoleMap, changedRoleSet);
        const changedGroupRolesForCalc = await getModelingGroupRolesForCalc(calcRoles);

        const form = {
            roles: state.reportsModeling.roleLevel.roles,
            systems: systemsForCalc,
            matrix_headers: state.reportsModeling.roleLevel.matrixHeaders.map(item => item.key),
            risks: state.reportsModeling.roleLevel.risks.map(item => item.key),
            risk_levels: state.reportsModeling.roleLevel.riskLevels.map(item => item.key),
            groups: [],
            changed_roles: changedRolesForCalc,
            changed_group_roles: changedGroupRolesForCalc
        };

        try {
            await reportsModelingService.calcRoleLevelModelingReport(form);
        } catch (error) {
            return rejectWithValue(error)
        }
    }
);


export const calcCompareReport = createAsyncThunk(
    "reportsModeling/roleLevel//calcCompareReport",
    async (_, { getState, rejectWithValue }) => {
        const state = getState();
        const currentVariantName = selectCurrentVariantName(state);
        //const calcRoles = Object.values(state.reportsModeling.roleLevel.modeling.roles.entities)
        const groupRoles = state.reportsModeling.roleLevel.modeling.rolesForView.filter(role => role.isComplex)
        const calcRoles = [...Object.values(state.reportsModeling.roleLevel.modeling.roles.entities), ...groupRoles]
        // const changedRolesMap = calcRoles.filter(role => role.changed).reduce((accumulator, role) => {
        //     accumulator[role.id] = true

        //     return accumulator
        // }, {})

        const changedRoleSet = new Set();

        calcRoles.forEach(role => {
            if (role.changed) {
                changedRoleSet.add(role.id)
            }
        })

        const systemsForCalc = state.reportsModeling.roleLevel.extendedSystems.filter(item => !item.isGroup).map(item => item.key)

        const rolesToFetch = calcRoles.filter(role => role.changed && !role.initialized);

        const prefetchedRoleMap = await getRolePermissionsMap(
            rolesToFetch, currentVariantName, PERMISSIONS_TREE_VARIANT_LEVEL.ROLES, ""
        );

        const changedRolesForCalc = await getModelingRolesForCalc(calcRoles, prefetchedRoleMap, changedRoleSet);
        const changedGroupRolesForCalc = await getModelingGroupRolesForCalc(calcRoles);

        const form = {
            roles: state.reportsModeling.roleLevel.roles,
            systems: systemsForCalc,
            matrix_headers: state.reportsModeling.roleLevel.matrixHeaders.map(item => item.key),
            risks: state.reportsModeling.roleLevel.risks.map(item => item.key),
            risk_levels: state.reportsModeling.roleLevel.riskLevels.map(item => item.key),
            groups: [],
            changed_roles: changedRolesForCalc,
            changed_group_roles: changedGroupRolesForCalc
        };

        try {
            await reportsModelingService.calcRoleLevelModelingCompareReport(form);
        } catch (error) {
            return rejectWithValue(error)
        }
    }
);

export const saveVariant = createAsyncThunk(
    "reportsModeling/roleLevel/saveVariant",
    async (variantName, { getState, rejectWithValue }) => {
        const state = getState();

        const isVariantNew = !state.reportsModeling.roleLevel.variants.some(
            variant => variant.variantName === variantName
        );

        const currentVariantName = selectCurrentVariantName(state);

        const newParams = {
            "variant_name": variantName,
            "variant_type": REPORT_VARIANT_TYPES.ROLES_MODELING,
        };

        const systemsForCalc = state.reportsModeling.roleLevel.extendedSystems.filter(item => !item.isGroup).map(item => item.key)

        const variantData = {
            "roles": state.reportsModeling.roleLevel.roles,
            "systems": systemsForCalc,
            "matrix_headers": state.reportsModeling.roleLevel.matrixHeaders.map(item => item.key),
            "risks": state.reportsModeling.roleLevel.risks.map(item => item.key),
            "risk_levels": state.reportsModeling.roleLevel.riskLevels.map(item => item.key),
            "groups": []
        }

        const form = {
            variant_name: variantName,
            variant_data: variantData,
            variant_type: REPORT_VARIANT_TYPES.ROLES_MODELING
        }

        const variantModelingRoles = {
            ...state.reportsModeling.roleLevel.modeling.roles.entities,
        };

        const groupRoles = state.reportsModeling.roleLevel.modeling.rolesForView.filter(role => role.isComplex)
        const rolesToSave = Object.values(variantModelingRoles);

        const prefetchedRoleMap = await getPrefetchedRolesForVariant(rolesToSave, currentVariantName)

        const roles = [...rolesToSave, ...groupRoles];
        let rolesData = []
        if (groupRoles.length) {
            roles.forEach(role => {
                const modelingRole = { ...role }

                if (prefetchedRoleMap[role.id]) {
                    modelingRole.menu = prefetchedRoleMap[role.id].menu
                }

                const parsedRole = reportsModelingService.parseRoleToTreeVariant(modelingRole);
                rolesData.push({
                    ...parsedRole,
                    user: "",
                    is_changed: role.changed,
                    operation: "",
                    is_explicit: role.isComplex
                })
            });
        } else {
            const parsedRole = reportsModelingService.parseRoleToTreeVariant(rolesToSave[0]);
            rolesData.push({
                ...parsedRole, 
                user: "",
                is_changed: rolesToSave[0].changed,
                operation: "",
                is_explicit: true
            })
        }

        // const prefetchedRoleMap = await getPrefetchedRolesForVariant(rolesToSave, currentVariantName)



        const requestData = {
            variant_name: variantName,
            old_variant_name: currentVariantName,
            level: PERMISSIONS_TREE_VARIANT_LEVEL.ROLES,
            variants: rolesData
        }

        try {
            const [savedVariant] = await Promise.all([
                reportsModelingService.saveVariant(form, newParams, isVariantNew),
                reportsModelingService.savePermissionsTreeVariant(requestData)
            ]);
            return savedVariant;
        } catch (error) {
            return rejectWithValue(error)
        }
    }
);

export const deleteVariant = createAsyncThunk(
    "reportsModeling/roleLevel/deleteVariant",
    async (variantName, { rejectWithValue }) => {
        const newParams = {
            "variant_name": variantName,
            "variant_type": REPORT_VARIANT_TYPES.ROLES_MODELING,
        };

        try {
            return await reportsModelingService.deleteVariant(newParams);
        } catch (error) {
            return rejectWithValue(error)
        }
    }
);

export const setFavoritesForVariant = createAsyncThunk(
    "reportsModeling/roleLevel/setFavoritesForVariant",
    async ({ variantName, inFavorites }, { rejectWithValue }) => {
        const form = {
            variant_name: variantName,
            variant_type: REPORT_VARIANT_TYPES.ROLES_MODELING,
            in_favorites: inFavorites
        };

        try {
            return await reportsModelingService.setFavoritesForVariant(form);
        } catch (error) {
            return rejectWithValue(error)
        }
    }
);

export const fetchVariantsByUser = createAsyncThunk(
    "reportsModeling/roleLevel/fetchVariantsByUser",
    async (searchString) => {
        const params = {
            variant_type: REPORT_VARIANT_TYPES.ROLES_MODELING,
        };

        if (searchString) {
            params.search = searchString;
        }

        const response = await reportsModelingService.getVariantsByUser(params);

        return response;
    }
);

export const exportReport = createAsyncThunk(
    "reportsModeling/roleLevel/exportReport",
    async (_, { getState, rejectWithValue }) => {
        const state = getState();

        const params = {
            "report_level": state.reportsModeling.roleLevel.reportLevel,
            "report_type": state.reportsModeling.roleLevel.reportType,
        };

        try {
            await reportsModelingService.exportRoleLevelReport(params);
        } catch (error) {
            rejectWithValue(error);
        }
    }
);

export const setReportLevelWithColumns = (reportLevel) => (dispatch) => {
    dispatch(setReportLevel(reportLevel));
    dispatch(fetchReportColumns());
};

export const setReportTypeWithColumns = (reportType) => (dispatch) => {
    dispatch(setReportType(reportType));
    dispatch(fetchReportColumns());
};

export const setConflictLevelWithColumns = (conflictLevel) => (dispatch) => {
    dispatch(setConflictLevel(conflictLevel));
    dispatch(fetchReportColumns());
};

export const saveReportColumns = createAsyncThunk(
    "reportsModeling/roleLevel/saveReportColumns",
    async ({ columns, reportLevel, reportType }) => {
        await reportsModelingService.saveRoleLevelModelingColumns(columns, reportLevel, reportType);
        return { columns, reportLevel, reportType };
    }
);

export const setSelectedViewRole = createAsyncThunk(
    "reportsModeling/userLevel/setSelectedModelingRole",
    async (role, { dispatch }) => {
        dispatch(setModelingSelectedRoleById(role?.id))
        return role;
    }
)

export const setModelingSelectedRole = createAsyncThunk(
    "reportsModeling/roleLevel/setModelingSelectedRole",
    async (role, { rejectWithValue, getState }) => {
        if (!role) return
        if (role.initialized) {
            return role;
        }

        const state = getState();
        const currentVariantName = selectCurrentVariantName(state);
        const selectedRole = state.reportsModeling.roleLevel.modeling.rolesForView.find(r => r.role === role.role)

        try {

            const { menu, tree, orgLevels, childrens = [], tcodeToValue = {} } = await reportsModelingService.getRolePermissionsVariant(
                role.role, role.systemId, currentVariantName, PERMISSIONS_TREE_VARIANT_LEVEL.ROLES, null
            )
            if (tree && orgLevels) {
                recalcRoleTreeTrafficLightStatus(tree, orgLevels)
            }

            return {
                ...role,
                delta: [],
                orgLevelsDelta: [],
                tree: tree ?? [],
                orgLevels: orgLevels ?? {},
                menu: menu,
                children: selectedRole?.children ?? childrens,
                initialized: true,
                tcodeToValue: tcodeToValue
            };
        } catch (error) {
            rejectWithValue(error);
        }
    }
);

export const setModelingSelectedRoleById = (id) => (dispatch, getState) => {
    const state = getState();
    const role = selectModelingRoleById(state, id);
    dispatch(setModelingSelectedRole(role));
};

export const setModelingSelectedRoleByName = (name = "") => (dispatch, getState) => {
    const state = getState();

    const lowerName = name.toLowerCase();

    const role = selectModelingRoles(state).find(role => (
        role.role.toLowerCase() === lowerName
    ));

    dispatch(setModelingSelectedRole(role));
};

export const addDefaultPermissionsForMenuUpdate = createAsyncThunk(
    "reportsModeling/roleLevel/addDefaultPermissionsForMenuUpdate",
    async ({ id, transactionIds }, { getState, dispatch }) => {
        const state = getState();
        const role = selectModelingRoleById(state, id);

        if (!role || !transactionIds?.length) {
            return;
        }

        const newPermissionsTree = await getPfcgTreeForTcodes(role.systemId, transactionIds)

        const defaultValues = await getDefaultPermissionsValuesForTransactions(role.systemId, transactionIds);

        dispatch(addModelingRoleTreeItems({
            id,
            items: newPermissionsTree,
            defaultValues,
            tcodes: transactionIds,
            isStandart: true
        }));
    }
);

export const addRoleMenuNodeWithDefaultValues = ({ id, activeNodePath, tcodes }) => async (dispatch, getState) => {

    let state = getState();
    let selectedRole = selectModelingRoleById(state, id);

    if (!selectedRole) {
        return
    }

    const transactions = await getTransactionsByIds(selectedRole.systemId, tcodes);

    dispatch(addRoleMenuNode({
        id,
        activeNodePath,
        tcodes,
        transactions
    }));

    state = getState();
    selectedRole = selectModelingRoleById(state, id);
    const tcodesMapMenu = getTcodesMapCountFromMenu(selectedRole.menu)

    const tcodesForDefaultPermissions = tcodes.filter(tcode => tcodesMapMenu[tcode] && tcodesMapMenu[tcode] > 0)

    if (tcodesForDefaultPermissions.length > 0) {
        dispatch(addDefaultPermissionsForMenuUpdate({
            id,
            transactionIds: tcodesForDefaultPermissions
        }));
    }
};


export const addGroupRoleChildToModeling = createAsyncThunk(
    "reportsModeling/userLevel/addGroupRoleChildToModeling",
    async ({ id, newChildRole }, { getState, dispatch }) => {
        const state = getState();
        const roles = state.reportsModeling.roleLevel.modeling.rolesForView;

        const newChild = createRoleForModeling(newChildRole);
        dispatch(addModelingRole(newChild));

        const updatedRoles = roles.map(role => {
            if (role.id === id) {
                return {
                    ...role,
                    changed: true,
                    open: false,
                    children: addGroupRoleChildren(newChildRole, newChild.id, role.children)
                };
            }
            return role;
        });


        dispatch(setModelingRolesForView(updatedRoles))

    }
)

export const removeGroupRoleChildFromModeling = createAsyncThunk(
    "reportsModeling/userLevel/removeGroupRoleChildFromModeling",
    async ({ roleIdToRemove }, { getState, dispatch }) => {
        const state = getState();
        const roles = state.reportsModeling.roleLevel.modeling.rolesForView;

        const updatedRoles = roles.map(role => {
            if (role.isComplex) {
                return {
                    ...role,
                    changed: true,
                    children: removeGroupRoleChildren(roleIdToRemove, role.children)
                };
            }
            return role;
        }).filter(role => role.id !== roleIdToRemove);

        dispatch(setModelingRolesForView(updatedRoles));

        dispatch(removeRoleFromModeling(roleIdToRemove));
    }
);


export const copyRoleMenuWithDefaultValues = ({ id, menuToCopy }) => (dispatch) => {
    if (!menuToCopy?.length) {
        return;
    }

    dispatch(copyRoleMenu({
        id,
        menuToCopy
    }));

    const transactionIds = getTransactionFromRoleMenu(menuToCopy[0].children);

    dispatch(addDefaultPermissionsForMenuUpdate({
        id,
        transactionIds
    }));
};

export const setVariantWithFetchModeling = createAsyncThunk(
    "reportsModeling/roleLevel/setVariantWithFetchModeling",
    async (variant, { dispatch, getState }) => {
        dispatch(setCurrentVariant(variant));

        if (!variant) {
            dispatch(clearVariant());
        }

        const selectedRole = selectModelingSelectedRole(getState());

        const { payload: modelingRoles } = await dispatch(fetchModelingRoles());

        const newSelectedRole = selectedRole && modelingRoles.find(role => (
            role.role === selectedRole.role &&
            role.systemId === selectedRole.systemId
        ));

        dispatch(setModelingSelectedRole(newSelectedRole));
    }
);

const selectedRoleReducers = getSelectedRoleReducers(
    (state) => state.modeling.roles,
    modelingRoles,
    (state, role) => {
        const rolesForView = state.modeling.rolesForView;
        const changedRole = rolesForView.find(item => item.id === role.id)
        if (changedRole) {
            changedRole.changed = true;
        }
        rolesForView.forEach(r => {
            if (r.isComplex) {
                r.children.forEach(child => {
                    if (child.id == role.id) {
                        child.changed = true;
                    }
                })
            }
        });
        role.changed = true
    },
    false
);

const roleLevelSlice = createSlice({
    name: "reportsModeling/roleLevel",
    initialState: initialState,
    reducers: {
        setRoles(state, action) {
            state.roles = action.payload;
        },

        setSystems(state, action) {
            state.systems = action.payload;
        },

        setSystemsExtended(state, action) {
            state.extendedSystems = action.payload
        },

        setMatrixHeaders(state, action) {
            state.matrixHeaders = action.payload;
        },

        setRisks(state, action) {
            state.risks = action.payload;
        },

        setRiskLevels(state, action) {
            state.riskLevels = action.payload;
        },

        setParametersOpen(state, action) {
            state.parametersOpen = action.payload
        },

        setResultsOpen(state, action) {
            state.resultsOpen = action.payload;
        },

        setReportLevel(state, action) {
            state.pageNumber = 1;
            state.searchString = "";
            state.filterData = [];
            state.sortData = [];
            state.reportLevel = action.payload;
        },

        setReportType(state, action) {
            state.pageNumber = 1;
            state.searchString = "";
            state.filterData = [];
            state.sortData = [];
            state.reportType = action.payload;
        },

        setConflictLevel(state, action) {
            state.pageNumber = 1;
            state.searchString = "";
            state.filterData = [];
            state.sortData = [];
            state.conflictLevel = action.payload;
        },

        setError(state, action) {
            state.error = action.payload;
        },

        setSelectedIds(state, action) {
            state.selectedIds = action.payload;
        },

        setSearchString(state, action) {
            if (state.searchString !== action.payload) {
                state.pageNumber = 1;
                state.searchString = action.payload;
            }
        },

        setPageNumber(state, action) {
            state.pageNumber = action.payload;
        },

        setLimitNumber(state, action) {
            state.pageNumber = 1;
            state.limitNumber = action.payload;
        },

        setSortData(state, action) {
            state.pageNumber = 1;
            state.sortData = action.payload;
        },

        setFilterData(state, action) {
            state.pageNumber = 1;
            state.filterData = action.payload;
        },

        setCurrentVariant(state, action) {
            // if (!state.currentVariant) {
            //     state.standardParameters.roles = state.roles;
            //     state.standardParameters.systems = state.systems;
            //     state.standardParameters.matrixHeaders = state.matrixHeaders;
            //     state.standardParameters.risks = state.risks;
            //     state.standardParameters.riskLevels = state.riskLevels;
            //     state.standardParameters.extendedSystems = state.extendedSystems
            // }

            state.standardParameters.roles = state.roles;
            state.standardParameters.systems = state.systems;
            state.standardParameters.matrixHeaders = state.matrixHeaders;
            state.standardParameters.risks = state.risks;
            state.standardParameters.riskLevels = state.riskLevels;
            state.standardParameters.extendedSystems = state.extendedSystems

            const variant = action.payload;

            const systemsCurrent = variant ? variant.variantData.systems : state.standardParameters.extendedSystems.filter(item => !item.isGroup)
            const groupsCurrent = variant ? variant.variantData.groups : state.standardParameters.extendedSystems.filter(item => item.isGroup)

            const systemPart = systemsCurrent.map(system => ({
                key: system.key,
                text: system.text,
                isGroup: false
            }))

            const groupPart = groupsCurrent.map(group => ({
                key: group.key,
                text: group.text,
                isGroup: false
            }))

            state.currentVariant = variant;

            state.roles = variant
                ? variant.variantData.roles
                : state.standardParameters.roles;

            state.systems = variant
                ? variant.variantData.systems
                : state.standardParameters.systems;

            state.extendedSystems = [...systemPart, ...groupPart]

            state.matrixHeaders = variant
                ? variant.variantData.matrixHeaders
                : state.standardParameters.matrixHeaders;

            state.risks = variant
                ? variant.variantData.risks
                : state.standardParameters.risks;

            state.riskLevels = variant
                ? variant.variantData.riskLevels
                : state.standardParameters.riskLevels;
        },

        setModelingOpen(state, action) {
            state.modeling.open = action.payload;
        },

        setModelingRolesForView(state, action) {
            state.modeling.rolesForView = action.payload;
        },

        removeRoleFromModeling: (state, action) => {
            modelingRoles.removeOne(state.modeling.roles, action.payload);
        },

        addModelingRole(state, action) {
            modelingRoles.addOne(state.modeling.roles, action.payload);
        },

        clearFilters(state) {
            state.selectedIds = [];
            state.searchString = "";
            state.pageNumber = 1;
            state.sortData = [];
            state.filterData = [];
        },

        clearVariant(state) {
            state.modeling.roles = modelingRoles.getInitialState()
            state.modeling.selectedRoleId = ""
        },

        initModeling(state) {
            state.parametersOpen = true;
            state.modeling = initialState.modeling;

            for (const resultsKey in initialResults) {
                state[resultsKey] = initialResults[resultsKey];
            }
        },

        expandGroupRole(state, action) {
            const rolesForView = state.modeling.rolesForView;
            const groupRole = rolesForView.find(role => role.id === action.payload.role.id);
            groupRole.open = !groupRole.open;
        },

        expandItemTree(state, action) {
            const roleId = state.modeling.selectedRoleId;
            const role = state.modeling.roles.entities[roleId];
            const item = findItemInTree(action.payload.path, role.tree)
            item.open = !item.open;
            const treeWalker = new TreeWalker(item.children);
            treeWalker.walk(
                node => {
                    node.open = item.open;
                },
                () => {
                    return {
                        keyField: "",
                        keyValue: "",
                        childField: "children"
                    }
                },
                () => {

                }
            )
        },

        ...selectedRoleReducers
    },
    extraReducers: {
        [fetchReportResults.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
            state.rows = []
        },

        [fetchReportResults.rejected]: (state, action) => {
            const error = action.payload;

            state.busy = false;
            state.error = error;

            if (error.code === "validation") {
                state.validationErrors = error.errors;
            }
        },

        [fetchReportResults.fulfilled]: (state, action) => {
            state.busy = false;
            state.error = null;
            state.validationErrors = null;

            if (action.payload) {
                state.resultsOpen = true;
                state.rows = action.payload.rows;
                state.total = action.payload.total;
            }
        },


        [calcReport.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
            state.error = null;
            state.validationErrors = null;
        },

        [calcReport.rejected]: (state, action) => {
            state.busy = false;
            const error = action.payload;

            state.busy = false;
            state.error = error;

            if (error && error.code === "validation") {
                state.validationErrors = error.errors;
            }
        },

        [calcReport.fulfilled]: (state) => {
            state.selectedIds = [];
            state.searchString = "";
            state.pageNumber = 1;
            state.sortData = [];
            state.filterData = [];
            state.canFetchResults = true;
        },



        [calcCompareReport.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
            state.error = null;
            state.validationErrors = null;
        },

        [calcCompareReport.rejected]: (state, action) => {
            state.busy = false;
            const error = action.payload;

            state.busy = false;
            state.error = error;

            if (error && error.code === "validation") {
                state.validationErrors = error.errors;
            }
        },

        [calcCompareReport.fulfilled]: (state) => {
            state.selectedIds = [];
            state.searchString = "";
            state.pageNumber = 1;
            state.sortData = [];
            state.filterData = [];
            state.canFetchResults = true;
        },

        [fetchReportColumns.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        },

        [fetchReportColumns.fulfilled]: (state, action) => {
            state.busy = false;
            state.columns = action.payload?.filter(column => column.active);
        },

        [fetchReportColumns.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.error;
        },

        [saveVariant.pending]: (state) => {
            state.variantCreated = false;
            state.busy = true;
            state.busyType = BUSY_TYPES.SAVE;
        },

        [saveVariant.fulfilled]: (state, action) => {
            state.busy = false;
            state.validationErrors = [];
            state.variantCreated = true;

            const savedVariant = action.payload;
            const index = state.variants.findIndex(variant => variant.variantName === savedVariant.variantName);

            if (index === -1) {
                state.variants.push(savedVariant);
            } else {
                state.variants[index] = savedVariant;
            }

            if (state.currentVariant?.variantName === savedVariant.variantName) {
                state.currentVariant = savedVariant;
            }
        },

        [saveVariant.rejected]: (state, action) => {
            const error = action.payload;

            state.busy = false;
            state.error = error;

            if (error && error.code === "validation") {
                state.validationErrors = error.errors;
            }
        },

        [deleteVariant.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.SAVE;
        },

        [deleteVariant.fulfilled]: (state, action) => {
            const variantName = action.meta.arg;

            state.busy = false;
            state.variants = state.variants.filter(variant => variant.variantName !== variantName);

            if (state.currentVariant?.variantName === variantName) {
                state.currentVariant = null;
                state.roles = [];
                state.systems = [];
                state.extendedSystems = []
                state.matrixHeaders = [];
                state.risks = [];
                state.riskLevels = [];
            }
        },

        [deleteVariant.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.payload;
        },

        [setFavoritesForVariant.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.SAVE;
        },

        [setFavoritesForVariant.fulfilled]: (state, action) => {
            const { variantName, inFavorites } = action.meta.arg;

            state.busy = false;

            const variant = state.variants.find(variant => variant.variantName === variantName);

            if (variant) {
                variant.inFavorites = inFavorites;
            }

            if (state.currentVariant?.variantName === variantName) {
                state.currentVariant.inFavorites = inFavorites;
            }
        },

        [setFavoritesForVariant.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.payload;
        },

        [fetchVariantsByUser.fulfilled]: (state, action) => {
            state.variants = action.payload;
        },

        [fetchVariantsByUser.rejected]: (state, action) => {
            state.error = action.error;
        },

        [exportReport.rejected]: (state, action) => {
            state.error = action.error;
        },

        [saveReportColumns.fulfilled]: (state, action) => {
            const { columns, reportLevel, reportType } = action.payload;

            if (state.reportLevel === reportLevel &&
                state.reportType === reportType) {
                state.columns = columns.filter(column => column.active);
            }
        },

        [fetchModelingRoles.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        },

        [fetchModelingRoles.fulfilled]: (state, action) => {

            //const parsedModelingRoles = action.payload.map(role => createRoleForModeling(role))
            const roles = action.payload;
            const parsedModelingRoles = {}
            //const rolesForView = roles;

            if (roles.length == 0) {
                return
            }


            roles.forEach(role => {
                if (role.isComplex) {
                    role.open = false;
                    role.id = nanoid()
                    role.children.forEach(childRole => {
                        const childRoleExtended = createRoleForModeling(childRole)
                        childRole.parentRole = role.role;
                        childRole.isChild = true;
                        childRole.id = childRoleExtended.id;
                        parsedModelingRoles[childRoleExtended.id] = childRoleExtended;
                    })
                    return
                }
                const roleExtended = createRoleForModeling(role)
                parsedModelingRoles[roleExtended.id] = roleExtended
                role.id = roleExtended.id;
            })


            modelingRoles.setAll(state.modeling.roles, parsedModelingRoles);
            state.modeling.rolesForView = roles;

            state.busy = false;
            state.parametersOpen = false;
            state.modeling.open = true;
            state.modeling.selectedRoleId = "";

            if (parsedModelingRoles.length > 0) {
                //state.modeling.selectedRoleId = parsedModelingRoles[0].id
            }

            for (const resultsKey in initialResults) {
                state[resultsKey] = initialResults[resultsKey];
            }
        },

        [fetchModelingRoles.rejected]: (state, action) => {
            state.error = action.error;
            state.busy = false;
        },

        [removeGroupRoleChildFromModeling.rejected]: (state, action) => {
            state.error = action.error;
        },

        [setSelectedViewRole.fulfilled]: (state, action) => {
            state.modeling.selectedViewRole = action.payload;
        },

        [setModelingSelectedRole.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        },

        [setModelingSelectedRole.fulfilled]: (state, action) => {
            const role = action.payload;

            if (!role) {
                state.modeling.selectedRoleId = "";
            } else if (role.id in state.modeling.roles.entities) {
                state.modeling.selectedRoleId = role.id;
                modelingRoles.setOne(state.modeling.roles, role);
            }

            state.busy = false;
        },

        [setModelingSelectedRole.rejected]: (state, action) => {
            state.error = action.error;
            state.busy = false;
        },
    }
});

export const {
    setRoles, setSystems, setSystemsExtended, setMatrixHeaders, setRisks,
    setRiskLevels, setParametersOpen, setResultsOpen,
    setReportLevel, setReportType, setError, setSelectedIds,
    setSearchString, setPageNumber, setLimitNumber, setSortData,
    setFilterData, setCurrentVariant, clearFilters,
    setModelingOpen, addModelingRoleTreeItems, setModelingRoleOrgLevelValues,
    setModelingRoleTreeField, openRoleMenuNode, addRoleMenuFolder,
    addRoleMenuNode, removeRoleMenuNode, copyRoleMenu,
    addGroupRoleChild, removeGroupRoleChild, initModeling, removeModelingRoleTreeItem, setConflictLevel, substractPfcgTreeFromRole, clearVariant,
    expandItemTree, setModelingRolesForView, addModelingRole, expandGroupRole, removeRoleFromModeling
} = roleLevelSlice.actions;

export const {
    selectIds: selectModelingRoleIds,
    selectAll: selectModelingRoles,
    selectById: selectModelingRoleById
} = modelingRoles.getSelectors(state => state.reportsModeling.roleLevel.modeling.roles);

export const selectModelingRolesForViewBySearch = (state, searchString = "") => {
    const roles = state.reportsModeling.roleLevel.modeling.rolesForView;
    const search = searchString.toLowerCase();
    return roles.filter(({ role }) => role.toLowerCase().includes(search))
};

export const selectModelingRolesBySearch = createSelector(
    selectModelingRoles,
    (_, searchString = "") => searchString.toLowerCase(),
    (roles, searchString) => roles.filter(({ role }) => role.toLowerCase().includes(searchString))
);
export const selectSelectedViewRole = state => state.reportsModeling.roleLevel.modeling.selectedViewRole;
export const selectModelingSelectedRoleId = state => state.reportsModeling.roleLevel.modeling.selectedRoleId;
export const selectModelingSelectedRole = state => {
    const selectedRoleId = selectModelingSelectedRoleId(state);
    if (!selectedRoleId) return null
    return selectModelingRoleById(state, selectedRoleId)
};

export const selectModelingRolesContainsGroupRoles = createSelector(
    selectModelingRoles,
    (roles) => {
        for (let i = 0; i < roles.length; i++) {
            if (!roles[i].isComplex) return false;
        }
        return true;
    }
)

export const selectModelingOpen = state => state.reportsModeling.roleLevel.modeling.open;

export const selectCurrentVariantName = state => state.reportsModeling.roleLevel.currentVariant?.variantName;

export default roleLevelSlice.reducer;


async function getPrefetchedRolesForVariant(roles, currentVariantName) {
    const rolesToFetch = roles.filter(role => !role.isComplex && !role.initialized);

    const prefetchedRoleMap = await getRolePermissionsMap(
        rolesToFetch, currentVariantName, PERMISSIONS_TREE_VARIANT_LEVEL.ROLES, ""
    );

    return prefetchedRoleMap
}