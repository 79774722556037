import React from "react";
import { Box } from "@mui/material";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import { Button } from "../buttons";
import { EditIcon, TrashIcon } from "../icons";

import { EDIT_MODE, READ_MODE } from "./entity-form-modes";

import "./style.less";

const SaveButton = ({ onClick }) => (
    <Button
        variant="contained"
        onClick={onClick}
        sx={{
            backgroundColor: "var(--font-2)",
            color: "var(--font-1)",

            "&:hover": {
                backgroundColor: "var(--font-2)",
            },
        }}
    >
        <FormattedMessage id="common.btn-save" />
    </Button>
);

const DeleteButton = ({ onClick }) => (
    <Button
        variant="contained"
        onClick={onClick}
        startIcon={
            <TrashIcon color="var(--font-1)" width="18px" height="18px" />
        }
        sx={{
            backgroundColor: "var(--font-2)",
            color: "var(--font-1)",

            "&:hover": {
                backgroundColor: "var(--font-2)",
            },
        }}
    >
        <FormattedMessage id="common.btn-delete" />
    </Button>
);

const EditButton = ({ onClick }) => (
    <Button
        variant='outlined'
        sx={{
            borderColor: "var(--font-4)",
            color: "var(--font-4)",
        }}
        startIcon={<EditIcon color="var(--font-4)" />}
        onClick={onClick}
    >
        <FormattedMessage id="common.btn-edit" />
    </Button>
);


const LogsButton = ({ onClick }) => (
    <Button
        variant='outlined'
        sx={{
            borderColor: "var(--font-4)",
            color: "var(--font-4)",
        }}
        onClick={onClick}
    >
        <FormattedMessage id="common.btn-logs"/>
    </Button>
);


const EntityFormActions = ({ mode, onDeleteClick, onSaveClick, onEditClick, onShowLogsClick, customActions }) => {
    const showDeleteButton = mode === EDIT_MODE;
    const showSaveButton = mode !== READ_MODE;
    const showEditButton = mode === READ_MODE;
    const showLogsButton = (mode === READ_MODE || mode === EDIT_MODE ) && onShowLogsClick

    return(
        <Box className="entity-form-actions">
            {showEditButton && <EditButton onClick={onEditClick} />}
            {showSaveButton && <SaveButton onClick={onSaveClick} />}
            {showDeleteButton && <DeleteButton onClick={onDeleteClick} />}
            {showLogsButton && <LogsButton onClick={onShowLogsClick}/>}
            {customActions}
        </Box>
    )
};

SaveButton.propTypes = {
    onClick: PropTypes.func,
};

DeleteButton.propTypes = {
    onClick: PropTypes.func,
};

EditButton.propTypes = {
    onClick: PropTypes.func,
};

LogsButton.propTypes = {
    onClick: PropTypes.func,
};

EntityFormActions.propTypes = {
    mode: PropTypes.string,
    onDeleteClick: PropTypes.func,
    onSaveClick: PropTypes.func,
    onEditClick: PropTypes.func,
    onShowLogsClick: PropTypes.func,
    customActions: PropTypes.node,
};

export default EntityFormActions;

export {
    EditButton, SaveButton, DeleteButton
};