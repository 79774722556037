import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    calcSummaryReport,
    setError as setErrorAction,
    setCalcStatus,
    clearFilters,
    setCalcDate,
    setCurrentView,
    calcDetailedReport,
    scheduleSummaryReport,
    fetchReportInfo,
    getTodayDateString,
    setIsComapring,
    calcSummaryReportDelta,
    resetCompareDates
} from "../../../reducers/reports/userBusynessLevelSlice";


import { ProgressDialog, ErrorsDialog, CompareReportsDialog } from "../../common/dialogs";
import useReduxValue from "../../common/hooks/useReduxValue";

import UserLevelBusynessReportResults from "./user-level-busyness-report-results.jsx";
import UserLevelBusynessReportDetailedResults from "./user-level-busyness-report-detailed-results.jsx";

import SodSocketService from "../../../services/sod-socket-service.js";
import PageContentBox from "../../common/page-content-box/page-content-box.jsx";

import { CalcReportBackgroundButton, CalcReportManualButton, CompareReportsButton } from "../report-actions";
import "./style.less"
import { useNavigate, useParams } from "react-router-dom";
import UserLevelBusynessReportToolbar from "./user-level-busyness-report-toolbar.jsx";
import { SUMMARY_REPORT_VIEWS } from "../../../utils/sodReports.js";

import BusynessReportScheduleDialog from "../dialogs/busyness-report-schedule-dialog.jsx";
import CompareSummaryReportsDialog from "../dialogs/compare-summary-reports-dialog.jsx";
import UserLevelBusynessReportTitle from "./user-level-busyness-report-title.jsx";

const DIALOG_TYPE = {
    CALC_SCHEDULING: "scheduling",
    CALC_DATE_DELTA: "dateDelta"
}

function formatDate(date) {

    let month = '' + (date.getMonth() + 1)
    let day = '' + date.getDate()
    let year = date.getFullYear()

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('');
}

const UserLevelBusynessReportPage = () => {

    const dispatch = useDispatch();

    const [openCompareDialog, setOpenCompareDialog] = useState(false);

    const [dialogType, setDialogType] = useState(null)

    const { rowsForCalc } = useParams()

    const socketService = SodSocketService.getInstance()

    const handleSocketMessage = useCallback((calcInfo) => {
        dispatch(setCalcStatus(calcInfo.status))

        if (calcInfo.status === "success"){
            dispatch(fetchReportInfo())
        }
    }, [dispatch])

    useEffect(() => {
        socketService.subscribe("update_summary_report_status", handleSocketMessage)

        return () => {
            socketService.unsubscribe("update_summary_report_status", handleSocketMessage)
        }
    }, [socketService, handleSocketMessage])

    const navigate = useNavigate()

    const onBackClick = useCallback(() => {
      navigate(`/sod-reports/summary-report-users`)

        dispatch(setCurrentView(SUMMARY_REPORT_VIEWS.LIST))
    }, [navigate, dispatch])

    useEffect(() => {
        if (!rowsForCalc) {
            onBackClick()
            return
        }

        (async () => {
            try {
                const parsedRows = JSON.parse(rowsForCalc)

                await dispatch(calcDetailedReport(parsedRows)).unwrap();
            } catch (error) {
                onBackClick()
            }
        })()

    }, [rowsForCalc, dispatch, onBackClick])


    const [error, setError] = useReduxValue(state => state.reports.userBusynessLevel.error, setErrorAction);


    const busy = useSelector(state => state.reports.userBusynessLevel.busy);
    const busyCalc = useSelector(state => state.reports.userBusynessLevel.busyCalc);
    const busyType = useSelector(state => state.reports.userBusynessLevel.busyType);
    const lastCalcDate = useSelector(state => state.reports.userBusynessLevel.lastCalcDate);

    const calcStatus = useSelector(state => state.reports.userBusynessLevel.calcStatus);
    const currentView = useSelector(state => state.reports.userBusynessLevel.currentView);
    const selectedIds = useSelector(state => state.reports.userBusynessLevel.selectedIds)
    const isComparing = useSelector(state => state.reports.userBusynessLevel.isComparing)
    const compareDates = useSelector(state => state.reports.userBusynessLevel.compareDates)


    const handleSocketMessageForDelta = useCallback((calcInfo) => {
        if (!compareDates) {
            return
        }

        const earlyDate = new Date(compareDates.earlyDate)
        const lateDate = new Date(compareDates.lateDate)

        if (formatDate(earlyDate) === calcInfo.early_date && formatDate(lateDate) === calcInfo.late_date) {
            dispatch(setCalcStatus(calcInfo.status))
        }
    }, [compareDates, dispatch])


    useEffect(() => {
        socketService.subscribe("update_business_delta_compare_status", handleSocketMessageForDelta)

        return () => {
            socketService.unsubscribe("update_business_delta_compare_status", handleSocketMessageForDelta)
        }
    }, [socketService, handleSocketMessageForDelta])

    const [calcReportDeltaValErrors, setCalcReportDeltaValErrors] = useState(null)


    const handleCalcManualReportClick = () => {
        dispatch(calcSummaryReport())

        onBackClick()
    };

    useEffect(() => {
        dispatch(fetchReportInfo())
    }, [dispatch])

    useEffect(() => {
        if (calcStatus && calcStatus === "success") {
            dispatch(clearFilters())
        }
    }, [calcStatus, dispatch])

    const isBlocked = calcStatus && calcStatus === "pending"


    const calcDateStr = useSelector(state => state.reports.userBusynessLevel.calcDate);

    const onChangeCalcDate = useCallback((value) => {
        onBackClick()

        const dateStr = value.toDateString()

        dispatch(setCalcDate(dateStr))

    }, [dispatch, onBackClick])

    const onScheduleCalc = useCallback((period, timeItems) => {
        const sheduleItems = timeItems.map(value => ({
            period: period,
            hours: value.getHours(),
            minutes: value.getMinutes()
        }))

        dispatch(scheduleSummaryReport(sheduleItems))

        setDialogType(null)
    }, [dispatch])

    const onChangeDialogType = useCallback((value) => {
        setDialogType(value)
    }, [setDialogType])

    const onCalcDetailedReport = useCallback(() => {
        const pageKey = JSON.stringify(selectedIds.map(id => {

            const [employee, risk, system] = id.split(":")

            return {
                riskId: risk,
                employee: employee,
                systemId: system
            }
        }))

        if (selectedIds.length === 0) {
           navigate(`/sod-reports/summary-report-users`)
            return
        }

        navigate(`/sod-reports/summary-report-users/${encodeURIComponent(pageKey)}`)

    }, [selectedIds, navigate])

    const calcDetailedReportBtnVisible = getTodayDateString() === calcDateStr && currentView === SUMMARY_REPORT_VIEWS.LIST

    const onCancelComparing = useCallback(() => {
        dispatch(setIsComapring(false))

        dispatch(clearFilters())
    }, [dispatch])


    const onCalcReportDelta = useCallback(async (val) => {
        try {
            await dispatch(calcSummaryReportDelta({
                earlyDate: val.earlyDate.toDateString(),
                lateDate: val.lateDate.toDateString()
            })).unwrap()
        } catch (error) {
            const validationError = {}
            error && error.errors && error.errors.forEach(item => {
                validationError[item.field] = item
            })

            setCalcReportDeltaValErrors(validationError)
            return
        }

        setCalcReportDeltaValErrors(null)

        onChangeDialogType(null)

        dispatch(clearFilters())
    }, [dispatch, onChangeDialogType])

    const onResetCompareDates = useCallback(() => {
        dispatch(resetCompareDates())
    }, [dispatch])

    const isOnDetailedPage = currentView === SUMMARY_REPORT_VIEWS.DETAILED

    return (

        <PageContentBox className="report-results-box">

            <PageContentBox className="summary-report-header-box">

                <UserLevelBusynessReportTitle calcStatus={calcStatus} lastCalcDate={lastCalcDate} />

                {!isOnDetailedPage &&!isComparing && <CompareReportsButton onClick={() => setOpenCompareDialog(true)} disabled={isBlocked} />}
                {!isOnDetailedPage && isComparing && <CompareReportsButton onClick={onCancelComparing} disabled={isBlocked} />}
                {!isOnDetailedPage && <CalcReportBackgroundButton onClick={() => { onChangeDialogType(DIALOG_TYPE.CALC_SCHEDULING) }} disabled={isBlocked} />}
                {!isOnDetailedPage && <CalcReportManualButton onClick={handleCalcManualReportClick} disabled={isBlocked} />}
            </PageContentBox>

            {!isOnDetailedPage && <UserLevelBusynessReportToolbar
                disabled={isBlocked}
                calcDate={new Date(calcDateStr)}
                onChangeCalcDate={onChangeCalcDate}
                onCalcDetailedReport={onCalcDetailedReport}
                calcDetailedReportBtnVisible={calcDetailedReportBtnVisible && !compareDates}
                setOnOpenCompareReport={() => setDialogType(DIALOG_TYPE.CALC_DATE_DELTA)}
                compareDates={compareDates}
                resetCompareDates={onResetCompareDates}
                isCalcDateVisible={!isComparing && !compareDates}
            />}
            {
                currentView === SUMMARY_REPORT_VIEWS.LIST && <UserLevelBusynessReportResults disabled={isBlocked} />
            }


            {
                currentView === SUMMARY_REPORT_VIEWS.DETAILED && <UserLevelBusynessReportDetailedResults onBackClick={onBackClick} />
            }

            {
                dialogType === DIALOG_TYPE.CALC_SCHEDULING && <BusynessReportScheduleDialog
                    open={dialogType}
                    sx={{
                        height: "500px"
                    }}
                    setError={setError}
                    onApply={onScheduleCalc}
                    onClose={() => onChangeDialogType(null)} />
            }

            {
                dialogType === DIALOG_TYPE.CALC_DATE_DELTA && <CompareSummaryReportsDialog
                    open={dialogType}
                    sx={{
                        height: "500px"
                    }}
                    setError={setError}
                    onApply={onCalcReportDelta}
                    onClose={() => onChangeDialogType(null)}
                    validationErrors={calcReportDeltaValErrors}
                    setCalcReportDeltaValErrors={setCalcReportDeltaValErrors}
                />
            }

            <ProgressDialog
                open={busy || busyCalc}
                busyType={busyType}
            />

            <ErrorsDialog
                error={error}
                open={error?.type === "error"}
                onClose={() => setError(null)}
            />

            <CompareReportsDialog
                open={openCompareDialog}
                setOpen={setOpenCompareDialog}
                onClose={() => setOpenCompareDialog(false)}
            />
        </PageContentBox>
    );
}

export default UserLevelBusynessReportPage;