import React from "react";
import { useIntl } from "react-intl";
import PropTypes from 'prop-types';

import { reportsService } from "../../../services/reports-service";

import { DropdownSearchHelpTagInput } from "../../common/form-controls/dropdown-search-help-input.jsx";

const getRisks = (params, signal) => {
    return reportsService.getRisksSearchHelp(params, signal);
};

const RiskIdsInput = ({ tags, setTags }) => {
    const intl = useIntl();
    const label = intl.formatMessage({ id: "report-parameters.risks-label" });

    return (
        <DropdownSearchHelpTagInput
            tags={tags}
            setTags={setTags}
            getData={getRisks}
            label={label}
        />
    );
};

RiskIdsInput.propTypes = {
    tags: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        text: PropTypes.string
    })),
    setTags: PropTypes.func
};

export default RiskIdsInput;
