import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import {
    setRoles as setRolesAction,
    //setSystems as setSystemsAction,
    setSystemsExtended as setSystemsExtendedAction,
    setMatrixHeaders as setMatrixHeadersAction,
    setRisks as setRisksAction,
    setRiskLevels as setRiskLevelsAction,
    setParametersOpen as setParametersOpenAction,
    setVariantWithFetchModeling
} from "../../../reducers/reports-modeling/roleLevelSlice";

import useReduxValue from "../../common/hooks/useReduxValue";

import {
    MatrixIdsInput, RiskIdsInput, RiskLevelInput,
    SystemIdsInput
} from "../../sod-reports/report-parameters";

import ReportModelingParameters, { RolesInput } from "../report-modeling-parameters";
import {reportsService} from "../../../services/reports-service";
import { useNavigate } from "react-router-dom";

const getSystems = (params, signal) => {
    return reportsService.getSystemsOnlySearchHelp(params, signal);
};


const RoleLevelModelingParameters = ({ onShowVariantsClick }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const currentVariant = useSelector(state => state.reportsModeling.roleLevel.currentVariant);

    const [roles, setRoles] = useReduxValue(state => state.reportsModeling.roleLevel.roles, setRolesAction);
    //const [systems, setSystems] = useReduxValue(state => state.reportsModeling.roleLevel.systems, setSystemsAction);
    const [systemsExtended, setSystemsExtended] = useReduxValue(state => state.reportsModeling.roleLevel.extendedSystems, setSystemsExtendedAction);
    const [matrixHeaders, setMatrixHeaders] = useReduxValue(state => state.reportsModeling.roleLevel.matrixHeaders, setMatrixHeadersAction);
    const [risks, setRisks] = useReduxValue(state => state.reportsModeling.roleLevel.risks, setRisksAction);
    const [riskLevels, setRiskLevels] = useReduxValue(state => state.reportsModeling.roleLevel.riskLevels, setRiskLevelsAction);
    const [open, setOpen] = useReduxValue(state => state.reportsModeling.roleLevel.parametersOpen, setParametersOpenAction);

    const isAnalyzeDisabled = !(roles?.length && systemsExtended.length === 1)

    
    const titles = [intl.formatMessage({ id: "role-level-report-page.title" })];

    if (currentVariant) {
        titles.push(intl.formatMessage(
            { id: "user-level-report-page.variant-selected.title" },
            { name: currentVariant.variantName }
        ));
    }

    const handleOpenClick = (event) => {
        event.stopPropagation();
        setOpen(!open);
    };

    const handleApply = () => {
        navigate("/reports-modeling/role-level");

        dispatch(setVariantWithFetchModeling(null));
    };

    return (
        <ReportModelingParameters
            title={titles.join(" ")}
            open={open}
            applyDisabled={isAnalyzeDisabled}
            onOpenClick={handleOpenClick}
            onShowVariantsClick={onShowVariantsClick}
            onApplyClick={handleApply}
        >
            <RolesInput
                ids={roles}
                setIds={setRoles}
            />

            <SystemIdsInput
                tags={systemsExtended}
                setTags={setSystemsExtended}
                getSystems={getSystems}
            />

            <MatrixIdsInput
                tags={matrixHeaders}
                setTags={setMatrixHeaders}
            />

            <RiskIdsInput
                tags={risks}
                setTags={setRisks}
            />

            <RiskLevelInput
                tags={riskLevels}
                setTags={setRiskLevels}
            />
        </ReportModelingParameters>
    );
};

RoleLevelModelingParameters.propTypes = {
    onShowVariantsClick: PropTypes.func
};

export default RoleLevelModelingParameters;
