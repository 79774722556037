import React from "react";

import {
    AnalyzeButton,
    ReportActionsBox,
    SaveTemplateButton,
    ColumnSettingsButton,
    CompareReportsButton
} from "../../sod-reports/report-actions";
import PropTypes from "prop-types";

const ReportsModelingActions = ({
    onSubmitClick,
    onSaveClick,
    onColumnSettingsClick,
    isAnalyzeDisabled,
    onReportCompare
}) => {

    return(
        <ReportActionsBox>
            <AnalyzeButton onClick={onSubmitClick} disabled={isAnalyzeDisabled}/>
            <SaveTemplateButton onClick={onSaveClick} />
            <CompareReportsButton onClick={onReportCompare} disabled={isAnalyzeDisabled}/>
            <ColumnSettingsButton onClick={onColumnSettingsClick} />
        </ReportActionsBox>
    );
};

ReportsModelingActions.propTypes = {
    onSubmitClick: AnalyzeButton.propTypes.onClick,
    onSaveClick: SaveTemplateButton.propTypes.onClick,
    onColumnSettingsClick: ColumnSettingsButton.propTypes.onClick,
    onReportCompare: CompareReportsButton.propTypes.onClick,
    isAnalyzeDisabled: PropTypes.bool
};

export default ReportsModelingActions;