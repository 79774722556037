import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, CircularProgress, Typography } from "@mui/material";
import PropTypes from "prop-types";

import { adminService } from "../../../services/admin-service";
import { selectUserScreenButton } from "../../../reducers/authSlice";
import { USER_SCREEN_BUTTONS } from "../../../utils/auth";

import { Button } from "../../common/buttons";

import "./styles.less";
import { CheckboxInput, TextInput } from "../../common/form-controls";
import {
    setCoreSetting,
    setSodSetting,
    selectCoreSettings,
    selectSodSettings,
    fetchCoreSettings,
    fetchSodSettings,
    saveSodSettings,
    saveCoreSettings,
    setBusy,
    setDialogType,
    selectChangedCoreSettings,
    selectChangedSodSettings,
    SERVICE_ID,
} from "../../../reducers/admin/adminSlice";
import { FormattedMessage, useIntl } from "react-intl";
import { SubmitDialog } from "../../common/dialogs";


const SettingInput = ({ module, settingId, value, type, disabled, error, label }) => {
    const dispatch = useDispatch()

    const setValue = useCallback((value) => {

        const setFunc = module == SERVICE_ID.CORE ? setCoreSetting : setSodSetting
        dispatch(setFunc({
            settingId, value
        }))
    }, [dispatch, settingId])

    const onChange = useCallback((newValue) => {
        switch (type) {
            case "int":
                if (newValue === "") {
                    setValue(newValue)
                    return
                }

                newValue = parseInt(newValue)

                if (isNaN(parseInt(newValue))) {
                    newValue = value || ""
                }
                break
            case "string":
                break
            case "bool":
                newValue = !!newValue

                break
            default:
                return
        }

        setValue(newValue)
    }, [type, value, setValue])

    switch (type) {
        case "bool":
            return <CheckboxInput
                label={label}
                checked={value}
                disabled={disabled}
                onChange={(event) => onChange(event.target.checked)}
            />
        default:
            return (
                <TextInput
                    label={label}
                    value={value}
                    onChange={(event) => onChange(event.target.value)}
                    disabled={disabled}
                    error={error && error?.value === value}
                    errorMessage={error?.message}
                />
            );
    }
};


const AdminActionButton = ({
    children,
    onClick,
    disabled,
    sx={}
}) => {
    return (
        <Button
            className="admin-action-button"
            variant="outlined"
            onClick={onClick}
            sx={{...sx}}
            disabled={disabled}
        >
            {children}
        </Button>
    )
};

const AdminPage = () => {
    const genRulesButton = useSelector((state) => selectUserScreenButton(state, USER_SCREEN_BUTTONS.GEN_RULES));
    const clearMatricesButton = useSelector((state) => selectUserScreenButton(state, USER_SCREEN_BUTTONS.CLEAR_MATRICES));
    const coreSettings = useSelector(state => selectCoreSettings(state))
    const sodSettings = useSelector(state => selectSodSettings(state))
    const busy = useSelector(state => state.admin.admin.busy)
    const dialogType = useSelector(state => state.admin.admin.dialogType)
    const changedCoreSettings = useSelector(selectChangedCoreSettings)
    const changedSodSettings = useSelector(selectChangedSodSettings)
    const intl = useIntl()

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchCoreSettings())
        dispatch(fetchSodSettings())
    }, [dispatch])

    const handleGenRulesClick = async () => {
        dispatch(setBusy(true));

        try {
            await adminService.generateRules();
        } catch (error) {
            console.error(error);
        }

        dispatch(setBusy(false));
    };

    const handleDeleteMatrixClick = async () => {
        dispatch(setBusy(true));

        try {
            await adminService.clearMatrices();
        } catch (error) {
            console.error(error);
        }

        dispatch(setBusy(false));
    };

    const handleSaveCoreSettings = async () => {
        dispatch(saveCoreSettings())
    }

    const handleSaveSodSettings = async () => {
        dispatch(saveSodSettings())
    }

    return (
        <>
            <Box>
                {genRulesButton && (
                    <AdminActionButton
                        onClick={handleGenRulesClick}
                        disabled={busy}
                    >
                        {genRulesButton.title}
                    </AdminActionButton>
                )}

                {clearMatricesButton && (
                    <AdminActionButton
                        onClick={handleDeleteMatrixClick}
                        disabled={busy}
                    >
                        {clearMatricesButton.title}
                    </AdminActionButton>
                )}

            </Box>

            <Box className="admin-settings-inputs">
                <Typography className="admin-settings-title">
                    <FormattedMessage id="admin-settings-page.core-settings.title" />
                </Typography>

                {
                    coreSettings && coreSettings.map(item => (<SettingInput
                        module={SERVICE_ID.CORE}
                        key={item.key}
                        settingId={item.key}
                        type={item.type}
                        label={item.description}
                        value={item.value}
                    />))
                }

                <AdminActionButton
                    onClick={handleSaveCoreSettings}
                    disabled={changedCoreSettings && changedCoreSettings.length === 0}
                    sx={{
                        marginTop: "25px"
                    }}
                >
                    <FormattedMessage id="admin-settings-page.btn-save-settings" />
                </AdminActionButton>

            </Box>

            <Box marginTop="40px" className="admin-settings-inputs">
                <Typography className="admin-settings-title">
                    <FormattedMessage id="admin-settings-page.sod-settings.title" />
                </Typography>

                {
                    sodSettings && sodSettings.map(item => (<SettingInput
                        module={SERVICE_ID.SOD}
                        key={item.key}
                        settingId={item.key}
                        type={item.type}
                        label={item.description}
                        value={item.value}
                    />))
                }

                <AdminActionButton
                    onClick={handleSaveSodSettings}
                    disabled={changedSodSettings && changedSodSettings.length === 0}
                    sx={{
                        marginTop: "25px"
                    }}
                >
                    <FormattedMessage id="admin-settings-page.btn-save-settings" />
                </AdminActionButton>

            </Box>

            {busy && (
                <CircularProgress size="34px" />
            )}

            <SubmitDialog
                open={dialogType && dialogType === "success"}
                title={""}
                message={intl.formatMessage({ id: "admin-settings-page.success-save" })}
                onCancelClick={() => { dispatch(setDialogType(null)) }}
                buttons={[{
                    text: intl.formatMessage({ id: "common.submit-dialog.btn-close" }),
                    type: "close",
                    onClick: () => { dispatch(setDialogType(null)) }
                }]}
                disableRestoreFocus
            />
        </>
    );
};

AdminActionButton.propTypes = {
    children: PropTypes.node,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    sx: PropTypes.object
};

SettingInput.propTypes = {
    module: PropTypes.string,
    settingId: PropTypes.string,
    value: PropTypes.string,
    type: PropTypes.string,
    label: PropTypes.string,
    error: PropTypes.object,
    disabled: PropTypes.bool
};

export default AdminPage;
