import React, { useState } from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

import {
    REPORT_MODELING_VIEWS,
    REPORT_MODELING_SUBVIEWS,
    REPORT_MODELING_DIALOG_TYPES,
    REPORT_MODELING_VALUE_TYPES,
} from "../../../utils/reports-modeling-utils";

import { findItemInTree } from "../../../utils/tree-table";

import ReportModelingViewsToolbar from "../report-modeling-views-toolbar";
import PfcgView from "../pfcg-view";
import { ReportModelingEditDialogRange, ReportModelingEditDialogSelect } from "../report-modeling-edit-dialogs";

import SelectPermissionsDialog from "../select-permissions-dialog";
import ManualSelectPermissionsDialog from "../manual-select-permissions-dialog";
import OrgLevelsDialog from "../org-levels-dialog";
import RoleMenu from "../role-menu";

import SubviewsPicker from "./subviews-picker.jsx";
import ReportModelingViewsActions from "../report-modeling-views-toolbar/report-modeling-views-actions.jsx";

import "./styles.less";

const editDialogsMap = {
    [REPORT_MODELING_VALUE_TYPES.SELECT]: ReportModelingEditDialogSelect,
    [REPORT_MODELING_VALUE_TYPES.RANGE]: ReportModelingEditDialogRange
};

const RoleModelingBox =({
    role,
    onTreeItemFieldChange,
    onAddTreeItems,
    onSetOrgLevelValues,
    onOpenRoleMenuNode,
    onAddRoleMenuFolder,
    onAddRoleMenuNode,
    onRemoveRoleMenuNode,
    onCopyRoleMenu,
    onRemoveTreeItem,
    onExpandItemTree
}) => {
    const [currentView, setCurrentView] = useState(REPORT_MODELING_VIEWS.PFCG);
    const [subview, setSubview] = useState(REPORT_MODELING_SUBVIEWS.MENU);
    const [dialog, setDialog] = useState(null);

    const handleEditItemClick = (item) => {
        const permissionObjectPath = item.path.slice(0, 2);
        const permissionObject = findItemInTree(permissionObjectPath, role.tree);

        const field = item.orgLevel in role.orgLevels && !item.values
            ? {
                ...item,
                values: role.orgLevels[item.orgLevel].values
            }
            : item;

        setDialog({
            type: REPORT_MODELING_DIALOG_TYPES.EDIT,
            dialogComponent: editDialogsMap[item.valueType],
            field,
            permissionObject
        });
    };

    const handleEditDialogApply = (field, values) => {
        onTreeItemFieldChange(field.path, "values", values);
        setDialog(null);
    };

    const handleSelectPermisssionsClick = () => {
        if (role?.tree) {
            setDialog({
                type: REPORT_MODELING_DIALOG_TYPES.SELECT_PERMISSIONS
            });
        }
    };

    const handleSelectPermissionsDialogApply = (appliedTree) => {
        onAddTreeItems(appliedTree);
        setDialog(null);
    };

    const handleManualSelectPermisssionsClick = () => {
        if (role?.tree) {
            setDialog({
                type: REPORT_MODELING_DIALOG_TYPES.MANUAL_SELECT_PERMISSIONS
            });
        }
    };

    const handleOrgLevelsClick = () => {
        if (role?.orgLevels) {
            setDialog({
                type: REPORT_MODELING_DIALOG_TYPES.ORG_LEVELS
            });
        }
    };

    const handleOrgLevelsDialogApply = (values) => {
        onSetOrgLevelValues(values);
        setDialog(null);
    };

    const handleCloseDialog = () => setDialog(null);

    const editDialogOpen = dialog?.type === REPORT_MODELING_DIALOG_TYPES.EDIT;
    const selectPermissionsDialogOpen = dialog?.type === REPORT_MODELING_DIALOG_TYPES.SELECT_PERMISSIONS;
    const manualSelectPermissionsDialogOpen = dialog?.type === REPORT_MODELING_DIALOG_TYPES.MANUAL_SELECT_PERMISSIONS;
    const orgLevelsDialogOpen = dialog?.type === REPORT_MODELING_DIALOG_TYPES.ORG_LEVELS;

    const menuOpen = role?.menu && subview === REPORT_MODELING_SUBVIEWS.MENU;
    const pfcgViewOpen = !menuOpen && role?.tree && currentView === REPORT_MODELING_VIEWS.PFCG;

    return (
        <>
            <ReportModelingViewsToolbar
                view={currentView}
                actionsDisabled={!role}
                onChangeView={setCurrentView}
                onSelectClick={handleSelectPermisssionsClick}
                onManualSelectClick={handleManualSelectPermisssionsClick}
                onOrgLevelsClick={handleOrgLevelsClick}
            />

            <Box className="role-modeling-subviews">

                <SubviewsPicker
                    currentSubview={subview}
                    onChange={(e) => setSubview(e.target.value)}
                />

                {
                    pfcgViewOpen && (<ReportModelingViewsActions
                        actionsDisabled={!role}
                        onSelectClick={handleSelectPermisssionsClick}
                        onManualSelectClick={handleManualSelectPermisssionsClick}
                        onOrgLevelsClick={handleOrgLevelsClick}
                    />)
                }
                {menuOpen && (
                    <RoleMenu
                        menuTree={role.menu}
                        systemId={role.systemId}
                        onOpenNode={onOpenRoleMenuNode}
                        onAddFolder={onAddRoleMenuFolder}
                        onAddNode={onAddRoleMenuNode}
                        onRemoveNode={onRemoveRoleMenuNode}
                        onCopyMenu={onCopyRoleMenu}
                    />
                )}
                {pfcgViewOpen && (
                    <PfcgView
                        tree={role.tree}
                        orgLevels={role.orgLevels}
                        onItemFieldChange={onTreeItemFieldChange}
                        onEditItemClick={handleEditItemClick}
                        onRemoveItemClick={onRemoveTreeItem}
                        onExpandItemTreeClick={onExpandItemTree}
                    />
                )}
            </Box>


            {editDialogOpen && (
                <dialog.dialogComponent
                    open={editDialogOpen}
                    systemId={role?.systemId}
                    permissionObject={dialog.permissionObject}
                    field={dialog.field}
                    onClose={handleCloseDialog}
                    onApply={handleEditDialogApply}
                />
            )}

            {selectPermissionsDialogOpen && (
                <SelectPermissionsDialog
                    systemId={role?.systemId}
                    open={selectPermissionsDialogOpen}
                    onApply={handleSelectPermissionsDialogApply}
                    onClose={handleCloseDialog}
                />
            )}

            {manualSelectPermissionsDialogOpen && (
                <ManualSelectPermissionsDialog
                    open={manualSelectPermissionsDialogOpen}
                    systemId={role?.systemId}
                    onClose={handleCloseDialog}
                    onApply={handleSelectPermissionsDialogApply}
                />
            )}

            {orgLevelsDialogOpen && (
                <OrgLevelsDialog
                    open={orgLevelsDialogOpen}
                    systemId={role.systemId}
                    orgLevels={role.orgLevels}
                    onClose={handleCloseDialog}
                    onApply={handleOrgLevelsDialogApply}
                />
            )}
        </>
    );
};

RoleModelingBox.propTypes = {
    role: PropTypes.shape({
        role: PropTypes.string,
        systemId: PropTypes.string,
        profile: PropTypes.string,
        tree: PropTypes.arrayOf(PropTypes.object),
        orgLevels: PropTypes.object,
        menu: PropTypes.arrayOf(PropTypes.object),
    }),
    onTreeItemFieldChange: PropTypes.func,
    onAddTreeItems: PropTypes.func,
    onSetOrgLevelValues: PropTypes.func,
    onOpenRoleMenuNode: PropTypes.func,
    onAddRoleMenuFolder: PropTypes.func,
    onAddRoleMenuNode: PropTypes.func,
    onRemoveRoleMenuNode: PropTypes.func,
    onCopyRoleMenu: PropTypes.func,
    onRemoveTreeItem: PropTypes.func,
    onExpandItemTree: PropTypes.func
};

export default RoleModelingBox;