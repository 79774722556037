import React, { useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { CommonDialog, CommonDialogContent, CommonDialogTitle } from "../../common/dialogs";
import { SearchInput } from "../../common/form-controls";

import PersistedRolesTable from "../persisted-roles-table";

const GroupRoleAddRolesDialog = ({ open, systemId, onClose, onSubmit, showSingleRoles }) => {
    const intl = useIntl();
    const [searchString, setSearchString] = useState("");

    const columns = [
        {
            id: "role",
            title: intl.formatMessage({ id: "add-role-dialog.columns.key" })
        },
        {
            id: "description",
            title: intl.formatMessage({ id: "add-role-dialog.columns.text" })
        }
    ];

    return (
        <CommonDialog
            className="group-role-add-roles-dialog"
            open={open}
        >
            <CommonDialogTitle
                title={intl.formatMessage({ id: "user-level-modeling.role-search-help-dialog.toolbar.add" })}
                onClose={onClose}
            />

            <CommonDialogContent>
                <SearchInput
                    value={searchString}
                    onSearchClick={(event) => setSearchString(event.target.value)}
                />

                <PersistedRolesTable
                    columns={columns}
                    systemId={systemId}
                    searchString={searchString}
                    onRowClick={onSubmit}
                    showSingleRoles={showSingleRoles}
                />
            </CommonDialogContent>
        </CommonDialog>
    );
};

GroupRoleAddRolesDialog.propTypes = {
    open: PropTypes.bool,
    systemId: PropTypes.string,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    showSingleRoles: PropTypes.bool
};

export default GroupRoleAddRolesDialog;