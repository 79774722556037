import React, { useRef, useState } from "react";
import { ListItemText, ClickAwayListener, Box, Collapse, List, ListItem } from "@mui/material";
import { TimeField } from "@mui/x-date-pickers";
import PropTypes from "prop-types";
import dayjs from "dayjs";

import { TimeIcon } from "../icons";

import { getInputClassName } from "./input.jsx";
import { FormHelperTextContent, formHelperTextClassName } from "./form-helper-text.jsx";
import { inputLabelClassName } from "./input-label.jsx";
import { formControlClassName } from "./form-control.jsx";

const getTimeItems = (formatter) => {
    const timeItems = []

    for (let i = 0; i < 24; i++) {
        const date = new Date()
        date.setHours(i, 0, 0, 0)

        timeItems.push({
            key: date.toString(),
            text: formatter(date)
        })
    }
    return timeItems
}

const TimePicker = ({
    className="",
    fullWidth=true,
    shrink=true,
    label,
    value,
    error=false,
    errorMessage,
    warning=false,
    warningMessage,
    showFormHelpers=true,
    disabled=false,
    onChange
}) => {
    const ref = useRef();

    const [open, setOpen] = useState(false);

    const inputClassName = getInputClassName({
        error,
        warning
    });

    const helperText = showFormHelpers && (error && errorMessage || warning && warningMessage) ? (
        <FormHelperTextContent
            error={error}
            errorMessage={errorMessage}
            warning={warning}
            warningMessage={warningMessage}
        />
    ) : undefined;
    
    const itemTextFormatter = (date) => date ? `${date.getHours().toString().padStart(2, "0")}:00` : "";
    const items = getTimeItems(itemTextFormatter);

    const onSelectItem = (key) => {
        const date = new Date(key);

        if (value) {
            const newHours = date.getHours();
            const newMinutes = date.getMinutes();
            const newDate = new Date(value.getTime());

            newDate.setHours(newHours);
            newDate.setMinutes(newMinutes);
            newDate.setSeconds(0);
            newDate.setMilliseconds(0);

            onChange(newDate);
            setOpen(false);
        }
    };

    const handleChange = (newValue) => {
        if(newValue.isValid()) {
            const newDate = newValue["$d"] || null;
            onChange(newDate);
        }else {
            onChange(new Date());
        }
    };

    return(
        <ClickAwayListener onClickAway={() => setOpen(false)}>
            <Box sx={{
                width: "100%"
            }}>
                <TimeField
                    ref={ref}
                    className={`${formControlClassName} ${className}`}
                    fullWidth={fullWidth}
                    shrink={shrink}
                    label={label}
                    disabled={disabled}
                    value={value ? dayjs(value) : null}
                    onChange={handleChange}
                    onFocus={() => setOpen(true)}
                    slotProps={{
                        textField: {
                            InputLabelProps: {
                                className: inputLabelClassName,
                                shrink: shrink
                            },
                            InputProps: {
                                className: inputClassName,
                                endAdornment: <TimeIcon />
                            },
                            FormHelperTextProps: {
                                className: formHelperTextClassName
                            },
                            helperText: helperText
                        }
                    }}
                    format="HH:mm"
                />

                <Collapse
                    sx={{
                        width: ref && ref.current && ref.current.offsetWidth,
                        position: "absolute",
                        zIndex: 100,
                        backgroundColor: "white",
                        borderRadius: "4px",
                        overflow: "hidden",
                        boxShadow: "0px 10px 11px 0px rgba(0, 0, 0, 0.34)"
                    }}
                    in={open}
                >
                    <List
                        sx={{
                            maxHeight: `${100}px`,
                            overflowY: "auto",
                            padding: "8px 20px 8px 8px",
                            display: "flex",
                            flexDirection: "column",
                            gap: "4px",
                            "&::-webkit-scrollbar": {
                                width: "12px"
                            },
                            "&::-webkit-scrollbar-track": {
                                backgroundColor: "var(--stroke-1)"
                            },
                            "&::-webkit-scrollbar-thumb": {
                                backgroundColor: "var(--font-4)"
                            }
                        }}
                    >
                        {
                            items && items.map(item => (
                                <DropdownItem
                                    key={item.key}
                                    item={item}
                                    onSelectItem={onSelectItem}
                                />
                            ))
                        }
                    </List>
                </Collapse>
            </Box>
        </ClickAwayListener>
    )
};

const DropdownItem = ({ item, onSelectItem }) => {
    return (
        <ListItem
            key={item.key}
            onClick={() => onSelectItem(item.key)}
            sx={{
                padding: 0,
                cursor: "pointer",
                gap: "6px"
            }}
        >
            <ListItemText
                primary={item.text}
                sx={{
                    margin: 0,
                    color: "var(--font-4)",
                    fontFamily: "Mulish",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "150%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                }}
            />
        </ListItem>
    )
}

TimePicker.propTypes = {
    className: PropTypes.string,
    fullWidth: PropTypes.bool,
    shrink: PropTypes.bool,
    label: PropTypes.string,
    value: PropTypes.instanceOf(Date),
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    warning: PropTypes.bool,
    warningMessage: PropTypes.string,
    showFormHelpers: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func
};

DropdownItem.propTypes = {
    item: PropTypes.shape({
        key: PropTypes.string,
        text: PropTypes.string
    }),
    onSelectItem: PropTypes.func
};

export default TimePicker;