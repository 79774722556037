import React from "react";
import {
    usePickerLayout,
    PickersLayoutRoot,
    pickersLayoutClasses,
    PickersLayoutContentWrapper,
} from '@mui/x-date-pickers/PickersLayout';
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import PropTypes from "prop-types";

import { CalendarIcon } from "../icons";

import { inputLabelClassName } from './input-label.jsx';
import { formControlClassName } from './form-control.jsx';
import { getInputClassName } from './input.jsx';
import { FormHelperTextContent, formHelperTextClassName } from "./form-helper-text.jsx";

import "./styles.less"

const SodDatePickerLayout = (props) => {
    const { toolbar, tabs, content, actionBar } = usePickerLayout(props);

    // Put the action bar before the content
    return (
        <PickersLayoutRoot className={`${pickersLayoutClasses.root} test-datepicker`} ownerState={props}>
            {toolbar}
            {actionBar}
            <PickersLayoutContentWrapper className={pickersLayoutClasses.contentWrapper}>
                {tabs}
                {content}
            </PickersLayoutContentWrapper>
        </PickersLayoutRoot>
    );
}

const DatePicker = ({
    className="",
    fullWidth=true,
    shrink=true,
    label,
    value,
    error=false,
    errorMessage,
    warning=false,
    warningMessage,
    showFormHelpers=true,
    disabled=false,
    onChange
}) => {
    const inputClassName = getInputClassName({
        error,
        warning
    });

    const helperText = showFormHelpers && (error && errorMessage || warning && warningMessage) ? (
        <FormHelperTextContent
            error={error}
            errorMessage={errorMessage}
            warning={warning}
            warningMessage={warningMessage}
        />
    ) : undefined;

    const handleChange = (newValue) => {
        if(newValue.isValid()) {
            const newDate = newValue["$d"] || null;
            onChange(newDate);
        }else {
            onChange(new Date());
        }
    };

    return (
        <MUIDatePicker
            className={`${formControlClassName} ${className}`}
            fullWidth={fullWidth}
            shrink={shrink}
            label={label}
            disabled={disabled}
            showDaysOutsideCurrentMonth
            views={["day"]}
            format="DD.MM.YYYY"
            value={value ? dayjs(value) : null}
            onAccept={handleChange}
            onChange={handleChange}
            slots={{ 
                layout: SodDatePickerLayout,
                openPickerIcon: CalendarIcon
            }}
            slotProps={{
                textField: {
                    InputLabelProps: {
                        className: inputLabelClassName,
                        shrink: shrink
                    },
                    InputProps: {
                        className: inputClassName
                    },
                    FormHelperTextProps: {
                        className: formHelperTextClassName
                    },
                    helperText: helperText
                }
            }}
        />
    );
};

DatePicker.propTypes = {
    className: PropTypes.string,
    fullWidth: PropTypes.bool,
    shrink: PropTypes.bool,
    label: PropTypes.string,
    value: PropTypes.instanceOf(Date),
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    warning: PropTypes.bool,
    warningMessage: PropTypes.string,
    showFormHelpers: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool
};

export default DatePicker;