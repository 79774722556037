import React from "react";
import { useIntl } from "react-intl";
import PropTypes from 'prop-types';

import { reportsService } from "../../../services/reports-service";

import { DropdownSearchHelpTagInput } from "../../common/form-controls/dropdown-search-help-input.jsx";

const getRiskLevels = (params, signal) => {
    return reportsService.getRiskLevelSearchHelp(params, signal);
};

const RiskLevelInput = ({ tags, setTags }) => {
    const intl = useIntl();
    const label = intl.formatMessage({ id: "report-parameters.risk-level-label" });

    return (
        <DropdownSearchHelpTagInput
            tags={tags}
            setTags={setTags}
            getData={getRiskLevels}
            label={label}
            getTagText={(item) => `${item.text}`}
            getListItemText={(item) => `${item.text}`}
        />
    )
};

RiskLevelInput.propTypes = {
    tags: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        text: PropTypes.string
    })),
    setTags: PropTypes.func
};

export default RiskLevelInput;
