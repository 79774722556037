import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { addGroupRoleChildToModeling, removeGroupRoleChildFromModeling, expandGroupRole, setSelectedViewRole } from "../../../reducers/reports-modeling/roleLevelSlice";

import GroupRoleModelingBox from "../group-role-modeling-box";

const RoleLevelGroupRoleModelingBox = ({ selectedRole }) => {
    const dispatch = useDispatch();

    const [rows, setRows] = useState([]);
    const roles = useSelector((state) => state.reportsModeling.roleLevel.modeling.rolesForView);
    const isAddDisabled = !selectedRole?.isComplex;
    const isDeleteDisabled = !selectedRole?.isChild;

    const handleAddChildRole = (newChildRole) => {
        dispatch(
            addGroupRoleChildToModeling({
                id: selectedRole.id,
                newChildRole
            })
        );
        handleExpandChildrenRoles(selectedRole, true)
    }

    const handleRemoveChildRoles = () => {
        dispatch(
            removeGroupRoleChildFromModeling({
                roleIdToRemove: selectedRole.id
            }))
        dispatch(setSelectedViewRole(null))
    };

    const handleExpandChildrenRoles = (role, stayOpen = false) => {
        dispatch(setSelectedViewRole(role))
        dispatch(expandGroupRole({ role: role, stayOpen: stayOpen }))

    };

    const handleRowClick = (row, setActiveRow) => {
        dispatch(setSelectedViewRole(row))
        setActiveRow(row.id)
    }



    useEffect(() => {
        if (!roles) {
            return
        }

        const rows = []

        roles.forEach(role => {
            rows.push(role)
            if (role.isComplex && role.open) {
                role.children.forEach(childrenRole => {
                    rows.push(childrenRole)
                })
            }
        })

        setRows(rows)
        
    }, [roles])

    return (
        <GroupRoleModelingBox
            roles={rows}
            selectedRole={selectedRole}
            onAddChildRole={handleAddChildRole}
            onRemoveChildRoles={handleRemoveChildRoles}
            handleRowClick={handleRowClick}
            handleExpandChildrenRoles={handleExpandChildrenRoles}
            isAddDisabled={isAddDisabled}
            isDeleteDisabled={isDeleteDisabled}
        />
    )
};

RoleLevelGroupRoleModelingBox.propTypes = {
    selectedRole: PropTypes.object
};

export default RoleLevelGroupRoleModelingBox