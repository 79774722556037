import React from "react"
import GroupRoleView from "../group-role-view"
import SingleRoleView from "../single-role-view"
import PropTypes from "prop-types";


const CustomRoleFormPageViews = ({ form, setForm }) => {


    return (
        <>
            {form.isComplex && (
                <GroupRoleView form={form} setForm={setForm}></GroupRoleView>
            )}

            {!form.isComplex && (
                <SingleRoleView form={form} setForm={setForm}></SingleRoleView>
            )

            }
        </>
    )
}

export default CustomRoleFormPageViews;

CustomRoleFormPageViews.propTypes = {
    form: PropTypes.object,
    setForm: PropTypes.func
}

