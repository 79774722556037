import { lockService } from "../services/lock-service";
import SodSocketService from "../services/sod-socket-service";

const socketService = SodSocketService.getInstance();

class LockManager{
    constructor(entity){
        this.entity = entity;
    }

    static _getEntityDataFunction(entity){
        switch(entity){
            case "system":
                return lockService.lockSystem.bind(lockService);

            case "systemGroup":
                return lockService.lockSystemGroup.bind(lockService);

            case "function":
                return lockService.lockFunction.bind(lockService);

            case "matrix-header":
                return lockService.lockMatrixHeader.bind(lockService);

            case "risk":
                return lockService.lockRisk.bind(lockService);

            case "risk-levels":
                return lockService.lockRiskLevels.bind(lockService);
            
            case "critical-role":
                return lockService.lockCriticalRole.bind(lockService);
            
            case "critical-profile":
                return lockService.lockCriticalProfile.bind(lockService);

            case "user":
                return lockService.lockUser.bind(lockService);

            case "custom-role":
                
                return lockService.lockCustomRole.bind(lockService);
            case "busynessProcess":
                return lockService.lockBusynessProcess.bind(lockService);

            case "summary-report-schedule":
                return lockService.lockSummaryReportSchedule.bind(lockService)
        }
    }

    async lockObject(key){
        let sessionId;
        
        try {
            sessionId = await socketService.getSessionId();
        } catch (error){
            console.log(error)
        }

        try {
            await LockManager._getEntityDataFunction(this.entity)("lock", key, sessionId);
        } catch (error){
            return {result: false, messages: error}
        }

        return {result: true, messages: null}
    }

    async unlockObject(key){
        try {
            await LockManager._getEntityDataFunction(this.entity)("unlock", key);
        } catch (error){
            return {result: false, messages: error};
        }

        return {result: true, messages: null};
    }
}

export default LockManager;