import React from "react"
import GroupRoleModelingBox from "../../group-role-modeling-box"
import { nanoid } from "@reduxjs/toolkit";
import PropTypes from "prop-types";

const GroupRoleView = ({ form, setForm }) => {


    const handleAddChildRole = (newChildRole) => {
        newChildRole.id = nanoid()
        if (!form.children.some(role => role.role === newChildRole.role)) {
            setForm({ ...form, children: [...form.children, newChildRole] })
        }
    }


    const handleRemoveChildRoles = (roleIdsToRemove) => {
        setForm({ ...form, children: form.children.filter(role => !roleIdsToRemove.includes(role.id)) })
    }


    return (
        <GroupRoleModelingBox
            role={form}
            onAddChildRole={handleAddChildRole}
            onRemoveChildRoles={handleRemoveChildRoles}
        />
    )
}

export default GroupRoleView

GroupRoleView.propTypes = {
    form: PropTypes.object,
    setForm: PropTypes.func

}