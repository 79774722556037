import { nanoid } from "@reduxjs/toolkit";

import { calcRoleTreeItemStatus, calcRoleTreeItemTrafficLightStatus, setClassCheckedIndeterminate, sortTreeChildren } from "../utils/reports-modeling-utils.js";
import { createTreeNode } from "../utils/tree-table.js";
import { addEncodedURIComponent } from "../utils/addEncodedURIComponent.js";

import ReportsService from "./reports-service.js";

class ReportsModelingService extends ReportsService {
    async getModelingRoles(roles, systems, users, variant, reportLevel) {
        const params = {};

        if (roles) {
            params.roles = roles.toString();
        }

        if (systems) {
            params.system_ids = systems.toString();
        }

        if (users) {
            params.employees = users.toString();
        }

        if (reportLevel) {
            params.levels = reportLevel;
        }

        const config = this.generateConfigForGetEntities(params);
        const url = addEncodedURIComponent("/sod/reports/simulation/roles", "variant", variant);
        const apiRoles = await this.get(url, config);

        return apiRoles?.map(role => this.parseModelingRole(role)) ?? [];
    }

    async getModelingUsers(users, systems, reportLevel, variantName) {
        const params = {
            employees: users.toString(),
            system_ids: systems.toString(),
            variant: variantName
        };

        const config = this.generateConfigForGetEntities(params);
        const apiUsers = await this.get("/sod/reports/simulation/employee-roles", config);

        return apiUsers?.map(user => this.parseModelingUser(user)) ?? [];
    }

    async getRolePermissions(role, systemId) {
        if (!role || !systemId) {
            return;
        }

        const params = {
            system: systemId
        };

        const config = this.generateConfigForGetEntities(params);
        const data = await this.get(`/sod/reports/pfcg/role/${role}`, config);
        const tree = this.parseRolePermissionsTree(data.tree);
        const orgLevels = this.parseRoleOrgLevels(data.orgLevels);

        return { tree, orgLevels };
    }

    async getRolePermissionsVariant(role, systemId, variantName, reportLevel, username) {
        if (!role || !systemId) {
            return;
        }

        const params = {
            system: systemId,
        };

        if (variantName) {
            params["variant_name"] = encodeURIComponent(variantName)
            params["level"] = reportLevel

            if (username) {
                params["user"] = username
            }
        }

        const config = this.generateConfigForGetEntities(params);

        const data = await this.get(`/core/pfcg/role/${role}`, config);
        const tree = this.parseRolePermissionsTree(data.tree);
        const orgLevels = this.parseRoleOrgLevels(data.orgLevels);
        const menu = this.parseRoleMenu(data.menu);
        const childrens = this.parseRoleChildrenList(data.children, systemId)
        const tcodeToValue = this.parseTcodeValues(data.tcode_values, systemId)

        return { tree, orgLevels, menu, childrens, tcodeToValue };
    }

    async createNewRole(role, description, systemId, isComplex) {
        const data = await this.post("/core/integration/role/detailed", {
            system_id: systemId,
            role: role,
            is_complex_role: isComplex,
            description: description
        })

        return data
    }

    async getPermissionsTree(params, signal, system, permissions, checkedPermissions) {
        const paramsInner = params ? { ...params } : {};

        let url = "/api/sod/reports/pfcg/permission-tree?system="

        if (system) {
            url += `${system}`
        }

        if (permissions) {
            url += `&permissions=${permissions.toString()}`
        }

        const config = this.generateConfigForGetEntities(paramsInner, signal);

        if (config.params) {
            if (config.params.page) {
                url += `&page=${config.params.page}`
            }
            if (config.params.limit) {
                url += `&limit=${config.params.limit}`
            }
            if (config.params.search) {
                url += `&search=${config.params.search}`
            }
        }

        const batchStruct = [{ url: url, method: "get" }]
        const data = await this.post("/sod/batch/", batchStruct);

        const rows = this.parsePermissionsTree(data[0].body.rows, checkedPermissions);
        const total = data.total;

        return { rows, total };
    }

    async calcRoleLevelModelingReport(form) {
        const data = await this.post("/sod/reports/role-level/calc-simulation", form);
        return data;
    }


    async calcRoleLevelModelingCompareReport(form) {
        const data = await this.post("/sod/reports/simulation/role-level/compare", form);
        return data;
    }

    async calcUserLevelModelingReport(form) {
        const data = await this.post("/sod/reports/user-level/calc-simulation", form);
        return data;
    }

    //async initializeNewRoles(rolesPfcgForInit, roleMenuForInit, groupRolesForInit, changedOrgLevelsForInit) {
    async calcUserLevelModelingCompareReport(form) {
        const data = await this.post("/sod/reports/simulation/user-level/compare", form);
        return data;
    }

    async initializeNewRoles(rolesPfcgForInit, roleMenuForInit, groupRolesForInit, changedOrgLevelsForInit) {
        const data = await this.post("/sod/reports/role-initialization", {
            changed_roles: rolesPfcgForInit,
            changed_group_roles: groupRolesForInit,
            changed_menues: roleMenuForInit,
            changed_org_levels: changedOrgLevelsForInit
        });

        return data;
    }

    async getRoleLevelModelingColumns(reportLevel, reportType, defaultColumns) {
        return this.getReportColumns("/sod/reports/user-role-model-column", reportLevel, reportType, defaultColumns);
    }

    async getUserLevelModelingColumns(reportLevel, reportType, defaultColumns) {
        return this.getReportColumns("/sod/reports/user-model-column", reportLevel, reportType, defaultColumns);
    }

    async saveRoleLevelModelingColumns(columns, reportLevel, reportType) {
        return this.saveReportColumns("/sod/reports/user-role-model-column", columns, reportLevel, reportType);
    }

    async saveUserLevelModelingColumns(columns, reportLevel, reportType) {
        return this.saveReportColumns("/sod/reports/user-model-column", columns, reportLevel, reportType);
    }

    async getRolesModelingSearchHelp(params, signal) {
        const config = this.generateConfigForGetEntities(params, signal);

        const data = await this.get("/sod/reports/search-help/simulation-roles", config);

        const rows = data && data.rows.map(item => this.parseRole(item));
        const total = data && data.total;

        return { rows, total };
    }

    async savePermissionsTreeVariant(requestData) {
        return this.post("/core/pfcg/permission-tree/variant", requestData);
    }

    async saveGroupRolesVariant(roles, variant, reportLevel, oldVariant) {
        const delta = roles
            .filter(role => role.isComplex && role.children?.length > 0)
            .map(role => this.parseGroupRoleForVariant(role, variant, reportLevel, oldVariant));

        if (delta.length > 0) {
            return this.post("/core/pfcg/group-role/variant", delta);
        }
    }

    async saveRoleMenuVariant(roles, variant, reportLevel) {
        const menus = roles
            .filter(role => !role.isComplex && role.status)
            .map(role => this.parseRoletoMenuVariant(role, variant, reportLevel));

        if (menus.length > 0) {
            return this.post("/core/pfcg/role-menu/variant", menus);
        }
    }

    async _getRoleMenu(roleId, systemId, signal) {
        const params = { system: systemId };
        const config = this.generateConfigForGetEntities(params, signal);

        return this.get(
            `/core/integration/role-menu-tree/${roleId}`,
            config
        );
    }

    async getRoleMenu(roleId, systemId, signal) {
        const data = await this._getRoleMenu(roleId, systemId, signal);
        return data && this.parseRoleMenu(data);
    }

    async getCopyRoleMenu(roleId, systemId, signal) {
        const data = await this._getRoleMenu(roleId, systemId, signal);
        return data && this.parseCopyRoleMenu(roleId, data);
    }

    async getRoleMenuVariant(roleId, systemId, variant, reportLevel, signal) {
        const params = {
            role: roleId,
            level: reportLevel,
            system: systemId
        };

        const config = this.generateConfigForGetEntities(params, signal);
        const url = addEncodedURIComponent("/core/pfcg/role-menu/variant", "variant_name", variant);
        const data = await this.get(url, config);
        return data && this.parseRoleMenu(data);
    }

    async getRoleChildrens(role, systemId) {
        if (!role || !systemId) {
            return;
        }

        const params = {
            system: systemId,
            complex_roles: role
        };

        const config = this.generateConfigForGetEntities(params);
        const data = await this.get(`/sod/reports/child-roles`, config);

        const parsedChildren = this.parseRoleChildren(data, role, systemId)

        return parsedChildren
    }

    async getRoleChildrensVariant(role, systemId, level, variant) {
        if (!role || !systemId) {
            return;
        }

        const params = {
            system: systemId,
            role: role,
            level: level,
            variant_name: variant
        };

        const config = this.generateConfigForGetEntities(params);
        const data = await this.get(`/core/pfcg/group-role/variant`, config);

        const parsedChildren = this.parseRoleChildren(data, role, systemId)

        return parsedChildren
    }

    parseRoleChildren(data, role, systemId) {
        const childrens = data[role] || []

        return childrens.map(childrenRole => ({
            id: nanoid(),
            role: childrenRole,
            systemId: systemId
        }))
    }

    parseRoleChildrenList(childrens, systemId) {

        return childrens.map(childrenRole => ({
            //id: nanoid(),
            role: childrenRole,
            systemId: systemId
        }))
    }

    parseTcodeValues(tcodeToValueMap, systemId) {
        const result = {}

        if (tcodeToValueMap === null){
            return result
        }

        Object.entries(tcodeToValueMap).forEach(([tcode, values]) => {
            result[tcode] = values.map(value => this.parseFunctionDefaultPermission(value, systemId))
        })

        return result
    }

    parseFunctionDefaultPermission(item, systemId) {
        return {
            system: systemId,
            permission: item.permission,
            field: item.permissionf_field,
            valueFrom: item.value_from,
            valueTo: item.value_to,
        }
    }

    parseRoleMenuChildVariant(child) {
        const parsedChild = {
            report_name: child.reportName,
            report_type: child.reportType,
            is_folder: child.isFolder,
            node_text: child.nodeText,
            description: child.description,
            children: child?.children || []
        };

        if (child.children) {
            parsedChild.children = child.children.map(
                subChild => this.parseRoleMenuChildVariant(subChild)
            );
        }

        return parsedChild;
    }

    parseRoletoMenuVariant(role, variant, reportLevel) {
        const menuItem = {
            variant_name: variant,
            level: reportLevel,
            object_id: role.role,
            system: role.systemId,
            menu_tree: []
        }

        menuItem["menu_tree"] = role.menu?.[0].children?.map(child => this.parseRoleMenuChildVariant(child))

        return menuItem
    }

    parseGroupRoleForVariant(role, variant, reportLevel) {
        return {
            variant_name: variant,
            level: reportLevel,
            object_id: role.role,
            system: role.systemId,
            children: role.children.map(role => role.role)
        }
    }

    parseRoleToTreeVariantWithStatus(role, variant, reportLevel, oldVariant) {
        const parsedRole = {
            variant_name: variant,
            level: reportLevel,
            object_id: role.role,
            system: role.systemId,
            operation: role.status,
            delta: [],
            org_levels: []
        }

        if (oldVariant) {
            parsedRole.old_variant_name = oldVariant;
        }

        return parsedRole
    }

    parseRoleToTreeVariant(role) {
        const parsedRole = {
            object_id: role.role,
            system: role.systemId,
            initialized: role.initialized,
            delta: role.delta?.map(deltaItem => ({
                permission_object: deltaItem.permission_object,
                permission_group: deltaItem.permission,
                field: deltaItem.field,
                operation: deltaItem.operation,
                status: deltaItem.status,
                active: deltaItem.active ?? false,
                values: deltaItem.values?.map(({ from, to }) => ({
                    value_from: from ?? "",
                    value_to: to ?? ""
                }))
            })),
            org_levels: role.orgLevelsDelta?.map(deltaItem => ({
                org_level: deltaItem.id,
                operation: deltaItem.operation,
                values: deltaItem.values?.map(({ from, to }) => ({
                    value_from: from ?? "",
                    value_to: to ?? ""
                }))
            })),
            menu_tree: role.menu?.[0]?.children?.map(child => this.parseRoleMenuChildVariant(child)) || [],
            children: role.children?.map(child => ({
                description: child.description ?? "",
                role: child.role,
                system_id: child.systemId
            }))
        };

        return parsedRole;
    }

    parseRoleOrgLevels(orgLevels) {
        if (orgLevels) {
            const parsedOrgLevels = orgLevels.reduce((acc, orgLevel) => {
                acc[orgLevel.id] = this.parseRoleOrgLevel(orgLevel);
                return acc;
            }, {});

            return parsedOrgLevels;
        }
    }

    parseRoleOrgLevel(orgLevel) {
        const values = orgLevel.values.map(value => ({
            ...value,
            rowId: nanoid()
        }));

        return {
            ...orgLevel,
            values
        };
    }

    parseRolePermissionsTree(tree) {
        if (tree) {
            const sortedTree = sortTreeChildren(tree);

            return sortedTree.map(item => createTreeNode(
                item,
                0,
                null,
                this.parseRolePermissionsTreeNode,
                this.parseRolePermissionsTreeItem
            ));
        }
    }

    parseRolePermissionsTreeItem(item) {
        let parsedItem = {
            type: item.type,
            id: item.id,
            description: item.description,
            active: item.active
        };

        switch (item.type) {
            case "field":
                parsedItem.values = item.values?.map(value => ({
                    ...value,
                    rowId: nanoid()
                }));
                parsedItem.orgLevel = item.orglevel;
                parsedItem.status = item.status;
                parsedItem.valueType = item.valueType;
                break;

            case "class":
                parsedItem.children = sortTreeChildren(item.children);
                break;

            case "permission":
                parsedItem.children = item.children?.map(child => ({
                    ...child,
                    active: item.active
                }));
                break;

            default:
                parsedItem.children = item.children;
                break;
        }

        return parsedItem;
    }

    parseRolePermissionsTreeNode(node, orgLevels) {
        const parsedNode = {
            ...node,
            trafficLightStatus: calcRoleTreeItemTrafficLightStatus(node, orgLevels)
        };

        if (node.type !== "field") {
            parsedNode.status = calcRoleTreeItemStatus(node);
        }

        return parsedNode;
    }

    parseModelingRole(role) {
        const parsedRole = {
            role: role.role,
            systemId: role.system_id,
            profile: role.profile ?? "",
            description: role.description,
            isVariantAvailable: role.is_variant_available ?? false,
            isComplex: role.is_complex,
            changed: role.is_changed,
            children: (role.childrens ?? []).map(child => ({
                role: child.role,
                description: child.description,
                systemId: child.system_id,
                isComplex: false,
                isChild: true,
                changed: child.is_changed, 
            }))
        };

        //if (parsedRole.isVariantAvailable) {
            //parsedRole.status = REPORT_MODELING_DELTA_STATUSES.CHANGED;
            parsedRole.status = role.operation
       // }

        return parsedRole;
    }

    parseModelingUser(user) {
        return {
            id: nanoid(),
            employee: user.employee,
            systemId: user.system_id,
            firstName: user.firstname,
            lastName: user.lastname,
            roles: (user.roles ?? []).map(role => ({
                children: role.childrens.map(child => ({
                    role: child.role,
                    description: child.description,
                    systemId: child.system_id,
                    isComplex: false,
                    isChild: true,
                    changed: child.is_changed,
                    id: nanoid(),
                })),
                id: nanoid(),
                description: role.description,
                isComplex: role.is_complex,
                isVariantAvailable: role.is_variant_available,
                status: role.operation,
                profile: role.profile,
                role: role.role,
                systemId: role.system_id,
                manual: role.manual,
                changed: role.is_changed || false
            }))
        };
    }

    parsePermissionsTreeItem(node) {
        const parsedNode = {
            type: node.type,
            id: node.id,
            description: node.description,
            checked: false,
            indeterminate: false
        };

        if (node.type === "field") {
            parsedNode.orgLevel = node.orglevel;
            parsedNode.valueType = node.valueType;
        } else {
            parsedNode.children = node.children;
        }

        return parsedNode;
    }

    parsePermissionsTreeNode(node, checkedPermissions) {
        switch (node.type) {
            case "permission_object":
                return {
                    ...node,
                    checked: Boolean(
                        checkedPermissions &&
                        checkedPermissions.has(node.id)
                    ),
                    indeterminate: false
                };

            case "class": {
                const parsedNode = { ...node };
                setClassCheckedIndeterminate(parsedNode);
                return parsedNode;
            }

            default:
                return node;
        }
    }

    parsePermissionsTree(tree, checkedPermissions) {
        return tree.map(item => createTreeNode(
            item,
            0,
            null,
            (node) => this.parsePermissionsTreeNode(node, checkedPermissions),
            this.parsePermissionsTreeItem
        ));
    }

    parseRoleMenuItem(roleMenuItem) {
        const parsedItem = roleMenuItem.isRoot
            ? roleMenuItem
            : {
                reportName: roleMenuItem.report_name,
                reportType: roleMenuItem.report_type,
                isFolder: roleMenuItem.is_folder,
                nodeText: roleMenuItem.node_text,
                description: roleMenuItem.description,
            };

        if (parsedItem.isFolder) {
            parsedItem.children = roleMenuItem.children ?? [];
        }

        return parsedItem;
    }

    parseRoleMenu(roleMenu) {
        const root = {
            nodeText: "Меню роли",
            isRoot: true,
            isFolder: true,
            children: roleMenu
        };

        const rootNode = createTreeNode(
            root,
            0,
            null,
            null,
            (item) => this.parseRoleMenuItem(item)
        );

        return [rootNode];
    }

    parseCopyRoleMenuNode(roleMenuNode) {
        return {
            ...roleMenuNode,
            checked: false,
            indeterminate: false
        };
    }

    parseCopyRoleMenu(roleId, roleMenu) {
        const root = {
            nodeText: "Меню роли",
            isRoot: true,
            isFolder: true,
            children: roleMenu,
            reportName: roleId
        };

        const rootNode = createTreeNode(
            root,
            0,
            null,
            (node) => this.parseCopyRoleMenuNode(node),
            (item) => this.parseRoleMenuItem(item)
        );

        return [rootNode];
    }
}

const reportsModelingService = new ReportsModelingService();

export { reportsModelingService };
export default ReportsModelingService;
