import React from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { selectUserScreenButton } from "../../../reducers/authSlice";

import { USER_SCREEN_BUTTONS } from "../../../utils/auth";

import { Button } from "../../common/buttons";
import { PageContentBox } from "../../common/page-content-box";

import "./styles.less";

export const SaveTemplateButton = ({ onClick }) => {
    const intl = useIntl();
    const title = intl.formatMessage({ id: "common.btn-save-template" });

    return (
        <Button
            className="save-template-button"
            variant="outlined"
            onClick={onClick}
        >
            {title}
        </Button>
    );
};


export const CompareReportsButton = ({ onClick, disabled }) => {
    const compareReportsButton = useSelector(state => selectUserScreenButton(state, USER_SCREEN_BUTTONS.COMPARE_REPORTS));

    if (!compareReportsButton) {
        return null;
    }

    return (
        <Button
            className="compare-button"
            variant="outlined"
            onClick={onClick}
            disabled={disabled}
        >
            {compareReportsButton.title}
        </Button>
    );
};


export const FillUsersButton = ({ onClick }) => {

    return (
        <Button
            className="compare-button"
            variant="outlined"
            onClick={onClick}
        >
            Генерация объектов
        </Button>
    );
};

export const AnalyzeButton = ({ onClick, disabled }) => {
    const intl = useIntl();
    const title = intl.formatMessage({ id: "common.btn-analyze" });

    return (
        <Button
            className="analyze-button"
            variant="contained"
            onClick={onClick}
            disabled={disabled}
        >
            {title}
        </Button>
    );
};

export const CalcReportManualButton = ({ onClick, disabled }) => {
    const intl = useIntl();
    const title = intl.formatMessage({ id: "common.btn-calc-report-manual" });

    return (
        <Button
            className="analyze-button"
            variant="contained"
            onClick={onClick}
            disabled={disabled}
        >
            {title}
        </Button>
    );
};

export const CalcReportBackgroundButton = ({ onClick, disabled }) => {
    const intl = useIntl();
    const title = intl.formatMessage({ id: "common.btn-calc-report-bgnd" });

    return (
        <Button
            className="compare-button"
            variant="outlined"
            onClick={onClick}
            disabled={disabled}
        >
            {title}
        </Button>
    );
};

export const ColumnSettingsButton = ({ onClick }) => {
    const intl = useIntl();
    const title = intl.formatMessage({ id: "common.btn-column-settings" });

    return (
        <Button
            className="column-settings-button"
            variant="outlined"
            onClick={onClick}
        >
            {title}
        </Button>
    );
};

export const ReportActionsBox = ({ className, ...props }) => {
    let classNameInner = "report-actions-box";

    if (className) {
        classNameInner += ` ${className}`;
    }

    return (
        <PageContentBox
            className={classNameInner}
            {...props}
        />
    );
};

const ReportActions = ({
    onSubmitClick,
    onSaveClick,
    onReportCompare,
    onColumnSettingsClick,
}) => {
    return(
        <ReportActionsBox>
            <AnalyzeButton onClick={onSubmitClick} />
            <SaveTemplateButton onClick={onSaveClick} />
            <CompareReportsButton onClick={onReportCompare}/>
            <ColumnSettingsButton onClick={onColumnSettingsClick} />
            
        </ReportActionsBox>
    );
};

SaveTemplateButton.propTypes = {
    onClick: PropTypes.func
};

AnalyzeButton.propTypes = {
    onClick: PropTypes.func,
    disabled: PropTypes.bool
};

ReportActions.propTypes = {
    onSubmitClick: PropTypes.func,
    onSaveClick: PropTypes.func,
    onReportCompare: PropTypes.func,
    onColumnSettingsClick: PropTypes.func,
};

CompareReportsButton.propTypes = {
    onClick: PropTypes.func,
    disabled: PropTypes.bool
};

ColumnSettingsButton.propTypes = {
    onClick: PropTypes.func,
};

FillUsersButton.propTypes = {
    onClick: PropTypes.func,
};

ReportActionsBox.propTypes = {
    className: PropTypes.string
};

CalcReportManualButton.propTypes = {
    onClick: PropTypes.func,
    disabled: PropTypes.bool
}

CalcReportBackgroundButton.propTypes = {
    onClick: PropTypes.func,
    disabled: PropTypes.bool
}

export default ReportActions;