import React from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import {
    setRoles as setRolesAction,
    //setSystems as setSystemsAction,
    setSystemsExtended as setSystemsExtendedAction,
    setMatrixHeaders as setMatrixHeadersAction,
    setRisks as setRisksAction,
    setRiskLevels as setRiskLevelsAction,
    setParametersOpen as setParametersOpenAction
} from "../../../reducers/reports/roleLevelSlice";

import useReduxValue from "../../common/hooks/useReduxValue";

import ReportParameters, {
    MatrixIdsInput, RiskIdsInput, RiskLevelInput,
    RolesInput, SystemIdsInput
} from "../report-parameters";


const RoleLevelReportParameters = ({ onShowVariantsClick }) => {
    const intl = useIntl();

    const currentVariant = useSelector(state => state.reports.roleLevel.currentVariant);

    const [roles, setRoles] = useReduxValue(state => state.reports.roleLevel.roles, setRolesAction);
    //const [systems, setSystems] = useReduxValue(state => state.reports.roleLevel.systems, setSystemsAction);
    const [systemsExtended, setSystemsExtended] = useReduxValue(state => state.reports.roleLevel.extendedSystems, setSystemsExtendedAction);
    const [matrixHeaders, setMatrixHeaders] = useReduxValue(state => state.reports.roleLevel.matrixHeaders, setMatrixHeadersAction);
    const [risks, setRisks] = useReduxValue(state => state.reports.roleLevel.risks, setRisksAction);
    const [riskLevels, setRiskLevels] = useReduxValue(state => state.reports.roleLevel.riskLevels, setRiskLevelsAction);
    const [open, setOpen] = useReduxValue(state => state.reports.roleLevel.parametersOpen, setParametersOpenAction);
    
    const titles = [intl.formatMessage({ id: "role-level-report-page.title" })];

    if (currentVariant) {
        titles.push(intl.formatMessage(
            { id: "user-level-report-page.variant-selected.title" },
            { name: currentVariant.variantName }
        ));
    }

    const handleOpenClick = (event) => {
        event.stopPropagation();
        setOpen(!open);
    };

    return (
        <ReportParameters
            title={titles.join(" ")}
            open={open}
            onOpenClick={handleOpenClick}
            onShowVariantsClick={onShowVariantsClick}
        >
            <RolesInput
                ids={roles}
                setIds={setRoles}
            />

            <SystemIdsInput
                tags={systemsExtended}
                setTags={setSystemsExtended}
            />

            <MatrixIdsInput
                tags={matrixHeaders}
                setTags={setMatrixHeaders}
            />

            <RiskIdsInput
                tags={risks}
                setTags={setRisks}
            />

            <RiskLevelInput
                tags={riskLevels}
                setTags={setRiskLevels}
            />
        </ReportParameters>
    );
};

RoleLevelReportParameters.propTypes = {
    onShowVariantsClick: PropTypes.func
};

export default RoleLevelReportParameters;
