import React from "react";
import { useIntl } from "react-intl";
import PropTypes from 'prop-types';

import { reportsService } from "../../../services/reports-service";

import { DropdownSearchHelpTagInput } from "../../common/form-controls/dropdown-search-help-input.jsx";

const getSystemsDefault = (params, signal) => {
    return reportsService.getSystemsSearchHelp(params, signal);
};

const SystemIdsInput = ({ tags, setTags, getSystems }) => {
    const intl = useIntl();
    const label = intl.formatMessage({ id: "report-parameters.systems-label" });

    return (
        <DropdownSearchHelpTagInput
            tags={tags}
            setTags={setTags}
            getData={getSystems}
            label={label}
        />
    );
};

SystemIdsInput.propTypes = {
    tags: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        text: PropTypes.string
    })),
    setTags: PropTypes.func,
    getSystems: PropTypes.func
};

SystemIdsInput.defaultProps = {
    getSystems: getSystemsDefault
}

export default SystemIdsInput;
