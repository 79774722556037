import React, { useCallback, useState } from "react";
import { nanoid } from "@reduxjs/toolkit";
import PropTypes from "prop-types";

import { integrationService } from "../../../../services/integration-service";

import {
    getUpdatedFormForDeleteEntity,
    getUpdatedFormForEntity,
    getVisibleEntities,
    getUpdatedFormForMassDeleteEntity,
    getUpdatedFormForMassEditEntity,
    getUpdatedFormForMassCopyEntity,
    getUpdatedFormForCopyEntity
} from "../../../../utils/formViews";

import OperationsView, { useOperationsTable } from "../operations-view";
import PermissionsView, { usePermissionsTable } from "../permissions-view";
import { CHANGE_STATUS } from "../../../common/const";
import { EntityFormPageViews, EntityFormTabs } from "../../../common/entity-form";
import { SYSTEM_TYPES } from "../../../../utils/integration-utils";

const tabs = [
    {
        id: "operations",
        titleId: "function-form-page-function.tabs.operations"
    },
    {
        id: "permissions",
        titleId: "function-form-page-function.tabs.permissions"
    }
];

const entityOperationKeyFields = ["operation"];

const entityOperation1CKeyFields = ["objectType", "objectName"]

const entityPermissionKeyFields = [
    "permissionGroup", "permission", "field", "valueFrom", "valueTo"
];

const entityPermission1CKeyFields = ["objectType", "objectName", "field1C", "value"]

const getUpdatedFormForOperation = (form, operation, field, value, entityOperationKeyFields) => {
    const { newForm, item } = getUpdatedFormForEntity(
        form, operation, field, value, "operations", entityOperationKeyFields
    );

    return { newForm, item }
};

const deletePermissionsForDeletedOperations = (form, ...deletedOperationsIds) => {
    if (deletedOperationsIds.length === 0) {
        return form;
    }

    const persistedOperations = form.operations
        .filter(operation => (
            !deletedOperationsIds.includes(operation.id) &&
            operation.changeStatus !== CHANGE_STATUS.DELETE
        ))
        .map(operation => operation.operation);

    const deletedOperations = form.operations
        .filter(operation =>
            deletedOperationsIds.includes(operation.id) &&
            !persistedOperations.includes(operation.operation)
        )
        .map(operation => operation.operation);
    
    if (deletedOperations.length === 0) {
        return form;
    }

    const deletedPermissionsIds = form.permissions
        .filter(permission => deletedOperations.includes(permission.permissionGroup))
        .map(permission => permission.id);

    if (deletedPermissionsIds.length === 0) {
        return form;
    }

    return getUpdatedFormForMassDeleteEntity(form, deletedPermissionsIds, "permissions");
};

const delete1CPermissionsForDeletedOperations = (form, ...deletedOperationsIds) => {
    if (deletedOperationsIds.length === 0) {
        return form;
    }

    const persistedOperations = form.operations
        .filter(operation => (
            !deletedOperationsIds.includes(operation.id) &&
            operation.changeStatus !== CHANGE_STATUS.DELETE
        ))
        .map(operation => ({objectType: operation.objectType, objectName: operation.objectName}));

    const deletedOperations = form.operations
        .filter(operation =>
            deletedOperationsIds.includes(operation.id) &&
            !persistedOperations.filter(item => item.objectType === operation.objectType && item.objectName === operation.objectName).length > 0
        )
        .map(operation => ({objectType: operation.objectType, objectName: operation.objectName}));
    
    if (deletedOperations.length === 0) {
        return form;
    }

    const deletedPermissionsIds = form.permissions
        .filter(
            permission => deletedOperations.filter(
                operation => operation.objectType === permission.objectType && operation.objectName === permission.objectName
            ).length > 0
        )
        .map(permission => permission.id);

    if (deletedPermissionsIds.length === 0) {
        return form;
    }

    return getUpdatedFormForMassDeleteEntity(form, deletedPermissionsIds, "permissions");
};

const checkItemsForDuplicates = (items, entityKeyFields) => {
    const keysMap = {}

    const getItemKey = (i) => entityKeyFields.map(field => i[field]).join("-")

    items.forEach(item => {
        if (item.changeStatus === CHANGE_STATUS.DELETE) return

        const key = getItemKey(item)

        if (!(key in keysMap)) {
            keysMap[key] = 0
        }

        keysMap[key] += 1
    })

    items.forEach(item => {
        if (item.changeStatus === CHANGE_STATUS.DELETE) return

        const key = getItemKey(item)

        if (keysMap[key] === 1) {
            entityKeyFields.forEach(field => {
                const fieldHasError = item[`error_${field}`] && item[`error_${field}`]["code"] === "duplicate";

                if (fieldHasError) {
                    delete item[`error_${field}`]
                }
            })
        }
    })
}

const getUpdatedFormForPermission = (form, permission, field, value, entityPermissionKeys) => {
    const newValue = value

    return getUpdatedFormForEntity(
        form,
        permission,
        field,
        newValue,
        "permissions",
        entityPermissionKeys
    );
};

const getOperation = async (operationId, systemId, isGroup) => {
    if (!operationId || !systemId) {
        return;
    }

    try {
        const operation = await integrationService.getTransactionDetailed(
            operationId, systemId, null, isGroup
        );

        return operation;
    } catch (e) {
        return;
    }
};


const getObjectType = async (objectTypeId, systemId, isGroup) => {
    if (!objectTypeId || !systemId) {
        return;
    }

    try {
        const objectType = await integrationService.getObjectTypeDetailed(
            objectTypeId, systemId, null, isGroup
        );

        return objectType;
    } catch (e) {
        return;
    }
};

const getObjectName = async (objectNameId, systemId, isGroup) => {
    if (!objectNameId || !systemId) {
        return;
    }

    try {
        const objectName = await integrationService.getObjectNameDetailed(
            objectNameId, systemId, null, isGroup
        );

        return objectName;
    } catch (e) {
        return;
    }
};

const getField1C = async (field1C, systemId, isGroup) => {
    if (!field1C || !systemId) {
        return;
    }

    try {
        const field = await integrationService.getField1CDetailed(
            field1C, systemId, null, isGroup
        );

        return field;
    } catch (e) {
        return;
    }
};

const getValue1C = async (value1C, systemId, isGroup, objectType) => {
    if (!value1C || !systemId) {
        return;
    }

    try {
        const value = await integrationService.getValue1CDetailed(
            value1C, systemId, null, isGroup, objectType
        );

        return value;
    } catch (e) {
        return;
    }
};

const getPermission = async (permissionId, systemId, isGroup) => {
    if (!permissionId || !systemId) {
        return;
    }

    try {
        const permission = await integrationService.getPermissionDetailed(
            permissionId, systemId, null, isGroup
        );

        return permission;
    } catch (e) {
        return;
    }
};

const getField = async (fieldId, permissionId, systemId, isGroup) => {
    if (!fieldId || !permissionId || !systemId) {
        return;
    }

    try {
        const field = await integrationService.getPermissionFieldDetailed(
            fieldId, permissionId, systemId, null, isGroup
        );

        return field;
    } catch (e) {
        return;
    }
};

const getFieldValue = async (value, fieldId, systemId, isGroup) => {
    if (!value || !fieldId || !systemId) {
        return;
    }

    try {
        const fieldValue = await integrationService.getPermissionFieldValueDetailed(
            value, fieldId, systemId, null, isGroup
        );

        return fieldValue;
    } catch (e) {
        return;
    }
};

const FunctionFormPageViews = ({ form, setForm, formDisabled, validationErrors, errorScroll, setErrorScroll }) => {
    const [currentView, setCurrentView] = useState(tabs[0].id);

    const addPermission = () => {
        const systemType = form.systemType

        const permissionSAP = {
            id: nanoid(),
            sequence: 1,
            permissionGroup: "",
            permission: "",
            field: "",
            valueFrom: '',
            valueTo: '',
            sign: "AND",
            active: true,
            changeStatus: CHANGE_STATUS.ADD
        };

        const permission1C = {
            id: nanoid(),
            sequence: 1,
            objectType: "",
            objectName: "",
            field1C: "",
            value: '',
            sign: "AND",
            active: true,
            changeStatus: CHANGE_STATUS.ADD
        };

        const permission = systemType === SYSTEM_TYPES.SAP ? permissionSAP : permission1C

        setForm(form => {
            return {
                ...form,
                permissions: [...form.permissions, permission]
            }
        })
    };

    const addPermissions = (permissions) => {
        setForm(form => {
            return {
                ...form,
                permissions: [...form.permissions, ...permissions]
            };
        });
    };

    const copyPermission = (id) => {
        setForm(form => {
            const newForm = getUpdatedFormForCopyEntity(form, id, "permissions");
            return newForm;
        });
    };

    const copyPermissionMass = useCallback((ids) => {
        setForm(form => {
            const newForm = getUpdatedFormForMassCopyEntity(form, ids, "permissions")
            return newForm  
        });
    }, [setForm]);

    const deletePermissionMass = useCallback((ids) => {
        setForm(form => {
            const newForm = getUpdatedFormForMassDeleteEntity(form, ids, "permissions")
            return newForm
        })
    }, [setForm]);

    const deletePermission = (id) => {
        const systemType = form.systemType
        const entityPermissionKeys = systemType === "SAP" ? entityPermissionKeyFields : entityPermission1CKeyFields

        setForm(form => {
            const { newForm } = getUpdatedFormForDeleteEntity(form, id, "permissions");
            checkItemsForDuplicates(newForm.permissions, entityPermissionKeys)
            return newForm;
        });
    };

    const editPermission = async (permission, field, value) => {
        let newValue;
        const systemType = form.systemType
        const permissionKeyFields = systemType === "SAP" ? entityPermissionKeyFields : entityPermission1CKeyFields

        try {
            switch (field) {
                case "permissionGroup": {
                    const operation = await getOperation(value, form.systemId, form.isGroup);
                    newValue = operation?.transaction ?? value;
                    break;
                }
                case "permission": {
                    const permission = await getPermission(value, form.systemId, form.isGroup);
                    newValue = permission?.permission ?? value;
                    break;
                }
                case "field": {
                    const field = await getField(
                        value, permission.permission, form.systemId, form.isGroup
                    );
                    newValue = field?.field ?? value;
                    break;
                }
                case "valueFrom":
                case "valueTo": {
                    const fieldValue = await getFieldValue(
                        value, permission.field, form.systemId, form.isGroup
                    );
                    newValue = fieldValue?.value ?? value;
                    break;
                }
                case "objectType": {
                    const objectType = await getObjectType(value, form.systemId, form.isGroup);
                    newValue = objectType?.type ?? value;
                    break;
                }
                case "objectName": {
                    const objectName = await getObjectName(value, form.systemId, form.isGroup);
                    newValue = objectName?.name ?? value;
                    break;
                }
                case "field1C": {
                    const field1C = await getField1C(value, form.systemId, form.isGroup);
                    newValue = field1C?.authTypeName ?? value;
                    break;
                }
                case "value": {
                    const field1C = permission.field1C
                    const objectType = permission.objectType ? permission.objectType : null

                    if (field1C !== "Право") {
                        newValue = value;
                        break;
                    }

                    const value1C = await getValue1C(value, form.systemId, form.isGroup, objectType);
                    newValue = value1C?.rightName ?? value;
                    break;

                }
                default:
                    newValue = value;
                    break;
            }
        } catch (e) {
            newValue = value;
        }

        setForm((form) => {
            const { newForm } = getUpdatedFormForPermission(form, permission, field, newValue, permissionKeyFields);
            checkItemsForDuplicates(newForm.permissions, permissionKeyFields);
            return newForm;
        });
    };

    const editPermissionMass = (ids, field, value, allIfIdsEmpty) => {
        const systemType = form.systemType
        const permissionKeyFields = systemType === "SAP" ? entityPermissionKeyFields : entityPermission1CKeyFields

        setForm(form => {
            const newForm = getUpdatedFormForMassEditEntity(
                form,
                ids,
                field,
                value,
                allIfIdsEmpty,
                "permissions",
                permissionKeyFields
            );

            checkItemsForDuplicates(newForm.permissions, permissionKeyFields);

            return newForm;
        });
    };

    const deleteOperationMass = useCallback((ids) => {
        const systemType = form.systemType

        const entityOperationKeys = systemType === "SAP" ? entityOperationKeyFields : entityOperation1CKeyFields
        const entityPermissionKeys = systemType === "SAP" ? entityPermissionKeyFields : entityPermission1CKeyFields

        setForm(form => {
            const formWithDeletedPermissions = systemType === "SAP" ? deletePermissionsForDeletedOperations(form, ...ids) : delete1CPermissionsForDeletedOperations(form, ...ids);
            const newForm = getUpdatedFormForMassDeleteEntity(formWithDeletedPermissions, ids, "operations");
            checkItemsForDuplicates(newForm.operations, entityOperationKeys);
            checkItemsForDuplicates(newForm.permissions, entityPermissionKeys);
            return newForm;
        });
    }, [setForm]);

    const deleteOperation = (id) => {
        const systemType = form.systemType

        const entityOperationKeys = systemType === "SAP" ? entityOperationKeyFields : entityOperation1CKeyFields
        const entityPermissionKeys = systemType === "SAP" ? entityPermissionKeyFields : entityPermission1CKeyFields

        setForm(form => {
            const formWithDeletedPermissions = systemType === "SAP" ? deletePermissionsForDeletedOperations(form, id) : delete1CPermissionsForDeletedOperations(form, id);
            const { newForm } = getUpdatedFormForDeleteEntity(formWithDeletedPermissions, id, "operations");
            checkItemsForDuplicates(newForm.operations, entityOperationKeys);
            checkItemsForDuplicates(newForm.permissions, entityPermissionKeys);
            return newForm;
        });
    };

    const addOperation = async () => {

        const systemType = form.systemType

        const operationSAP = {
            id: nanoid(),
            operation: "",
            description: "",
            active: true,
            changeStatus: CHANGE_STATUS.ADD
        };

        const operation1C = {
            id: nanoid(),
            objectType: "",
            objectName: "",
            description: "",
            active: true,
            changeStatus: CHANGE_STATUS.ADD
        };

        setForm(form => {
            const operation = systemType === SYSTEM_TYPES.SAP ? operationSAP : operation1C;

            const newForm = {
                ...form,
                operations: [...form.operations, operation]
            };

            return newForm;
        });
    };

    const editOperation = async (operation, field, value, systemType) => {
        systemType === SYSTEM_TYPES.SAP ? editOperationSAP(operation, field, value) : editOperation1C(operation, field, value);
    };

    const editOperationSAP = async (operation, field, value) => {
        let description = operation.description;
        let newValue = value;

        const fieldIsKey = entityOperationKeyFields.includes(field);
    
        if (fieldIsKey) {
            const operation = await getOperation(value, form.systemId, form.isGroup);
            description = operation?.description ?? "";
            newValue = operation?.transaction ?? value;
        }

        setForm(form => {
            let formForUpdate;

            if (fieldIsKey) {
                formForUpdate = deletePermissionsForDeletedOperations(form, operation.id);
                checkItemsForDuplicates(formForUpdate.permissions, entityPermissionKeyFields);
            } else {
                formForUpdate = form;
            }
            
            const { newForm, item } = getUpdatedFormForOperation(formForUpdate, operation, field, newValue, entityOperationKeyFields);
            item.description = description;
            checkItemsForDuplicates(newForm.operations, entityOperationKeyFields);
            return newForm;
        });
    };

    const editOperation1C = async (operation, field, value) => {
        let description = operation.description;
        let newValue = value;
        let objectName = "";
        let objectType = "";

        const fieldIsKey = entityOperation1CKeyFields.includes(field);

        if (fieldIsKey) {
            switch (field) {
                case "objectType":
                    objectType = await getObjectType(value, form.systemId, form.isGroup);
                    newValue = objectType?.type ?? value;
                    break;
                    
                case "objectName": 
                    objectName = await getObjectName(value, form.systemId, form.isGroup); 
                    newValue = objectName?.name ?? value;
                    description = objectName?.description ?? "";
                    break;
            }
        }

        setForm(form => {
            let formForUpdate;

            if (fieldIsKey) {
                formForUpdate = delete1CPermissionsForDeletedOperations(form, operation.id);
                checkItemsForDuplicates(formForUpdate.permissions, entityPermission1CKeyFields);
            } else {
                formForUpdate = form;
            }

            formForUpdate = form;
            
            const { newForm, item } = getUpdatedFormForOperation(formForUpdate, operation, field, newValue, entityOperation1CKeyFields);
            item.description = description;
            checkItemsForDuplicates(newForm.operations, entityOperation1CKeyFields);
            return newForm;
        });

    }

    const visibleOperations = getVisibleEntities(form.operations);
    const visiblePermissions = getVisibleEntities(form.permissions);

    const operationsTable = useOperationsTable(visibleOperations);
    const permissionsTable = usePermissionsTable(visiblePermissions);

    const operationsErrorIndex = errorScroll?.errorTab === "operations"
        ? errorScroll.errorIndex : null;

    const permissionsErrorIndex = errorScroll?.errorTab === "permissions"
        ? errorScroll.errorIndex : null;

    return (
        <EntityFormPageViews
            errorScroll={errorScroll}
            setCurrentView={setCurrentView}
        >
            <EntityFormTabs
                tabs={tabs}
                tabId={currentView}
                setTabId={id => setCurrentView(id)}
            />

            {currentView === "operations" && (
                <OperationsView
                    operations={operationsTable.rows}
                    filterData={operationsTable.filterData}
                    setFilterData={operationsTable.setFilterData}
                    searchString={operationsTable.searchString}
                    setSearchString={operationsTable.setSearchString}
                    getDialogFilters={operationsTable.getDialogFilters}
                    addOperation={addOperation}
                    deleteOperation={deleteOperation}
                    deleteOperationMass={deleteOperationMass}
                    editOperation={editOperation}
                    disabled={formDisabled}
                    validationErrors={validationErrors}
                    systemId={form.systemId}
                    isGroup={form.isGroup}
                    systemType={form.systemType}
                    errorIndex={operationsErrorIndex}
                    setErrorScroll={setErrorScroll}
                />
            )}

            {currentView === "permissions" && (
                <PermissionsView
                    operations={visibleOperations}
                    permissions={permissionsTable.rows}
                    filterData={permissionsTable.filterData}
                    setFilterData={permissionsTable.setFilterData}
                    searchString={permissionsTable.searchString}
                    setSearchString={permissionsTable.setSearchString}
                    getDialogFilters={permissionsTable.getDialogFilters}
                    addPermission={addPermission}
                    addPermissions={addPermissions}
                    copyPermission={copyPermission}
                    deletePermission={deletePermission}
                    deletePermissionMass={deletePermissionMass}
                    editPermission={editPermission}
                    editPermissionMass={editPermissionMass}
                    copyPermissionMass={copyPermissionMass}
                    disabled={formDisabled}
                    validationErrors={validationErrors}
                    systemId={form.systemId}
                    systemType={form.systemType}
                    isGroup={form.isGroup}
                    errorIndex={permissionsErrorIndex}
                    setErrorScroll={setErrorScroll}
                />
            )}
        </EntityFormPageViews>
    );
};

FunctionFormPageViews.propTypes = {
    form: PropTypes.object,
    setForm: PropTypes.func,
    formDisabled: PropTypes.bool,
    validationErrors: PropTypes.object,
    errorScroll: PropTypes.object,
    setErrorScroll: PropTypes.func
};

export default FunctionFormPageViews;
