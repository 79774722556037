import React from "react";
import { Box } from "@mui/material";
import PropTypes from 'prop-types';

import Tag from "./tag.jsx";

const TagsList = ({
    ids,
    onDelete,
    onClick=(id) => (id),
    getTagText=(key) => (key)
}) => {
    return (
        <>
            {ids && ids.length > 0 && (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        alignItems: "center",
                        gap: "10px",
                        marginTop: "6px"
                    }}
                >
                    {ids && ids.map(id => (
                        <Tag
                            tag={getTagText(id)}
                            onClick={() => onClick(id)}
                            key={id}
                            onDelete={() => onDelete(id)}
                        />
                    ))}
                </Box>
            )}
        </>
    );
};

TagsList.propTypes = {
    ids: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.number, PropTypes.string
        ])
    ),
    onDelete: PropTypes.func,
    getTagText: PropTypes.func,
    onClick: PropTypes.func,
};

export default TagsList;
