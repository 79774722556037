import { combineReducers } from "redux";

import roleLevelReducer from "./roleLevelSlice.js";
import userLevelReducer from "./userLevelSlice.js";
import customRolesReducer from "./customRolesSlice.js";

const reportReducer = combineReducers({
    roleLevel: roleLevelReducer,
    userLevel: userLevelReducer, 
    customRoles: customRolesReducer,
});

export default reportReducer;
