import React from "react";
import { useIntl } from "react-intl";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

import { CheckboxInput } from "../../common/form-controls";

import { SetUpPlanIntegrationButton, RunIntegrationButton } from "./integration-common.jsx";

const ActionsBox = ({
    checkedAll,
    onCheckedAllChange,
    onStartClick,
    onPlanClick,
    isIntegrationPending,
    runIntegrationDisabled
}) => {
    const intl = useIntl();

    return (
        <Box className="integration-system-box-actions">
            <CheckboxInput
                label={intl.formatMessage({id: "integration.actions-box.checkbox-choose-all"})}
                checked={checkedAll}
                disabled={isIntegrationPending}
                onChange={onCheckedAllChange}
            />

            <Box className="integration-system-box-actions-buttons">
                <SetUpPlanIntegrationButton onClick={() => onPlanClick("common")} />
                <RunIntegrationButton onClick={onStartClick} disabled={runIntegrationDisabled}/>
            </Box>
        </Box>
    );
};

ActionsBox.propTypes = {
    checkedAll: PropTypes.bool,
    onCheckedAllChange: PropTypes.func,
    onStartClick: PropTypes.func,
    onDownloadClick: PropTypes.func,
    onPlanClick: PropTypes.func,
    isIntegrationPending: PropTypes.bool,
    runIntegrationDisabled: PropTypes.bool
}

export default ActionsBox;
