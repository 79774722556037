import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { removeGroupRoleChildFromModeling, addGroupRoleChildToModeling, selectModelingUsers, setSelectedUserRole, expandGroupRole, addModelingSelectedUserRole, deleteModelingSelectedUserRole, restoreDeletedRole } from "../../../reducers/reports-modeling/userLevelSlice";

import GroupRoleModelingBox from "../group-role-modeling-box";

const UserLevelGroupRoleModelingBox = ({ selectedRole }) => {
    const dispatch = useDispatch();
    const [rows, setRows] = useState([])
    const roles = useSelector(selectModelingUsers)[0]?.roles;
    const isAddDisabled = selectedRole?.isComplex ? !selectedRole.isComplex : false;
    const isDeleteDisabled = !selectedRole || selectedRole?.status == 'deleted';
    const isAddUserRole = !selectedRole?.isComplex && !selectedRole?.isChild
    const isDeleteUserRole = !selectedRole?.isChild;



    // const handleAddChildRole = (newChildRole) => {
    //     dispatch(
    //         addGroupRoleChildToModeling({
    //             roleId: selectedRole.id,
    //             newChildRole
    //         })
    //     );
    //     handleExpandChildrenRoles(selectedRole, true)
    // }

    const handleAddRole = (newRole = {}) => {
        if (selectedRole?.status == 'deleted') {
            dispatch(restoreDeletedRole({ roleId: selectedRole.id }))
            dispatch(setSelectedUserRole(null))
            return
        }
        if (!selectedRole?.isComplex && !selectedRole?.isChild) {
            dispatch(addModelingSelectedUserRole(
                newRole
            ));

        }
        else {
            dispatch(
                addGroupRoleChildToModeling({
                    roleId: selectedRole?.isChild ? selectedRole?.parentRole : selectedRole?.role,
                    newChildRole: newRole
                })
            );
            handleExpandChildrenRoles(selectedRole)
        }
    }

    const handleRemoveChildRoles = () => {
        dispatch(
            removeGroupRoleChildFromModeling({
                role: selectedRole
            })
        )
        dispatch(setSelectedUserRole(null))
    }

    const handleRemoveUserRole = () => {
        dispatch(deleteModelingSelectedUserRole(selectedRole.id))
        dispatch(setSelectedUserRole(null))
    }


    const handleRowClick = (row, setActiveRow) => {
        dispatch(setSelectedUserRole(row))
        setActiveRow(row.id)
    }

    const handleExpandChildrenRoles = (row) => {
        dispatch(setSelectedUserRole(row))
        dispatch(expandGroupRole({ role: row }))
    }




    useEffect(() => {
        if (!roles) {
            return
        }

        const rows = []

        roles.forEach(role => {
            rows.push(role)
            if (role.isComplex && role.open) {
                role.children.forEach(childrenRole => {
                    rows.push(childrenRole)
                })
            }
        })

        setRows(rows)

    }, [roles])


    return (
        <GroupRoleModelingBox
            roles={rows}
            selectedRole={selectedRole}
            onAddChildRole={handleAddRole}
            onRemoveChildRoles={isDeleteUserRole ? handleRemoveUserRole : handleRemoveChildRoles}
            handleRowClick={handleRowClick}
            handleExpandChildrenRoles={handleExpandChildrenRoles}
            isAddDisabled={isAddDisabled}
            isDeleteDisabled={isDeleteDisabled}
            showSingleRoles={!isAddUserRole}
        />
    )
}

UserLevelGroupRoleModelingBox.propTypes = {
    selectedRole: PropTypes.object
};

export default UserLevelGroupRoleModelingBox