import React from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import {
    setUsers as setUsersAction,
    //setSystems as setSystemsAction,
    setSystemsExtended as setSystemsExtendedAction,
    setMatrixHeaders as setMatrixHeadersAction,
    setRisks as setRisksAction,
    setRiskLevels as setRiskLevelsAction,
    setParametersOpen as setParametersOpenAction,
    setOnlineSync as setOnlineSyncAction
} from "../../../reducers/reports/userLevelSlice";

import { getFieldValidationError } from "../../../utils/sodReports";

import useReduxValue from "../../common/hooks/useReduxValue";

import ReportParameters, {
    MatrixIdsInput, RiskIdsInput, RiskLevelInput,
    UsersInput, SystemIdsInput, OnlineSyncCheckbox
} from "../report-parameters";


const UserLevelReportParameters = ({ onShowVariantsClick }) => {
    const intl = useIntl();

    const currentVariant = useSelector(state => state.reports.userLevel.currentVariant);
    const validationErrors = useSelector(state => state.reports.userLevel.validationErrors);

    const [users, setUsers] = useReduxValue(state => state.reports.userLevel.users, setUsersAction);
    //const [systems, setSystems] = useReduxValue(state => state.reports.userLevel.systems, setSystemsAction);
    const [systemsExtended, setSystemsExtended] = useReduxValue(state => state.reports.userLevel.extendedSystems, setSystemsExtendedAction);
    const [matrixHeaders, setMatrixHeaders] = useReduxValue(state => state.reports.userLevel.matrixHeaders, setMatrixHeadersAction);
    const [risks, setRisks] = useReduxValue(state => state.reports.userLevel.risks, setRisksAction);
    const [riskLevels, setRiskLevels] = useReduxValue(state => state.reports.userLevel.riskLevels, setRiskLevelsAction);
    const [onlineSync, setOnlineSync] = useReduxValue(state => state.reports.userLevel.onlineSync, setOnlineSyncAction);
    const [open, setOpen] = useReduxValue(state => state.reports.userLevel.parametersOpen, setParametersOpenAction);

    const titles = [intl.formatMessage({ id: "user-level-report-page.title" })];

    if (currentVariant) {
        titles.push(intl.formatMessage(
            { id: "user-level-report-page.variant-selected.title" },
            { name: currentVariant.variantName }
        ));
    }

    const handleOpenClick = (event) => {
        event.stopPropagation();
        setOpen(!open);
    };

    return (
        <ReportParameters
            title={titles.join(" ")}
            open={open}
            onOpenClick={handleOpenClick}
            onShowVariantsClick={onShowVariantsClick}
        >
            <UsersInput
                ids={users}
                setIds={setUsers}
                error={getFieldValidationError(validationErrors, "usernames")}
            />

            <SystemIdsInput
                tags={systemsExtended}
                setTags={setSystemsExtended}
            />

            <MatrixIdsInput
                tags={matrixHeaders}
                setTags={setMatrixHeaders}
            />

            <RiskIdsInput
                tags={risks}
                setTags={setRisks}
            />

            <RiskLevelInput
                tags={riskLevels}
                setTags={setRiskLevels}
            />

            <OnlineSyncCheckbox
                checked={onlineSync}
                onChange={(e) => setOnlineSync(e.target.checked)}
            />
        </ReportParameters>
    );
};

UserLevelReportParameters.propTypes = {
    onShowVariantsClick: PropTypes.func
};

export default UserLevelReportParameters;
