import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import {
    calcReport,
    compareReport,
    deleteVariant,
    fetchVariantsByUser,
    saveVariant,
    setFavoritesForVariant,
    setError as setErrorAction,
    setCurrentVariant as setCurrentVariantAction,
    clearFilters,
    saveReportColumns,
    generateObjects
} from "../../../reducers/reports/userLevelSlice";

import { DIALOG_TYPES } from "../../../utils/sodReports";

import { reportsService } from "../../../services/reports-service";

import { ProgressDialog, ErrorsDialog } from "../../common/dialogs";
import useReduxValue from "../../common/hooks/useReduxValue";

import {
    FavoritesDialog,
    SaveVariantDialog,
    DeleteVariantDialog,
    VariantsListBox,
    VariantsListDialog
} from "../report-variants";
import ReportActions from "../report-actions";
import { ReportHeader } from "../report-header";
import ReportColumnsDialog from "../report-columns-dialog";

import UserLevelReportParameters from "./user-level-report-parameters.jsx";
import UserLevelReportResults from "./user-level-report-results.jsx";
import GenerateUsersDialog from "../report-parameters/generate-users-dialog.jsx";

const getColumns = (reportLevel, reportType, defaultColumns) => reportsService.getUserLevelReportColumns(reportLevel, reportType, defaultColumns);

const UserLevelReportPage = () => {
    const { variantName: encodedVariantName } = useParams();
    const variantName = encodedVariantName ? decodeURIComponent(encodedVariantName) : undefined;

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const [error, setError] = useReduxValue(state => state.reports.userLevel.error, setErrorAction);
    const [currentVariant, setCurrentVariant] = useReduxValue(state => state.reports.userLevel.currentVariant, setCurrentVariantAction);

    const [variantDialogType, setVariantDialogType] = useState(null);
    const [dialogVariant, setDialogVariant] = useState(null);

    const busy = useSelector(state => state.reports.userLevel.busy);
    const busyType = useSelector(state => state.reports.userLevel.busyType);
    const variantCreated = useSelector(state => state.reports.userLevel.variantCreated);
    const variants = useSelector(state => state.reports.userLevel.variants);
    const parametersOpen = useSelector(state => state.reports.userLevel.parametersOpen);
    const validationErrors = useSelector(state => state.reports.userLevel.validationErrors);

    const [genUsers, setGenUsers] = useState(false)

    useEffect(() => {
        dispatch(fetchVariantsByUser());

        return(() => {
            dispatch(clearFilters());
        });
    }, [dispatch]);

    useEffect(() => {
        if (!currentVariant && !variantName) {
            return;
        }

        const newCurrentVariant = variants.find(
            variant => variant.variantName === variantName
        );

        if (!newCurrentVariant) {
            setCurrentVariant(null);
            navigate("/sod-reports/user-level");
            return;
        }

        setCurrentVariant(newCurrentVariant);
    }, [variantName, variants, currentVariant, setCurrentVariant, navigate]);

    useEffect(() => {
        if (variantCreated) {
            hideVariantDialog();
        }
    }, [variantCreated]);

    const hideVariantDialog = () => {
        setDialogVariant(null);
        setVariantDialogType(null);
    };

    // Variants list dialog
    const handleShowVariantsClick = () => {
        setVariantDialogType(DIALOG_TYPES.VARIANTS);
    };

    // Delete dialog
    const handleDeleteDialogConfirmClick = () => {
        dispatch(deleteVariant(dialogVariant.variantName))
        hideVariantDialog();
    };

    const handleDeleteClick = (variant) => {
        setVariantDialogType(DIALOG_TYPES.DELETE);
        setDialogVariant(variant);
    };

    // Favorites dialog
    const handleFavoritesDialogConfirmClick = () => {
        dispatch(setFavoritesForVariant({
            variantName: dialogVariant.variantName,
            inFavorites: !dialogVariant.inFavorites
        }));

        hideVariantDialog();
    };

    const handleFavoriteClick = (variant) => {
        setVariantDialogType(DIALOG_TYPES.FAVORITES);
        setDialogVariant(variant);
    };

    // Save dialog
    const handleSaveDialogConfirmClick = async (variantName) => {
        await dispatch(saveVariant(variantName)).unwrap();
        navigate(`/sod-reports/user-level/${encodeURIComponent(variantName)}`);
    };

    const handleSaveClick = () => {
        setVariantDialogType(DIALOG_TYPES.SAVE);
    };

    // Columns settings dialog
    const handleColumnSettingsClick = () => {
        setVariantDialogType(DIALOG_TYPES.COLUMN_SETTINGS);
    };

    const handleSubmitClick = () => {
        dispatch(calcReport())
    };

    const handleSearchVariant = useCallback((event) => {
        dispatch(fetchVariantsByUser(event.target.value));
    }, [dispatch]);

    const handleVariantClick = (variant) => {
        if (variant.variantName === variantName) {
            setCurrentVariant(variant);
        } else {
            const encodedVariant = encodeURIComponent(variant.variantName);

            if (variantName) {
                navigate(`../${encodedVariant}`, { relative: "path" });
            } else {
                navigate(encodedVariant);
            }
        }

        hideVariantDialog();
    };

    const handleCompareReport = () => {
        dispatch(compareReport())
    }

    const handleSaveColumns = (columns, reportLevel, reportType) => {
        dispatch(saveReportColumns({
            columns, reportLevel, reportType
        }));
    };

    const variantsListDialogOpen = variantDialogType === DIALOG_TYPES.VARIANTS;
    const saveDialogOpen = variantDialogType === DIALOG_TYPES.SAVE;
    const deleteDialogOpen = dialogVariant && variantDialogType === DIALOG_TYPES.DELETE;
    const favoritesDialogOpen = dialogVariant && variantDialogType === DIALOG_TYPES.FAVORITES;
    const columnSettingsDialogOpen = variantDialogType === DIALOG_TYPES.COLUMN_SETTINGS;

    return (
        <>
            <ReportHeader
                parameters={
                    <UserLevelReportParameters
                        onShowVariantsClick={handleShowVariantsClick}
                    />
                }
                variants={
                    <VariantsListBox
                        variants={variants}
                        onDeleteClick={handleDeleteClick}
                        onFavoriteClick={handleFavoriteClick}
                        onItemClick={handleVariantClick}
                        onSearch={handleSearchVariant}
                    />
                }
                parametersOpen={parametersOpen}
            />

            <ReportActions
                onSubmitClick={handleSubmitClick}
                onSaveClick={handleSaveClick}
                onReportCompare={handleCompareReport}
                onColumnSettingsClick={handleColumnSettingsClick}
            />

            <UserLevelReportResults />
            
            {saveDialogOpen && (
                <SaveVariantDialog
                    defaultName={currentVariant?.variantName}
                    open={saveDialogOpen}
                    validationErrors={validationErrors}
                    onSaveClick={handleSaveDialogConfirmClick}
                    onCancelClick={hideVariantDialog}
                />
            )}

            {deleteDialogOpen && (
                <DeleteVariantDialog
                    open={deleteDialogOpen}
                    variant={dialogVariant}
                    onDeleteClick={handleDeleteDialogConfirmClick}
                    onCancelClick={hideVariantDialog}
                />
            )}

            {favoritesDialogOpen && (
                <FavoritesDialog
                    open={favoritesDialogOpen}
                    variant={dialogVariant}
                    onFavoritesClick={handleFavoritesDialogConfirmClick}
                    onCancelClick={hideVariantDialog}
                />
            )}

            {variantsListDialogOpen && (
                <VariantsListDialog
                    open={variantsListDialogOpen}
                    variants={variants}
                    onDeleteClick={handleDeleteClick}
                    onFavoriteClick={handleFavoriteClick}
                    onItemClick={handleVariantClick}
                    onSearch={handleSearchVariant}
                    onClose={hideVariantDialog}
                />
            )}

            {columnSettingsDialogOpen && (
                <ReportColumnsDialog
                    open={columnSettingsDialogOpen}
                    onClose={hideVariantDialog}
                    getColumns={getColumns}
                    saveColumns={handleSaveColumns}
                />
            )}

            <ProgressDialog
                open={busy}
                busyType={busyType}
            />

            <ErrorsDialog
                error={error}
                open={error?.type === "error"}
                onClose={() => setError(null)}
            />

            <GenerateUsersDialog
                open={genUsers}
                onClose={() => setGenUsers(false)}
                onApply={(template, maxCount) => {
                    dispatch(generateObjects({template, maxCount}))
                }}
            />
        </>
    );
}

export default UserLevelReportPage;