import SodService from "./sod-service";

class RisksService extends SodService {
    async getRisks(params, signal) {
        const config = this.generateConfigForGetEntities(params, signal);

        const data = await this.get("/sod/risks/list", config);

        const risks = data && data.rows.map(item => this.parseRisk(item));
        const total = data.total

        return {
            risks, total
        }
    }

    async getRiskColumns() {
        return await this.getColumns("/sod/risks/columns");
    }

    async getRisksFilters(params) {
        return this.getFilters("/sod/risks/filters", params);
    }

    async getRiskChangeHistory(key) {
        const data = await this.get(`/sod/risks/detailed/change-history/${key}`)
        return data && data.map(item => this.parseUserActionLog(item))
    }

    async getRiskDetailed(id) {
        const data = await this.get(`/sod/risks/detailed/${id}`);

        const riskDetailed = this.parseRiskDetailed(data);

        return riskDetailed;
    }

    async syncRiskWithSap(id) {
        return await this.post(`/sod/risks/detailed/sync-sap/${id}`)
    }

    async createRisk(func) {
        const data = await this.post(`/sod/risks/detailed/${func.id}`, this.parseRiskToServer(func));

        return this.parseRiskDetailed(data);
    }

    async editRisk(func) {
        const data = await this.put(`/sod/risks/detailed/${func.id}`, this.parseRiskToServer(func));

        return this.parseRiskDetailed(data);
    }

    async deleteRisk(id) {
        return this.delete(`/sod/risks/detailed/${id}`);
    }

    async checkRelatedObjsLock(id) {
        return await this.get(`/sod/risks/detailed/check-related-objs-lock/${id}`);
    }

    parseRisk(item) {
        return {
            id: item.risk_id,
            riskLevel: item.risk_level_id,
            riskType: item.type,
            functions: item.functions.map((func) => func.function_id),
            matrixHeaders: item.matrix_headers.map(matrixHeader => matrixHeader.matrix_id),
            active: item.active,
            description: item.description,
            updatedTime: item.upd_time,
            user: item.upd_user
        }
    }

    parseRiskDetailed(item) {
        return {
            id: item.risk_id,
            riskLevel: item.risk_level_id,
            riskType: item.type,
            functions: item.functions.map(func => ({
                key: func.key,
                text: func.text || ""
            })),
            active: item.active.toString(),
            description: item.description,
            updatedTime: item.upd_time,
            user: item.upd_user,
            matrixHeaders: item.matrix_headers.map(item => ({
                key: item.key,
                text: item.text || ""
            })),
        }
    }

    parseRiskToServer(item) {
        return {
            risk_id: item.id,
            risk_level_id: item.riskLevel,
            type: item.riskType,
            functions: item.functions.map(item => this.parseRiskFunctionForServer(item)),
            owners: [],
            active: item.active,
            description: item.description,
            matrix_headers: item.matrixHeaders.map(matrixHeader => this.parseRiskMatrixHeaderForServer(matrixHeader))
        }
    }

    parseRiskFunctionForServer(item) {
        return {
            function_id: item
        }
    }

    parseRiskMatrixHeaderForServer(item) {
        return {
            matrix_id: item
        }
    }
}

const risksService = new RisksService();

export { risksService };
export default RisksService;