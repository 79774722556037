import { combineReducers } from "redux";
import integrationReducer from "./integrationSlice.js";
import systemsReducer from "./systemsSlice.js";
import systemGroupReducer from "./systemGroupReducer.js";
import riskLevelsReducer from "./riskLevelsSlice.js";
import usersReducer from "./usersSlice.js";
import locksReducer from "./locksSlice.js";
import busynessProcessesReduces from "./busynessProcessesSlice.js"
import {default as adminReducerInner} from "./adminSlice.js"

const adminReducer = combineReducers({
    integration: integrationReducer,
    systems: systemsReducer,
    systemGroups: systemGroupReducer,
    riskLevels: riskLevelsReducer,
    users: usersReducer,
    locks: locksReducer,
    busynessProcesses: busynessProcessesReduces,
    admin: adminReducerInner
});

export default adminReducer;
